import {} from "../../UnitConversion";
export const getPageBorderStyle = ({ sectionProps, docProps }) => {
  const style = {};
  if (sectionProps?.page?.borders) {
    const borders = sectionProps.page.borders;

    // Use border sizes directly without conversion
    const borderLeftSize = borders.pageBorderLeft?.size || 0;
    const borderRightSize = borders.pageBorderRight?.size || 0;
    const borderTopSize = borders.pageBorderTop?.size || 0;
    const borderBottomSize = borders.pageBorderBottom?.size || 0;

    // Ensure the color codes are prefixed with '#' and in uppercase
    const formatColor = (color) =>
      color ? `#${color.toLowerCase()}` : "black";

    // Set border styles (style, color, and size)
    style.borderLeft = `${borderLeftSize}px ${"solid"} ${formatColor(
      borders.pageBorderLeft?.color
    )}`;
    style.borderRight = `${borderRightSize}px ${"solid"} ${formatColor(
      borders.pageBorderRight?.color
    )}`;
    style.borderTop = `${borderTopSize}px ${"solid"} ${formatColor(
      borders.pageBorderTop?.color
    )}`;
    style.borderBottom = `${borderBottomSize}px ${"solid"} ${formatColor(
      borders.pageBorderBottom?.color
    )}`;

    const borderLeftSpace = `${borders.pageBorderLeft?.space}pt` || 0;
    const borderRightSpace = `${borders.pageBorderRight?.space}pt` || 0;
    const borderTopSpace = `${borders.pageBorderTop?.space}pt` || 0;
    const borderBottomSpace = `${borders.pageBorderBottom?.space}pt` || 0;

    style.paddingLeft = borderLeftSpace;
    style.paddingRight = borderRightSpace;
    style.paddingTop = borderTopSpace;
    style.paddingBottom = borderBottomSpace;
  }

  return style;
};
