import React, { useState, useEffect } from "react";
import Input from "../utils/Input";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from "../slices/userApiSlice";
import { setCredentials } from "../slices/authSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo) {
      switch (userInfo.role) {
        case "Admin":
          navigate("/admin/AdminDashBoard");
          break;
        case "Manager":
          navigate("/manager/ManagerDashBoard");
          break;
        case "User":
          navigate("/DashBoard");
          break;
      }
    }
  }, [navigate, userInfo]);

  const submitHandler = async () => {
    try {
      const res = await login({ email, password }).unwrap();
      dispatch(setCredentials({ ...res }));
      // navigate("/");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <section className="wh-100 m-auto pmd items-start justify-center">
      <div className="wh-100 sm:w-[350px] m-auto flex flex-col items-center">
        <h1 className="font-mono text-2xl mb-5">Sign In</h1>
        <div className="mb-5">
          <Input
            id="email"
            value={email}
            placeholder="Enter your email"
            type="email"
            change={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-5">
          <Input
            id="password"
            value={password}
            placeholder="Enter your password"
            type="password"
            change={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="mb-5 flex items-center gap-4">
          <button className="btn_primary" onClick={() => submitHandler()}>
            Log In
          </button>
          {isLoading && <Loader />}
        </div>
        <div className="mb-2">
          <span className="font-mono font-bold">
            Forgot Password?{" "}
            <span
              className="text-secondary hover:text-light-500 hover:bg-cyan-950 px-2 py-2 cursor-pointer"
              onClick={() => navigate("/Forgot")}
            >
              Raise Request
            </span>
          </span>
        </div>
        <div>
          <span className="font-mono font-bold">
            Don't have an account?{" "}
            <span
              className="text-secondary hover:text-light-500 hover:bg-cyan-950 px-2 py-2 cursor-pointer"
              onClick={() => navigate("/SignUp")}
            >
              Sign Up
            </span>
          </span>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
