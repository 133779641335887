import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDocument } from "../../slices/documentSlice";
import {
  setDocs,
  setIndexPath,
  setCategory,
  setRunStatus,
} from "../../slices/docsSlice";
import { getForms, setSingleUserForm } from "../../slices/formsSlice";
import {
  setListIDS,
  getListIDS,
  setStructuredIDS,
  setInternalDependency,
} from "../../slices/internalDependencySlice";
import { useNavigate } from "react-router-dom";
import { useGetDocumentMutation } from "../../slices/documentsApiSlice";
import { toast } from "react-toastify";
import RecursiveDependency from "./RecursiveDependency";
import produceClone from "../../components/Lodash";
const InternalDep = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);
  const { document } = useSelector((state) => state.document);
  const { forms, userForms } = useSelector((state) => state.forms);
  const { listIDS, structuredIDS, internalDependency } = useSelector(
    (state) => state.internalDependency
  );
  const { userInfo } = useSelector((state) => state.auth);
  const [getDocument, {}] = useGetDocumentMutation();
  // const getDocumentHandler = async (record) => {
  //   try {
  //     const res = await getDocument({ document: record, listIDS }).unwrap();
  //     dispatch(
  //       getForms({
  //         userForms: res.userForms,
  //         forms: res.forms,
  //       })
  //     );
  //     dispatch(setDocument(record));
  //     dispatch(setDocs(record.document));
  //     dispatch(setCategory(record.category));
  //     dispatch(setRunStatus(record.runStatus));
  //     dispatch(setIndexPath(record.indexPath));
  //     // dispatch(getListIDS(res.listIDS));
  //     // dispatch(setInternalDependency(res.internalDependency));
  //   } catch (err) {
  //     toast.error(err?.data?.message || err.error);
  //   }
  // };
  // useEffect(() => {
  //   if (document && forms && userForms && listIDS && internalDependency) {
  //     setIsReadyToNavigate(true);
  //   }
  // }, [document, forms, userForms, listIDS, internalDependency]);
  // useEffect(() => {
  //   if (isReadyToNavigate && document) {
  //     if (userInfo.role === "Manager") {
  //       navigate(`/manager/${document.category}/DC`);
  //     } else if (userInfo.role === "User") {
  //       navigate(`/Forms/EF/${document.category}_EF`);
  //     }
  //   } else {
  //   }
  // }, [isReadyToNavigate, document]);
  useEffect(() => {
    if (!document) {
      navigate("/manager/CD");
    }
  }, [document]);
  useEffect(() => {
    let ids = {};
    Object.keys(document.internalDependency).map((category) => {
      let depArr = [];
      document.internalDependency[category].map((dep) => {
        depArr.push({
          depCategory: dep.category,
          depName: dep.name,
          internalDep: {},
        });
      });
      ids[category] = depArr;
    });
    dispatch(setStructuredIDS(ids));
  }, [document.internalDependency]);

  return (
    <div className="psm wh-90 mx-auto">
      <RecursiveDependency tempIDS={produceClone(structuredIDS)} />
      {userForms.length > 0 ? (
        <div className="flex justify-end">
          <button
            className="bg-cyan-950 text-light-500 p-2"
            onClick={() => {
              if (userInfo.role === "Manager") {
                navigate(`/manager/${document.category}$${document.name}/DC`);
              } else if (userInfo.role === "User") {
                dispatch(setSingleUserForm({ name: "Submit" }));
                navigate(`/Forms/EF/${document.category}$${document.name}_EF`);
              }
            }}
          >
            Next
          </button>
        </div>
      ) : null}
    </div>
  );
};
export default InternalDep;
