import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  imageName: undefined,
  textName: undefined,
  paraName: undefined,
  cellName: undefined,
  rowName: undefined,
  tableName: undefined,
  docName: undefined,
};

const elementSlice = createSlice({
  name: "element",
  initialState,
  reducers: {
    setElementName: (state, { payload }) => {
      switch (payload.ele) {
        case "image":
          state.imageName = payload.name;
          break;
        case "text":
          state.textName = payload.name;
          break;
        case "para":
          state.paraName = payload.name;
          break;
        case "cell":
          state.cellName = payload.name;
          break;
        case "row":
          state.rowName = payload.name;
          break;
        case "table":
          state.tableName = payload.name;
          break;
        case "doc":
          state.docName = payload.name;
          break;
      }
    },
    resetElementName: (state, { payload }) => {
      state.imageName = undefined;
      state.textName = undefined;
      state.paraName = undefined;
      state.cellName = undefined;
      state.rowName = undefined;
      state.tableName = undefined;
      state.docName = undefined;
    },
  },
});

export const { setElementName, resetElementName } = elementSlice.actions;

export default elementSlice.reducer;
