import React from "react";

const Input2 = ({
  type,
  placeholder,
  change,
  value,
  disabled,
  blur,
  style,
}) => {
  return (
    <input
      style={{ width: `${(value.length + 1) * 8}px` }}
      className="text-right outline-none bg-transparent w-[90px] line-clamp-[40px]"
      type={type}
      placeholder={placeholder}
      onChange={change}
      value={value}
      onFocus={(e) => e.target.select()}
      disabled={disabled}
      onBlur={blur}
    />
  );
};

export default Input2;
