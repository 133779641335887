import { twipToPt, convertToPx } from "../../UnitConversion";
export const getParaStyle = ({
  mainEle,
  paraProps,
  cellProps,
  rowProps,
  tableProps,
  sectionProps,
  docProps,
}) => {
  const style = {};
  if (mainEle.length === 3) {
    style.marginTop = docProps.para?.spacing.before;
    style.marginBottom = docProps.para?.spacing.after;
  } else {
    style.height = "auto";
  }
  if (paraProps !== undefined && Object.keys(paraProps)?.length > 0) {
    if (paraProps.alignment) {
      switch (paraProps.alignment) {
        case "left":
          style.justifyContent = "flex-start";
          break;
        case "right":
          style.justifyContent = "flex-end";
          break;
        case "center":
          style.justifyContent = "center";
          break;
      }
      // style.textAlign =
      //   paraProps.alignment === "justified" ? "justify" : paraProps.alignment;
    }
    if (paraProps.spacing) {
      style.marginTop = paraProps.spacing.before;
      style.marginBottom = paraProps.spacing.after;
    }
    if (paraProps.border) {
      style.border = "1px solid black";
    }
    if (paraProps.shading) {
      style.backgroundColor = `#${paraProps.shading.color}`;
      style.color = `#${paraProps.shading.fill}`;
    }
    if (paraProps.indent) {
      style.paddingLeft = convertToPx(paraProps.indent.left);
      style.paddingRight = convertToPx(paraProps.indent.right);
      style.textIndent = convertToPx(paraProps.indent.firstLine);
      if (paraProps.indent.hanging) {
        style.textIndent = convertToPx(paraProps.indent.hanging);
        style.paddingLeft = convertToPx(paraProps.indent.hanging);
      }
    }
  }
  return style;
};
