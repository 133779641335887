import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Loader from "../../components/Loader";
import Input from "../../utils/Input";
import Table from "../../utils/Table/Table";
import TableRow from "../../utils/Table/TableRow";
import TableCell from "../../utils/Table/TableCell";
import Pagination from "../../utils/Table/Pagination";

import {
  useManagersListQuery,
  useUpdateByAuthorityMutation,
  useRefreshPasswordListQuery,
  usePermissionAcceptMutation,
} from "../../slices/userApiSlice";

// import { updatePasswordRequests } from "../../slices/authSlice";

const Managers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const { agencies } = useSelector((state) => state.agencies);
  const { pageNumber } = useParams();

  const {
    data: managers,
    refetch: refetchManagers,
    isLoading,
  } = useManagersListQuery(pageNumber);

  const [permissionAccept, { isPermissionAccept }] =
    usePermissionAcceptMutation();

  // const [updateByAuthority, { isLoading: byAuthorityLoading }] =
  //   useUpdateByAuthorityMutation();

  // const {
  //   data: passwordList,
  //   refetch: refreshPasswordRefetch,
  //   isLoading: refreshPasswordLoading,
  // } = useRefreshPasswordListQuery();

  // useEffect(() => {
  //   if (managers) {
  //     const fetchAgencies = async () => {
  //       const currentAgencies = await Promise.all(
  //         managers.managers.map(async (manager) => {
  //           const agency = await getAgency({
  //             agencyid: manager.agency,
  //           }).unwrap();
  //           return agency;
  //         })
  //       );
  //       dispatch(setAgencies(currentAgencies));
  //     };

  //     fetchAgencies();
  //   }
  // }, [managers, getAgency, dispatch]);

  // useEffect(() => {
  //   if (passwordList !== undefined) {
  //     dispatch(updatePasswordRequests(passwordList));
  //   }
  // }, [passwordList]);

  const permissionAcceptHandler = async (email) => {
    try {
      const res = await permissionAccept({ email }).unwrap();
      if (res.status) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  // const submitHandler = async () => {
  //   try {
  //     const res = await updateByAuthority({
  //       id,
  //       name,
  //       email,
  //       phoneNo,
  //       password,
  //     }).unwrap();
  //     toast.success(res.message);
  //     setName("");
  //     setEmail("");
  //     setPhoneNo("");
  //     setPassword("");
  //     refreshPasswordRefetch();
  //   } catch (err) {
  //     toast.error(err?.data?.message || err.error);
  //   }
  // };

  // const pageChangeHandler = (async = () => {
  //   try {
  //     const res = res;
  //   } catch (err) {
  //     toast.error(err?.data?.message || err.error);
  //   }
  // });

  return (
    <div className="psm flex gap-2">
      <div className="wh-80">
        <h2 className="font-mono pb-2">Managers</h2>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Table>
              <TableRow isHeader={true}>
                <TableCell isHeader={true}>S.No</TableCell>
                <TableCell isHeader={true}>Name</TableCell>
                <TableCell isHeader={true}>Email</TableCell>
                <TableCell isHeader={true}>Agency</TableCell>

                <TableCell isHeader={true}>isPermitted</TableCell>
                <TableCell isHeader={true}>Phone No</TableCell>
                <TableCell isHeader={true}>Password Request</TableCell>
                <TableCell isHeader={true} className={`w-[44px] text-center`}>
                  X
                </TableCell>
              </TableRow>
              {managers.managers.map((manager, sno) => {
                return (
                  <TableRow
                    className={(sno + 1) % 2 === 0 ? "bg-gray-100" : null}
                  >
                    <TableCell>{sno + 1}</TableCell>
                    <TableCell>{manager.name}</TableCell>
                    <TableCell>{manager.email}</TableCell>
                    <TableCell>
                      {agencies.length > 0 ? (
                        agencies[sno].manager === manager._id ? (
                          agencies[sno].agency
                        ) : null
                      ) : (
                        <Loader />
                      )}
                    </TableCell>

                    <TableCell
                      className={`cursor-pointer ${
                        manager.isPermitted ? "bg-success-500" : "bg-danger-500"
                      }`}
                      click={async () => {
                        try {
                          await permissionAcceptHandler(manager.email);
                          refetchManagers();
                        } catch (err) {
                          toast.error(err?.data?.message || err.error);
                        }
                      }}
                    >
                      {manager.isPermitted ? "Permitted" : "Not Permitted"}
                    </TableCell>
                    <TableCell>{manager.phoneNo}</TableCell>
                    <TableCell>{manager.name}</TableCell>
                    <TableCell
                      className={`cursor-pointer bg-danger-500 font-bold text-light-500 text-center`}
                    >
                      X
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
            <Pagination
              currentPage={managers.page}
              totalPages={managers.pages}
              onPageChange={(value) => {
                navigate(`/admin/managers/${value}`);
              }}
            />
          </>
        )}
      </div>
      <div className="wh-20">
        <h2 className="font-mono mb-3">Update Manager Password</h2>
        <div className="wh-100 sm:w-[350px]">
          <div className="mb-5">
            <Input
              id="name"
              value={name}
              placeholder="Enter your name"
              type="text"
              change={(e) => setName(e.target.value)}
              disabled
            />
          </div>
          <div className="mb-5">
            <Input
              id="email"
              value={email}
              placeholder="Enter your email"
              type="email"
              change={(e) => setEmail(e.target.value)}
              disabled
            />
          </div>
          <div className="mb-5">
            <Input
              id="phone no"
              value={phoneNo}
              placeholder="Enter your phone no"
              type="text"
              change={(e) => setPhoneNo(e.target.value)}
            />
          </div>
          <div className="mb-5">
            <Input
              id="password"
              value={password}
              placeholder="Enter your password"
              type="text"
              change={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-5 flex items-center gap-4">
            <button
              className="px-5 py-3 bg-cyan-950 text-light-500 font-mono rounded"
              onClick={() => submitHandler()}
            >
              Update Manager Password
            </button>
            {/* {byAuthorityLoading && <Loader />} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Managers;
