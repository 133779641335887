import { getParaStyle } from "./Styles/paraStyle";
import DeleteElement from "../Elements/DeleteElement";
import { useDispatch, useSelector } from "react-redux";
import CreateText from "./Text";
import CreateImage from "./Image";
const CreatePara = ({
  para,
  index,
  loc,
  table,
  row,
  cell,
  sec,
  doc,
  baseURL,
  SIPHF,
}) => {
  const dispatch = useDispatch();
  const { RRIP_P, NIP_P } = useSelector((state) => state.docs);
  const paraStyle = getParaStyle({
    mainEle: loc.split("/"),
    paraProps: para?.properties,
    cellProps: cell?.properties,
    rowProps: row?.properties,
    tableProps: table?.properties,
    sectionProps: sec?.properties,
    docProps: doc?.properties,
  });
  let splitLoc = loc.split("/");
  return (
    <p
      key={index}
      id={index}
      className="relative scroll-mt-[100px] flex flex-wrap"
      // className={`${
      //   NIP.startsWith(index) ? "bg-primary-200" : ""
      // } w-full outline-none p-0 relative`}
      style={paraStyle}
    >
      {SIPHF !== "0_H" || SIPHF !== "0_F" ? (
        <DeleteElement
          NIP={NIP_P}
          RRIP={RRIP_P}
          index={index}
          path={`${baseURL}`}
        />
      ) : null}

      {para?.ele?.map((t, i1) => {
        splitLoc.push(`${i1}_T`);
        if (t?.type === "text") {
          return (
            <CreateText
              text={t}
              index={`${index}_${i1}`}
              loc={`${loc}/${i1}_T`}
              para={para}
              cell={cell}
              row={row}
              table={table}
              sec={sec}
              doc={doc}
              baseURL={baseURL}
              SIPHF={SIPHF}
            />
          );
        } else {
          return (
            <CreateImage
              image={t}
              index={`${index}_${i1}`}
              loc={`${loc}/${i1}_I`}
              para={para}
              cell={cell}
              row={row}
              table={table}
              sec={sec}
              doc={doc}
              baseURL={baseURL}
              SIPHF={SIPHF}
            />
          );
        }
      })}
      {para?.properties?.thematicBreak ? <hr className="border-black" /> : null}
    </p>
  );
};

export default CreatePara;
