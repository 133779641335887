import { DxaToPt, cmToPx, convertToPx } from "../../UnitConversion";

export const getCellStyle = ({
  cellProps,
  rowProps,
  tableProps,
  sectionProps,
  docProps,
}) => {
  const style = {};
  if (docProps.cell?.borders) {
    style.borderLeft =
      docProps.cell.borders.left.style !== "none" ? "1px solid black" : "";
    style.borderRight =
      docProps.cell.borders.right.style !== "none" ? "1px solid black" : "";
    style.borderTop =
      docProps.cell.borders.top.style !== "none" ? "1px solid black" : "";
    style.borderBottom =
      docProps.cell.borders.bottom.style !== "none" ? "1px solid black" : "";
  }
  if (`${tableProps.borders}` !== "undefined") {
    style.border =
      tableProps.borders === true ? "1px solid black" : "0px solid black";
  }
  if (`${cellProps.borders}` !== "undefined") {
    style.border =
      cellProps.borders === true ? "1px solid black" : "0px solid black";
  }

  if (docProps.table?.margins) {
    style.paddingLeft = convertToPx(docProps.table.margins.left);
    style.paddingRight = convertToPx(docProps.table.margins.right);
    style.paddingTop = convertToPx(docProps.table.margins.top);
    style.paddingBottom = convertToPx(docProps.table.margins.bottom);
  }
  if (docProps.cell?.margins) {
    style.paddingLeft = convertToPx(docProps.cell.margins.left);
    style.paddingRight = convertToPx(docProps.cell.margins.right);
    style.paddingTop = convertToPx(docProps.cell.margins.top);
    style.paddingBottom = convertToPx(docProps.cell.margins.bottom);
  }
  if (tableProps.margins) {
    style.paddingLeft = convertToPx(tableProps.margins.left);
    style.paddingRight = convertToPx(tableProps.margins.right);
    style.paddingTop = convertToPx(tableProps.margins.top);
    style.paddingBottom = convertToPx(tableProps.margins.bottom);
  }
  if (cellProps.margins) {
    style.paddingLeft = convertToPx(cellProps.margins.left);
    style.paddingRight = convertToPx(cellProps.margins.right);
    style.paddingTop = convertToPx(cellProps.margins.top);
    style.paddingBottom = convertToPx(cellProps.margins.bottom);
  }

  if (docProps.verticalAlignment) {
    style.verticalAlignment = docProps.verticalAlignment;
  }

  if (rowProps.height) {
    style.lineHeight = `${cmToPx(rowProps.height.value)}px`;
  }

  if (rowProps.width) {
    style.display = "table-cell";
    style.width = convertToPx(rowProps.width.size);
  }
  style.minWidth = "10px";
  if (cellProps.width) {
    style.display = "table-cell";
    style.width = convertToPx(cellProps.width.size);
  }

  if (cellProps.shading) {
    style.backgroundColor = `#${cellProps.shading.color}`;
    style.color = `#${cellProps.shading.fill}`;
  }

  // if (cellProps !== undefined && Object.keys(cellProps)?.length > 0) {
  //   Object.keys(cellProps).map((prop) => {
  //     // Table Properties

  //     if (prop === "width") {
  //       if (cellProps.width.type === "pct") {
  //         style.width = cellProps.width.size;
  //       } else if (cellProps.width.type === "dxa") {
  //         style.display = "block";
  //         style.width = `${DxaToPt(parseFloat(cellProps.width.size))}pt`;
  //       } else if (cellProps.width.type === "nil") {
  //         style.width = "auto";
  //         style.flex = "1";
  //       }
  //     } else if (rowProps.width.type === "pct") {
  //       style.width = rowProps.width.size;
  //     } else if (rowProps.width.type === "dxa") {
  //       style.display = "table-cell";
  //       style.width = `${DxaToPt(parseFloat(rowProps.width.size))}pt`;
  //     } else if (rowProps.width.type === "nil") {
  //       style.display = "table-cell";
  //       style.flex = "1";
  //     }
  //     if (!tableProps.borders) {
  //       style.border = "none";
  //     } else {
  //       if (prop === "borders" && cellProps.borders === "nil") {
  //         style.border = "none";
  //       } else {
  //         style.border = "1px solid black";
  //       }
  //     }

  //     if (prop === "shading") {
  //       style.backgroundColor = `#${cellProps.shading.color}`;
  //       style.color = `#${cellProps.shading.fill}`;
  //     }
  //     if (prop === "margins") {
  //       if (cellProps.margins.marginUnitType === "dxa") {
  //         style.paddingLeft = `${DxaToPt(cellProps.margins.left)}pt`;
  //         style.paddingRight = `${DxaToPt(cellProps.margins.right)}pt`;
  //         style.paddingTop = `${DxaToPt(cellProps.margins.top)}pt`;
  //         style.paddingBottom = `${DxaToPt(cellProps.margins.bottom)}pt`;
  //       }
  //     } else if (tableProps.margins) {
  //       if (tableProps.margins.marginUnitType === "dxa") {
  //         style.paddingLeft = `${DxaToPt(tableProps.margins.left)}pt`;
  //         style.paddingRight = `${DxaToPt(tableProps.margins.right)}pt`;
  //         style.paddingTop = `${DxaToPt(tableProps.margins.top)}pt`;
  //         style.paddingBottom = `${DxaToPt(tableProps.margins.bottom)}pt`;
  //       }
  //     } else {
  //       if (docProps.cellMargin.marginUnitType === "dxa") {
  //         style.paddingLeft = `${DxaToPt(docProps.cellMargin.left)}pt`;
  //         style.paddingRight = `${DxaToPt(docProps.cellMargin.right)}pt`;
  //         style.paddingTop = `${DxaToPt(docProps.cellMargin.top)}pt`;
  //         style.paddingBottom = `${DxaToPt(docProps.cellMargin.bottom)}pt`;
  //       }
  //     }
  //   });

  return style;
  // } else {
  //   return style;
  // }
};
