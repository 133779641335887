import React, { useState, useEffect } from "react";
import Input from "../utils/Input";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateUserMutation } from "../slices/userApiSlice";
import { setCredentials } from "../slices/authSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [agency, setAgency] = useState("");
  const [originalPassword, setOriginalPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);

  const [updateProfile, { isLoading }] = useUpdateUserMutation();

  useEffect(() => {
    setName(userInfo.name);
    setEmail(userInfo.email);
    setPhoneNo(userInfo.phoneNo);
    // if (userInfo.role === "Manager") {
    //   setAgency(userInfo.agency);
    // }
  }, [userInfo.email, userInfo.name, userInfo.agency, userInfo.phoneNo]);

  useEffect(() => {
    setPassword("");
    setConfirmPassword("");
    setOriginalPassword("");
  }, [userInfo]);

  const submitHandler = async () => {
    if (password !== "" && confirmPassword !== "") {
      if (password === confirmPassword) {
        if (originalPassword === "") {
          toast.error("Original password needed");
        } else {
          try {
            const res = await updateProfile({
              _id: userInfo._id,
              name,
              email,
              phoneNo,
              // agency,
              password,
              originalPassword,
            }).unwrap();
            dispatch(setCredentials(res));
            toast.success("Profile updated successfully");
          } catch (err) {
            toast.error(err?.data?.message || err.error);
          }
        }
      }
    } else {
      if (originalPassword === "") {
        toast.error("Original password needed");
      } else {
        try {
          const res = await updateProfile({
            _id: userInfo._id,
            name,
            email,
            phoneNo,
            // agency,
            originalPassword,
          }).unwrap();
          dispatch(setCredentials(res));
          toast.success("Profile updated successfully");
        } catch (err) {
          toast.error(err?.data?.message || err.error);
        }
      }
    }
  };

  return (
    <div className="wh-100 m-auto pmd items-start justify-center">
      <div className="wh-100 sm:w-[350px] m-auto">
        <h1 className="font-mono text-2xl mb-5">Update Profile</h1>
        <div className="mb-5">
          <Input
            id="name"
            value={name}
            placeholder="Enter your name"
            type="text"
            change={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-5">
          <Input
            id="email"
            value={email}
            placeholder="Enter your email"
            type="email"
            change={(e) => setEmail(e.target.value)}
            disabled
          />
        </div>
        {userInfo.role === "Manager" ? (
          <div className="mb-5">
            {/* <Input
              id="agency"
              value={agency}
              placeholder="Enter your Agency"
              type="text"
              change={(e) => setAgency(e.target.value)}
            /> */}
          </div>
        ) : null}
        <div className="mb-5">
          <Input
            id="phone no"
            value={phoneNo}
            placeholder="Enter your phone no"
            type="text"
            change={(e) => setPhoneNo(e.target.value)}
          />
        </div>
        <div className="mb-5">
          <Input
            id="password"
            value={password}
            placeholder="Enter your password"
            type="password"
            change={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="mb-5">
          <Input
            id="confirm password"
            value={confirmPassword}
            placeholder="Re-enter your password"
            type="password"
            change={(e) => setConfirmPassword(e.target.value)}
          />
        </div>

        <div className="mb-2">
          <span className="font-mono text-sm">
            Enter password to update your profile.
          </span>
        </div>
        <div className="mb-5">
          <Input
            id="original password"
            value={originalPassword}
            placeholder="Enter your current password"
            type="password"
            change={(e) => setOriginalPassword(e.target.value)}
          />
        </div>
        <div className="mb-5 flex items-center gap-4">
          <button
            // className="px-5 py-3 bg-cyan-950 text-light-500 font-mono"
            className="btn_primary"
            onClick={() => submitHandler()}
          >
            Update Profile
          </button>
          {isLoading && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
