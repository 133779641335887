import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Input from "../../../../../utils/Input";
import Dropdown from "../../../../../utils/Dropdown";
import { IoCloseSharp } from "react-icons/io5";

import {
  addXTInput,
  addXTDropDown,
  updateXTName,
  updateXTDv,
  removeXT,
} from "../../../../../slices/docsSlice";

const XtControls = ({ path, dynEle, operator }) => {
  const dispatch = useDispatch();
  const { docs, indexPath, dev } = useSelector((state) => state.docs);
  return (
    <div className="pb-4 min-h-[300px]">
      <div className="flex items-start justify-between">
        <h2 className="font-mono">{operator.toUpperCase()}</h2>
        <div className="flex gap-2 pb-2">
          <button
            className="px-2 bg-cyan-950 text-light-500 font-mono h-[40px]"
            onClick={() => {
              dispatch(
                addXTInput({
                  path: path,
                  operator: operator,
                })
              );
            }}
          >
            Add Input
          </button>
          <button
            className="px-2 bg-cyan-950 text-light-500 font-mono h-[40px]"
            onClick={() => {
              let noOfVal = prompt("No. of Inputs");
              let valArr = [];
              for (let i = 0; i < noOfVal; i++) {
                let val = prompt(`Input ${i + 1}`);
                valArr.push(val);
              }
              dispatch(
                addXTDropDown({
                  path: path,
                  name: valArr,
                  operator: operator,
                })
              );
            }}
          >
            Add Dropdown
          </button>
        </div>
      </div>
      <div className="flex gap-4 flex-wrap">
        {docs && eval(dynEle)[operator]?.XT
          ? eval(dynEle)[operator].XT.map((xt, i) => {
              if (typeof xt.name === "string") {
                return (
                  <div className="flex flex-wrap gap-2 relative">
                    <div
                      className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
                      onClick={() =>
                        dispatch(
                          removeXT({
                            path: path,
                            operator: operator,
                            index: i,
                          })
                        )
                      }
                    >
                      <IoCloseSharp />
                    </div>
                    <div>
                      <Input
                        className="w-[250px] h-[40px]"
                        type="text"
                        value={xt.name}
                        change={(e) =>
                          dispatch(
                            updateXTName({
                              path: path,
                              operator: operator,
                              index: i,
                              value: e.target.value,
                            })
                          )
                        }
                        click={() => {
                          if (dev) {
                            let val = `${operator}.XT[${i}].name`;
                            navigator.clipboard.writeText(val);
                          }
                        }}
                      />
                    </div>
                    <div>
                      <Input
                        className="w-[100px] h-[40px]"
                        placeholder="dv"
                        type="text"
                        value={xt.dv}
                        change={(e) =>
                          dispatch(
                            updateXTDv({
                              path: path,
                              operator: operator,
                              index: i,
                              value: e.target.value,
                            })
                          )
                        }
                      />
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="flex flex-wrap gap-2 relative">
                    <div
                      className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
                      onClick={() =>
                        dispatch(
                          removeXT({
                            path: path,
                            operator: operator,
                            index: i,
                          })
                        )
                      }
                    >
                      <IoCloseSharp />
                    </div>
                    <div>
                      <Dropdown
                        options={xt.name}
                        target="option"
                        value={xt.dv}
                        click={(value) => {
                          if (dev) {
                            let val = `${operator}.XT[${i}].name`;
                            navigator.clipboard.writeText(val);
                          }
                          dispatch(
                            updateXTDv({
                              path: path,
                              operator: operator,
                              index: i,
                              value: value,
                            })
                          );
                        }}
                        height={40}
                        width={250}
                      />
                    </div>
                    <div>
                      <Input
                        className="w-[100px] h-[40px]"
                        placeholder="dv"
                        type="text"
                        value={xt.dv}
                        disabled={true}
                      />
                    </div>
                  </div>
                );
              }
            })
          : null}
      </div>
    </div>
  );
};

export default XtControls;
