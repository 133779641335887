import React, { useState } from "react";

import Input from "../utils/Input";
import { useNavigate } from "react-router-dom";
import { useForgotPasswordMutation } from "../slices/passwordApiSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const Forgot = () => {
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const submitHandler = async () => {
    try {
      const res = await forgotPassword({ email }).unwrap();
      toast.success(res.message);
      navigate("/SignIn");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <section className="wh-100 m-auto pmd items-start justify-center">
      <div className="wh-100 sm:w-[350px] m-auto">
        <h1 className="font-mono text-2xl mb-5">Forgot Password</h1>
        <div className="mb-5">
          <Input
            id="email"
            value={email}
            placeholder="Enter your email"
            type="email"
            change={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-5 flex items-center gap-4">
          <button
            className="px-5 py-3 bg-cyan-950 text-light-500 font-mono"
            onClick={() => submitHandler()}
          >
            Raise Request
          </button>
          {isLoading && <Loader />}
        </div>
        <div>
          <span className="font-mono">
            Oops just remembered it!{" "}
            <span
              className="text-secondary hover:text-light-500 hover:bg-cyan-950 px-2 py-2 cursor-pointer"
              onClick={() => navigate("/SignIn")}
            >
              Sign In
            </span>
          </span>
        </div>
      </div>
    </section>
  );
};

export default Forgot;
