import React from "react";
import { useSelector } from "react-redux";
import produceClone from "../../components/Lodash";
import { useAddEntryFormMutation } from "../../slices/entryFormApiSlice";
import { toast } from "react-toastify";

const Submit = () => {
  const { forms, userForms } = useSelector((state) => state.forms);
  const { agency } = useSelector((state) => state.agency);
  const { document } = useSelector((state) => state.document);
  const { listIDS } = useSelector((state) => state.internalDependency);

  const [addEntryForm, {}] = useAddEntryFormMutation();

  const submitHandler = async () => {
    // let newForms = produceClone(forms);
    // Object.keys(forms).forEach((form) => {
    //   if (!userForms.includes(form)) {
    //     delete newForms[form];
    //   }
    // });

    let newUserForms = userForms.filter((item) => item.name !== "Submit");
    console.log(listIDS, agency, document);
    try {
      const res = await addEntryForm({
        listIDS,
        userForms: newUserForms,
        forms: forms,
        agency,
        category: document.category,
        docName: document.name,
      }).unwrap();
      toast.success(res.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="psm flex justify-center">
      <button
        className="p-2 bg-cyan-950 text-light-500"
        onClick={submitHandler}
      >
        Submit
      </button>
    </div>
  );
};

export default Submit;
