import React, { useState, useEffect } from "react";
import Input from "../utils/Input";
import Select2 from "../utils/Select2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useRegisterMutation,
  usePermissionMutation,
} from "../slices/userApiSlice";
import { setCredentials } from "../slices/authSlice";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agency, setAgency] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [register, { isLoading }] = useRegisterMutation();
  // const [permission, { isLoading: isPermission }] = usePermissionMutation();
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  const submitHandler = async () => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      try {
        // const res = await permission({
        //   name,
        //   email,
        //   phoneNo,
        //   password,
        //   agency,
        // }).unwrap();
        // toast.success(res.message);
        // navigate("/");

        const res = await register({
          name,
          email,
          phoneNo,
          password,
          agency,
        }).unwrap();

        toast.success(res.message);

        // dispatch(setCredentials({ ...res }));
        navigate("/");
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <div className="wh-100 m-auto pmd items-start justify-center">
      <div className="wh-100 sm:w-[350px] m-auto flex flex-col items-center">
        <h1 className="font-mono text-2xl mb-5">Sign Up</h1>
        <div className="mb-5">
          <Input
            id="name"
            value={name}
            placeholder="Enter your name"
            type="text"
            change={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-5">
          <Input
            id="email"
            value={email}
            placeholder="Enter your email"
            type="email"
            change={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-5">
          <Input
            id="password"
            value={password}
            placeholder="Enter your password"
            type="password"
            change={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="mb-5">
          <Input
            id="confirm password"
            value={confirmPassword}
            placeholder="Re-enter your password"
            type="password"
            change={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        {/* <div className="mb-5 flex justify-between">
          <Select2
            options={["Manager", "User"]}
            placeholder="Role"
            change={(value) => setRole(value)}
          />
          {role === "User" ? (
            <Select2 options={["Manager", "User"]} placeholder="Role" />
          ) : null}
        </div> */}
        {/* {role === "Manager" ? ( */}
        <div className="mb-5">
          <Input
            id="agency"
            value={agency}
            placeholder="Enter name of your Agency"
            type="text"
            change={(e) => setAgency(e.target.value)}
          />
        </div>
        <div className="mb-5">
          <Input
            id="phone No"
            value={phoneNo}
            placeholder="Enter your phone no"
            type="text"
            change={(e) => setPhoneNo(e.target.value)}
          />
        </div>
        {/* ) : null} */}

        <div className="mb-5 flex items-center gap-4">
          <button
            className="px-5 py-3 bg-cyan-950 text-light-500 font-mono font-bold"
            onClick={() => submitHandler()}
          >
            Request Permission from Admin
          </button>
          {/* {isLoading && <Loader />} */}
        </div>
        <div>
          <span className="font-mono font-bold">
            Already have an account?{" "}
            <span
              className="text-secondary hover:text-light-500 hover:bg-cyan-950 px-2 py-2 cursor-pointer"
              onClick={() => navigate("/SignIn")}
            >
              Sign In
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
