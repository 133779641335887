import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  updateShading,
  updateTextColor,
} from "../../../../../../slices/docsSlice";

const SavedColors = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productInfo } = useSelector((state) => state.product);
  const { indexPath } = useSelector((state) => state.docs);

  const { pathname } = useLocation();
  let splitLoc = pathname.split("/");
  const colorType = splitLoc[splitLoc.length - 1];
  if (splitLoc.length >= 4) {
    let length = splitLoc.length - 3;
    splitLoc.splice(3, length);
  }
  let newPath = splitLoc.join("/");

  const splitIndexPath = indexPath.split("/");
  const lastIndex = splitIndexPath[splitIndexPath.length - 1].split("_");

  return (
    <div className="mb-2 mt-4">
      <h1>
        Saved Colors{" "}
        <b className="bg-info-500 px-2 py-1 rounded">{colorType}</b>
      </h1>
      <div className="mb-2 mt-3 flex flex-wrap gap-5">
        {/* <div
          className={`rounded h-[50px] w-[70px] cursor-pointer border flex items-center justify-center text-xs`}
          onClick={() => {
            if (colorType === "shadeColor" || colorType === "shadeFill") {
              if (lastIndex[1] === "P") {
                navigate(`${newPath}/${indexPath}/PP`);
              } else {
                navigate(`${newPath}/${indexPath}/PP/TP/${lastIndex[0]}`);
              }
            } else if (colorType === "textColor") {
              navigate(`${newPath}/${indexPath}/PP/TP/${lastIndex[0]}`);
            }
          }}
        >
          NO COLOR
        </div> */}
        {productInfo.product.colors.map((color) => {
          return (
            <a href={indexPath}>
              {/* <div
                className={`rounded h-[50px] w-[70px] cursor-pointer border`}
                style={{ backgroundColor: color }}
                onClick={() => {
                  if (colorType === "shadeColor") {
                    dispatch(updateShading({ loc: "color", value: color }));
                    if (lastIndex[1] === "P") {
                      navigate(`${newPath}/${indexPath}/PP`);
                    } else {
                      navigate(`${newPath}/${indexPath}/PP/TP/${lastIndex[0]}`);
                    }
                  } else if (colorType === "shadeFill") {
                    dispatch(updateShading({ loc: "fill", value: color }));
                    if (lastIndex[1] === "P") {
                      navigate(`${newPath}/${indexPath}/PP`);
                    } else {
                      navigate(`${newPath}/${indexPath}/PP/TP/${lastIndex[0]}`);
                    }
                  } else if (colorType === "textColor") {
                    dispatch(updateTextColor(color));
                    navigate(`${newPath}/${indexPath}/PP/TP/0`);
                  }
                }}
              ></div> */}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SavedColors;
