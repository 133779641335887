import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useGetAgenciesQuery } from "../../slices/agencyApiSlice";
import { setAgencies } from "../../slices/agencies";

const Dashboard = () => {
  const dispatch = useDispatch();

  const {
    data: agencies,
    refetch: refetchAgencies,
    isLoading: agenciesLoading,
  } = useGetAgenciesQuery();

  useEffect(() => {
    dispatch(setAgencies(agencies));
  }, [agencies]);

  return <div>Dashboard</div>;
};

export default Dashboard;
