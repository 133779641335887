import { apiSlice } from "./apiSlice";
import { EXTERNALDEPENDENCY_URL } from "../constants";

export const externalDependencyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addExternalDependency: builder.mutation({
      query: (data) => ({
        url: `${EXTERNALDEPENDENCY_URL}`,
        method: "POST",
        body: data,
      }),
    }),
    getExternalDependencies: builder.query({
      query: () => ({
        url: EXTERNALDEPENDENCY_URL,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddExternalDependencyMutation,
  useGetExternalDependenciesQuery,
} = externalDependencyApiSlice;
