import { apiSlice } from "./apiSlice";
import { CATEGORY_URL } from "../constants";

export const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addCategory: builder.mutation({
      query: (data) => ({
        url: `${CATEGORY_URL}/addCategory`,
        method: "POST",
        body: data,
      }),
    }),
    getCategories: builder.query({
      query: () => ({
        url: `${CATEGORY_URL}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useAddCategoryMutation, useGetCategoriesQuery } =
  categoryApiSlice;
