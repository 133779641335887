import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTestDoc } from "../rahulDocx/Functions";
import { rahulDocx } from "../rahulDocx/RahulDocx";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { setSingleID } from "../../../../slices/internalDependencySlice";
import { setDocument } from "../../../../slices/documentSlice";
import { useUpdateDocumentMutation } from "../../../../slices/documentsApiSlice";
import produceClone from "../../../../components/Lodash";

import RunFunctions from "./RunFunctions";
import {
  setDocId,
  setDocs,
  setIndexPath,
  setEntryForm,
  setName,
  setCategory,
  setRunStatus,
} from "../../../../slices/docsSlice";
import { getForms, setSingleForm } from "../../../../slices/formsSlice";
import { useNavigate } from "react-router-dom";

const RunControls = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { docId, docs, runStatus, indexPath, dev } = useSelector(
    (state) => state.docs
  );
  const { document: mainFile } = useSelector((state) => state.document);
  const { internalDependency } = useSelector(
    (state) => state.internalDependency
  );
  const { forms, userForms } = useSelector((state) => state.forms);

  const [updateDocument, { isLoading: loadingUpdate }] =
    useUpdateDocumentMutation();

  const generateDocx = (doc, path) => {
    const testDoc = createTestDoc({ doc, path });
    Packer.toBlob(testDoc).then((blob) => {
      saveAs(blob, `test.docx`);
      console.log("Document created successfully");
    });
  };

  let newForms = produceClone(forms);

  const updateDocumentHandler = async () => {
    const entryForm = produceClone(docs);
    entryForm.map((doc) => {
      delete doc.properties;
      delete doc.type;
      doc.ele.map((S) => {
        delete S.type;
        delete S.headers.type;
        delete S.footers.type;
        delete S.headers.ele;
        delete S.footers.ele;
        delete S.properties;
        S.ele.map((E) => {
          delete E.ele;
          delete E.properties;
          delete E.type;
          delete E.jsFun;
          delete E.docxFun;
          delete E.noc;
          delete E.nor;
        });
      });
    });

    const filledEF = produceClone(entryForm);
    filledEF.map((doc) => {
      delete doc.name;
      doc.ele.map((S) => {
        delete S.name;
        S.ele.map((E) => {
          delete E.name;
          E.user?.XT?.map((xt) => {
            xt.name = xt.dv;
          });
          E.manager?.XT?.map((xt) => {
            xt.name = xt.dv;
          });
          if (E.user?.condition) {
            E.user.condition = 1;
            E.user.MXT.map((mxt) => {
              mxt.name = mxt.dv;
            });
            E.user.MXT = [E.user.MXT];
          }
          if (E.manager?.condition) {
            E.manager.condition = 1;
            E.manager.MXT.map((mxt) => {
              mxt.name = mxt.dv;
            });
            E.manager.MXT = [E.manager.MXT];
          }
        });
      });
    });

    try {
      const res = await updateDocument({
        docId,
        docs,
        entryForm,
        filledEF,
        indexPath,
      }).unwrap();
      console.log(res);
      if (
        `${res.document.category}$${res.document.name}` ===
        `${mainFile.category}$${mainFile.name}`
      ) {
        dispatch(setDocument(res.document));
        if (!dev) {
          dispatch(setEntryForm(res.document.filledEF));
        }
        newForms[`${res.document.category}$${res.document.name}`] =
          res.document;
        newForms[`${res.document.category}$${res.document.name}_EF`] =
          res.document.filledEF;
        if (dev) {
          dispatch(
            setSingleForm({
              name: `${res.document.category}$${res.document.name}`,
              value: res.document,
            })
          );
        }
        if (!dev) {
          dispatch(
            getForms({
              forms: newForms,
              userForms,
            })
          );
        }
      } else {
        internalDependency.map((doc, i) => {
          if (
            `${res.document.category}$${res.document.name}` ===
            `${doc.category}$${doc.name}`
          ) {
            dispatch(
              setSingleID({
                i,
                doc: res.document,
              })
            );
            if (!dev) {
              dispatch(setEntryForm(res.document.filledEF));
            }
            newForms[`${res.document.category}$${res.document.name}`] =
              res.document;
            newForms[`${`res.document.category`}$${res.document.name}_EF`] =
              res.document.filledEF;
            if (!dev) {
              dispatch(
                getForms({
                  forms: newForms,
                  userForms,
                })
              );
            }
          }
        });
      }

      toast.success(res.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e.target.result);

        const setParaProperties = (E) => {
          E.properties = {
            ...E.properties,
            text: {},
          };
        };

        const setCellProperties = (C) => {
          C.properties = {
            ...C.properties,
            para: {},
            text: {},
          };
          C.ele.map((E) => {
            if (E.type === "para") {
              setParaProperties(E);
            } else if (E.type === "table") {
              setTableProperties(E);
            }
          });
        };

        const setRowProperties = (R) => {
          R.properties = {
            ...R.properties,
            cell: {},
            para: {},
            text: {},
          };
          R.ele.map((C) => {
            setCellProperties(C);
          });
        };

        const setTableProperties = (E) => {
          E.properties = {
            ...E.properties,
            row: {},
            cell: {},
            para: {},
            text: {},
          };
          E.ele.map((R, l) => {
            setRowProperties(R);
          });
        };

        jsonData.document.map((D, i) => {
          D.ele.map((S, j) => {
            S.properties = {
              ...S.properties,
              table: {},
              row: {},
              cell: {},
              para: {},
              text: {},
            };
            S.ele.map((E, k) => {
              if (E.type === "para") {
                setParaProperties(E);
              } else if (E.type === "table") {
                setTableProperties(E);
              }
            });
          });
        });
        dispatch(setDocId(jsonData._id));
        dispatch(setDocs(jsonData.document));
        dispatch(setEntryForm(jsonData.entryForm));
        dispatch(setIndexPath(jsonData.indexPath));
        dispatch(setName(jsonData.name));
        dispatch(setCategory(jsonData.category));
        dispatch(setRunStatus(jsonData.runStatus));
        toast.success("Hurrey");
        // navigate(`/manager/${value}/${jsonData.indexPath}`);
        // dispatch(setItems(jsonData.items)); // Adjust based on your JSON structure
      } catch (error) {
        toast.error("Invalid JSON file:" + error);
      }
    };

    reader.onerror = (error) => {
      toast.error("Error reading file:" + error);
    };

    reader.readAsText(file);
  };

  return (
    <div className="min-h-[50px] border-t w-full text-light-500 px-3 py-2 flex flex-wrap justify-between items-center gap-5">
      <div className="flex gap-4">
        <button
          className="bg-info-500 px-2 py-1"
          onClick={() => {
            var dataStr =
              "data:text/json;charset=utf-8," +
              encodeURIComponent(
                JSON.stringify({
                  ...mainFile,
                  document: docs,
                  indexPath,
                })
              );
            var dlAnchorElem = document.getElementById("downloadAnchorElem");
            dlAnchorElem.setAttribute("href", dataStr);
            dlAnchorElem.setAttribute("download", "CEF.json");
            dlAnchorElem.click();
          }}
        >
          Download JSON
          <a id="downloadAnchorElem"></a>
        </button>
        <div className="relative">
          {/* Hidden file input */}
          <input
            type="file"
            accept=".json"
            onChange={handleFileChange}
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          />
          {/* Custom button */}
          <button className="w-[110px] h-[40px] bg-info-500 text-white focus:outline-none">
            Upload JSON
          </button>
        </div>
        {!runStatus ? (
          <button
            className="bg-primary-500 px-2 py-1"
            onClick={() => updateDocumentHandler()}
          >
            Update Doc
          </button>
        ) : null}

        {docs.map((doc, di) => {
          return (
            <button
              onClick={() => generateDocx(doc, `${di}`)}
              className="bg-warning-500 px-2 py-1 text-dark-900"
            >
              {doc.name}
            </button>
          );
        })}
      </div>
      <RunFunctions />
    </div>
  );
};

export default RunControls;
