import React from "react";

const TableCell = ({ children, isHeader = false, click, className, style }) => {
  return (
    <td
      className={`${className} py-3 px-3 text-sm ${
        isHeader ? "text-left font-bold border-b" : "text-gray-700 border-b"
      }`}
      style={style}
      onClick={click}
    >
      {children}
    </td>
  );
};

export default TableCell;
