import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Table from "../../utils/Table/Table";
import TableRow from "../../utils/Table/TableRow";
import TableCell from "../../utils/Table/TableCell";
import Input from "../../utils/Input";
import Dropdown from "../../utils/Dropdown";

import { setUser } from "../../slices/userSlice";
import {
  useAssignDocsMutation,
  useRemoveDocsMutation,
} from "../../slices/userApiSlice";
import { useGetDocumentsQuery } from "../../slices/documentsApiSlice";
import { toast } from "react-toastify";

const AssignDocuments = () => {
  const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState();
  const [searchCategory, setSearchCategory] = useState();
  const [assignDocs, {}] = useAssignDocsMutation();
  const [removeDocs, {}] = useRemoveDocsMutation();
  const { user } = useSelector((state) => state.user);
  const { categories } = useSelector((state) => state.categories);

  const { params } = useParams();

  const {
    data: documents,
    refetch: refetchDocuments,
    isLoading: documentsLoading,
  } = useGetDocumentsQuery({
    keyword: searchKeyword,
    category: searchCategory,
    params,
  });

  const assignDocumentsHandler = async (docId, name, category) => {
    try {
      const res = await assignDocs({
        userId: user._id,
        docId,
        name,
        category,
      }).unwrap();
      dispatch(setUser(res.data));
      toast.success(res.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const removeDocumentsHandler = async (docId) => {
    try {
      const res = await removeDocs({
        userId: user._id,
        docId,
      }).unwrap();
      dispatch(setUser(res.data));
      toast.success(res.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="psm gap-2">
      <div className="mb-10">
        <h2 className="font-mono pb-2">Document Description</h2>
        {user ? (
          <Table>
            <TableRow>
              <TableCell>NAME</TableCell>
              <TableCell>{user.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>{user.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Assigned Documents</TableCell>
              <TableCell style={{ padding: "0px" }}>
                <div className="flex gap-4">
                  {user.assignedDocs.map((doc) => {
                    return (
                      <button
                        className="cursor-pointer bg-cyan-950 text-light-500 hover:bg-danger-500 h-[48px] px-2"
                        onClick={() => removeDocumentsHandler(doc.docId)}
                      >
                        {doc.name}
                      </button>
                    );
                  })}
                </div>
              </TableCell>
            </TableRow>
          </Table>
        ) : null}
      </div>
      <div className="flex items-start gap-10">
        <div className="morphous p-4 flex-1">
          <h2 className="font-mono pb-2">Internal Dependency</h2>
          <div className="flex gap-4 pb-4">
            <Input
              type="text"
              value={searchKeyword}
              change={(e) => setSearchKeyword(e.target.value)}
              placeholder="Enter Doc Name"
            />
            <Dropdown
              options={categories}
              target="option.category"
              value={searchCategory}
              name="category"
              click={(value) => setSearchCategory(value.category)}
              style={
                searchCategory
                  ? { backgroundColor: "#035afc", color: "white" }
                  : null
              }
            />
          </div>
          <Table>
            <TableRow isHeader={true}>
              <TableCell isHeader={true}>Name</TableCell>
              <TableCell isHeader={true}>Category</TableCell>
              <TableCell isHeader={true}>Add</TableCell>
            </TableRow>
            {documents?.documents?.map((document, sno) => {
              return (
                <TableRow
                  key={sno}
                  className={(sno + 1) % 2 === 0 ? "bg-gray-100" : null}
                >
                  <TableCell>{document.name}</TableCell>
                  <TableCell>{document.category}</TableCell>
                  <TableCell
                    className="bg-cyan-950 text-center text-white cursor-pointer"
                    click={() =>
                      assignDocumentsHandler(
                        document._id,
                        document.name,
                        document.category
                      )
                    }
                  >
                    ADD
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default AssignDocuments;
