import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RiSave3Fill } from "react-icons/ri";
import { GiCardPickup } from "react-icons/gi";

const RetractableDiv = ({ show, name, highLight, children }) => {
  const { dev } = useSelector((state) => state.docs);
  const [isCollapsed, setIsCollapsed] = useState(show);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (dev) {
      setIsCollapsed(true);
    }
  }, [dev]);

  return (
    <div className={`${isCollapsed ? "bg-cyan-950 text-light-500" : "pt-2"}`}>
      <div
        className="flex items-center justify-center cursor-pointer transition"
        onClick={toggleCollapse}
      >
        <h1 className="font-mono text-center">{name}</h1>
        {/* <div className="flex gap-2 items-center">
          <b className="bg-info-500 px-2 py-1 rounded line-clamp-1 max-w-[150px]">
            {highLight}
          </b>
          {isCollapsed ? <FaChevronUp /> : <FaChevronDown />}
        </div> */}
      </div>

      <div
        className={`${
          isCollapsed ? "opacity-0 h-0" : "opacity-100 h-auto"
        } transition-all duration-300 ease-in-out overflow-hidden`}
      >
        {children}
      </div>
    </div>
  );
};

export default RetractableDiv;
