import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  agencies: [],
};

const agenciesSlice = createSlice({
  name: "agencies",
  initialState,
  reducers: {
    setAgencies: (state, { payload }) => {
      state.agencies = payload;
    },
    resetAgencies: (state, { payload }) => {
      state.agencies = [];
    },
  },
});

export const { setAgencies, resetAgencies } = agenciesSlice.actions;

export default agenciesSlice.reducer;
