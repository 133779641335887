import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Dropdown from "../../../../../../../utils/Dropdown";
import Input from "../../../../../../../utils/Input";
import { RiSave3Fill } from "react-icons/ri";
import { GiCardPickup } from "react-icons/gi";
import { switchShadeType } from "../../../CommFunctions";
import {
  updateShading,
  setCellMargin,
  updateCellBorder,
  setCellWidth,
  updateColSpan,
  updateRowSpan,
  addPara,
  addTable,
  replaceTable,
} from "../../../../../../../slices/docsSlice";
import { returnPropEle, switchWidthType } from "../../../CommFunctions";
import Button from "../../../../../../../utils/Button";

const CellProperties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    docs,
    indexPath,
    SIP,
    RRIP_TC,
    RRIP_TR,
    RRIP_TB,
    RRIP_DOC,
    NIP_TC,
    NIP_TR,
    NIP_TB,
    NIP_DOC,
    minimal,
  } = useSelector((state) => state.docs);
  const { cellName } = useSelector((state) => state.element);
  // const { productInfo } = useSelector((state) => state.product);
  const { shadingTypes, widthTypes, isTrueOrFalse } = useSelector(
    (state) => state.options
  );
  const [shadeColor, setShadeColor] = useState();
  const [shadeFill, setShadeFill] = useState();
  const [shadeType, setShadeType] = useState();
  const [marginTop, setMarginTop] = useState();
  const [marginBottom, setMarginBottom] = useState();
  const [marginLeft, setMarginLeft] = useState();
  const [marginRight, setMarginRight] = useState();
  const [verticalAlign, setVerticalAlign] = useState();
  const [widthType, setWidthType] = useState();
  const [widthSize, setWidthSize] = useState();
  const [columnSpan, setColumnSpan] = useState();
  const [rowSpan, setRowSpan] = useState();
  const [border, setBorder] = useState();

  const [rows, setRows] = useState();
  const [cols, setCols] = useState();

  let SIPHF = SIP[2];

  let NIP_TC_Arr = NIP_TC.split("_");

  useEffect(() => {
    let doc = returnPropEle(docs, RRIP_DOC);
    let table = RRIP_TB ? returnPropEle(docs, RRIP_TB) : undefined;
    let row = RRIP_TR ? returnPropEle(docs, RRIP_TR) : undefined;
    let cell = RRIP_TC ? returnPropEle(docs, RRIP_TC) : undefined;

    if (cell) {
      if (cell.properties.shading) {
        setShadeColor(`#${cell.properties.shading.color.toLowerCase()}`);
        setShadeFill(`#${cell.properties.shading.fill.toLowerCase()}`);
        setShadeType(cell.properties.shading.type);
      }

      if (cell.properties.margins) {
        setMarginLeft(cell.properties.margins.left);
        setMarginRight(cell.properties.margins.right);
        setMarginTop(cell.properties.margins.top);
        setMarginBottom(cell.properties.margins.bottom);
      } else if (table && table.properties.margins) {
        setMarginLeft(table.properties.margins.left);
        setMarginRight(table.properties.margins.right);
        setMarginTop(table.properties.margins.top);
        setMarginBottom(table.properties.margins.bottom);
      } else if (doc.properties.cell.margins) {
        setMarginLeft(doc.properties.cell.margins.left);
        setMarginRight(doc.properties.cell.margins.right);
        setMarginTop(doc.properties.cell.margins.top);
        setMarginBottom(doc.properties.cell.margins.bottom);
      } else {
        setMarginLeft(doc.properties.table.margins.left);
        setMarginRight(doc.properties.table.margins.right);
        setMarginTop(doc.properties.table.margins.top);
        setMarginBottom(doc.properties.table.margins.bottom);
      }

      if (cell.properties.verticalAlign) {
        setVerticalAlign(cell.properties.verticalAlign);
      } else {
        setVerticalAlign(doc.properties.cell.verticalAlign);
      }

      if (`${cell.properties.borders}` !== "undefined") {
        setBorder(cell.properties.borders === true ? "true" : "false");
      } else if (table?.properties?.borders) {
        setBorder(table.properties.borders);
      } else {
        setBorder(doc.properties.tableBorder);
      }

      if (cell.properties.width) {
        setWidthType(cell.properties.width.type);
        setWidthSize(cell.properties.width.size);
      } else if (row.properties.width) {
        setWidthType(row.properties.width.type);
        setWidthSize(row.properties.width.size);
      } else {
        setWidthType("");
        setWidthSize("");
      }

      if (cell.properties.columnSpan) {
        setColumnSpan(cell.properties.columnSpan);
      } else {
        setColumnSpan();
      }
      if (cell.properties.rowSpan) {
        setRowSpan(cell.properties.rowSpan);
      } else {
        setRowSpan();
      }
    }
  }, [docs, NIP_TC, NIP_TR, NIP_TB, NIP_DOC]);

  return (
    <div className="psm morphous bg-gray-100">
      {/* </RetractableDiv> */}
      <div className="mb-2">
        <Outlet />
      </div>
      <h1 className="font-mono text-center pb-4">
        Cell Properties [{cellName}]
        {/* [{`${eval(dynName)}_${NIP_TC_Arr[NIP_TC_Arr.length - 1]}`}] */}
      </h1>
      {/* <RetractableDiv
        show={false}
        name="Cell Properties"
        highLight={`${eval(dynName)}_${NIP_TC_Arr[NIP_TC_Arr.length - 1]}`}
      > */}
      <div className={`${minimal ? "hidden" : "block"}`}>
        <div className="flex gap-4 pb-4">
          <div>
            <h2 className="pb-1">Cell Color</h2>
            <Input
              className="h-[40px] w-[100px] p-0 shadow-none"
              value={shadeColor}
              blur={(e) => {
                dispatch(
                  updateShading({
                    loc: "color",
                    path: RRIP_TC,
                    value: e.target.value,
                    layoutEle:
                      SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                  })
                );
              }}
              change={(e) => setShadeColor(e.target.value)}
              type="color"
            />
            {/* <div
                  className={`text-2xl h-[35px] w-[35px] flex items-center justify-center bg-success-500 cursor-pointer rounded`}
                  onClick={() => {
                    navigate(`${newPath}/${indexPath}/PP/SC/shadeColor`);
                  }}
                >
                  <GiCardPickup />
                </div> */}
          </div>
          <div>
            <h2 className="pb-1">Text Color</h2>
            <Input
              className="h-[40px] w-[100px] p-0 shadow-none"
              value={shadeFill}
              blur={(e) => {
                dispatch(
                  updateShading({
                    loc: "fill",
                    path: RRIP_TC,
                    value: e.target.value,
                    layoutEle:
                      SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                  })
                );
              }}
              change={(e) => setShadeFill(e.target.value)}
              type="color"
            />
            {/* <div
                  className={`text-2xl h-[35px] w-[35px] flex items-center justify-center bg-success-500 cursor-pointer rounded`}
                  onClick={() => {
                    navigate(`${newPath}/${indexPath}/PP/SC/shadeFill`);
                  }}
                >
                  <GiCardPickup />
                </div> */}
          </div>
          <div>
            <h2 className="pb-1">Shade Type</h2>
            <Dropdown
              placeholder="Shade Type"
              value={shadeType}
              options={shadingTypes}
              name="Shade Type"
              width={250}
              height={40}
              target="option"
              click={(value) => {
                dispatch(
                  updateShading({
                    loc: "type",
                    path: RRIP_TC,
                    value,
                    layoutEle:
                      SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                  })
                );
              }}
            />
          </div>
        </div>
        <div className="flex gap-4 pb-4">
          <div>
            <h2 className="pb-1">Left Margin</h2>
            <Input
              className="h-[40px] w-[150px]"
              value={marginLeft}
              placeholder="left margin"
              blur={(e) =>
                dispatch(
                  updateCellMargin({
                    loc: "left",
                    cell: RRIP_TC,
                    table: RRIP_TB,
                    doc: RRIP_DOC,
                    value: e.target.value,
                    layoutEle:
                      SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                  })
                )
              }
              change={(e) => setMarginLeft(e.target.value)}
              type="text"
            />
          </div>
          <div>
            <h2 className="pb-1">Right Margin</h2>
            <Input
              className="h-[40px] w-[150px]"
              value={marginRight}
              placeholder="right margin"
              blur={(e) =>
                dispatch(
                  updateCellMargin({
                    loc: "right",
                    cell: RRIP_TC,
                    table: RRIP_TB,
                    doc: RRIP_DOC,
                    value: e.target.value,
                    layoutEle:
                      SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                  })
                )
              }
              change={(e) => setMarginRight(e.target.value)}
              type="text"
            />
          </div>
          <div>
            <h2 className="pb-1">Top Margin</h2>
            <Input
              className="h-[40px] w-[150px]"
              value={marginTop}
              placeholder="top margin"
              blur={(e) =>
                dispatch(
                  updateCellMargin({
                    loc: "top",
                    cell: RRIP_TC,
                    table: RRIP_TB,
                    doc: RRIP_DOC,
                    value: e.target.value,
                    layoutEle:
                      SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                  })
                )
              }
              change={(e) => setMarginTop(e.target.value)}
              type="text"
            />
          </div>
          <div>
            <h2 className="pb-1">Bottom Margin</h2>
            <Input
              className="h-[40px] w-[150px]"
              value={marginBottom}
              placeholder="bottom margin"
              blur={(e) =>
                dispatch(
                  updateCellMargin({
                    loc: "bottom",
                    cell: RRIP_TC,
                    table: RRIP_TB,
                    doc: RRIP_DOC,
                    value: e.target.value,
                    layoutEle:
                      SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                  })
                )
              }
              change={(e) => setMarginBottom(e.target.value)}
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex gap-4">
          <div>
            <h2 className="pb-1">Col Span</h2>
            <div>
              <Input
                className="h-[40px] w-[100px]"
                value={columnSpan === undefined ? 1 : columnSpan}
                blur={(e) =>
                  dispatch(
                    updateColSpan({
                      path: RRIP_TC,
                      row: RRIP_TR,
                      table: RRIP_TB,
                      value: e.target.value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  )
                }
                change={(e) => setColumnSpan(e.target.value)}
              />
            </div>
          </div>
          <div>
            <h2 className="pb-1">Row Span</h2>
            <div>
              <Input
                className="h-[40px] w-[100px]"
                value={rowSpan === undefined ? 1 : rowSpan}
                blur={(e) =>
                  dispatch(
                    updateRowSpan({
                      path: RRIP_TC,
                      row: RRIP_TR,
                      table: RRIP_TB,
                      value: e.target.value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  )
                }
                change={(e) => setRowSpan(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={`${minimal ? "hidden" : "block mb-2"}`}>
          <h2 className="pb-1">Set Width Type</h2>
          <Dropdown
            value={widthType}
            options={widthTypes}
            target="option"
            placeholder="width type"
            name="WT"
            width={150}
            height={40}
            click={(value) => {
              dispatch(
                setCellWidth({
                  loc: "type",
                  cell: RRIP_TC,
                  value,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
          />
        </div>
        <div className="mb-2">
          <h2 className="pb-1">Set Width Size</h2>
          <Input
            className="h-[40px] w-[150px]"
            value={widthSize}
            type="text"
            blur={(e) => {
              dispatch(
                setCellWidth({
                  loc: "size",
                  cell: RRIP_TC,
                  value: e.target.value,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
            change={(e) => setWidthSize(e.target.value)}
            // disabled={widthType === "NIL" ? true : false}
          />
        </div>
        <div className="min-w-[50px]">
          <h2 className="pb-1">Border</h2>
          <Button
            name="Border"
            value={border}
            click={() => {
              dispatch(
                updateCellBorder({
                  cell: RRIP_TC,
                  value: border,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
          />
        </div>
      </div>

      <div className="pb-4">
        <h2 className="pb-1">Add Para or Table</h2>
        <div className="flex gap-4">
          <button
            className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
            onClick={() => {
              dispatch(
                addPara({
                  path: RRIP_TC,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
            // disabled={!eleName}
          >
            Para
          </button>
          <div>
            <Input
              className="h-[40px] w-[100px]"
              value={rows}
              change={(e) => setRows(e.target.value)}
              placeholder="Rows"
              type="number"
            />
          </div>

          <div>
            <Input
              className="h-[40px] w-[100px]"
              value={cols}
              change={(e) => setCols(e.target.value)}
              placeholder="Cols"
              type="number"
            />
          </div>

          <button
            className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
            onClick={() => {
              dispatch(
                replaceTable({
                  path: RRIP_TC,
                  rows: parseFloat(rows),
                  cols: parseFloat(cols),
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
            // disabled={!eleName}
          >
            Replace Table
          </button>

          <button
            className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
            onClick={() => {
              dispatch(
                addTable({
                  path: RRIP_TC,
                  rows: parseFloat(rows),
                  cols: parseFloat(cols),
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
            // disabled={!eleName}
          >
            Table
          </button>
        </div>
      </div>
    </div>
  );
};

export default CellProperties;
