import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Dropdown from "../../../../../utils/Dropdown";
import Input from "../../../../../utils/Input";
import { RiSave3Fill } from "react-icons/ri";
import { GiCardPickup } from "react-icons/gi";
import {
  setTableWidth,
  setTableCellMargin,
  updateTableBorder,
  setTableAlignment,
  addColumnToTable,
  addRowToTable,
  updateShading,
  // TableCell properties
  setTableCellAlignment,
  // TablePara properties
  setTableParaAlignment,
  // TableText properties
  setTableTextFont,
  setTableTextFontSize,
} from "../../../../../slices/docsSlice";
import {
  returnPropEle,
  switchWidthType,
  switchVerAlignment,
} from "../CommFunctions";
import XtControls from "../Tools/xtControls";
import MxtControls from "../Tools/mxtControls";
import JsFunControls from "../Tools/jsFunControls";

const TableProperties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    docs,
    indexPath,
    SIP,
    RRIP_TB,
    RRIP_DOC,
    NIP_TB,
    NIP_DOC,
    dev,
    minimal,
  } = useSelector((state) => state.docs);
  const { tableName } = useSelector((state) => state.element);
  // const { productInfo } = useSelector((state) => state.product);
  const {
    fonts,
    fontSizes,
    widthTypes,
    alignments,
    cellAlignments,
    tableAlignments,
    isTrueOrFalse,
    shadingTypes,
  } = useSelector((state) => state.options);

  const [widthType, setWidthType] = useState();
  const [widthSize, setWidthSize] = useState();
  const [marginTop, setMarginTop] = useState();
  const [marginBottom, setMarginBottom] = useState();
  const [marginLeft, setMarginLeft] = useState();
  const [marginRight, setMarginRight] = useState();
  const [border, setBorder] = useState();
  const [alignment, setAlignment] = useState();

  // Row Properties

  // Cell Properties
  const [verticalAlign, setVerticalAlign] = useState();
  // Para Properties
  const [paraAlignment, setParaAlignment] = useState();
  // Text Properties
  const [font, setFont] = useState();
  const [fontSize, setFontSize] = useState();

  const [tableStatus, setTableStatus] = useState(dev ? "jsfun" : "properties");

  let SIPHF = SIP[2];

  useEffect(() => {
    if (docs.length === 0) {
      navigate(`${newPath}`);
    }
  }, [docs]);

  useEffect(() => {
    setTableStatus(dev ? "jsfun" : "properties");
  }, [indexPath, dev]);

  let dynEle = "docs";
  NIP_TB.split("_").forEach((item, i) => {
    dynEle =
      dynEle + `['${item}'].${i === NIP_TB.split("_").length - 1 ? "" : "ele"}`;
  });

  dynEle = dynEle.slice(0, -1);

  useEffect(() => {
    const table = RRIP_TB ? returnPropEle(docs, RRIP_TB) : undefined;
    const doc = returnPropEle(docs, RRIP_DOC);

    if (table) {
      if (table.properties.width) {
        setWidthType(table.properties.width.type);
        setWidthSize(table.properties.width.size);
      } else {
        setWidthType(doc.properties.table.width.type);
        setWidthSize(doc.properties.table.width.size);
      }
      if (table.properties.margins) {
        setMarginLeft(table.properties.margins.left);
        setMarginRight(table.properties.margins.right);
        setMarginTop(table.properties.margins.top);
        setMarginBottom(table.properties.margins.bottom);
      } else if (doc.properties.cell.margins) {
        setMarginLeft(doc.properties.cell.margins.left);
        setMarginRight(doc.properties.cell.margins.right);
        setMarginTop(doc.properties.cell.margins.top);
        setMarginBottom(doc.properties.cell.margins.bottom);
      } else {
        setMarginLeft(doc.properties.table.margins.left);
        setMarginRight(doc.properties.table.margins.right);
        setMarginTop(doc.properties.table.margins.top);
        setMarginBottom(doc.properties.table.margins.bottom);
      }
      if (`${table.properties.borders}` !== "undefined") {
        setBorder(table.properties.borders === true ? "true" : "false");
      } else {
        setBorder(doc.properties.tableBorder);
      }
      if (table.properties.alignment) {
        setAlignment(table.properties.alignment);
      }

      // TableCell Properties
      if (table.properties.cell?.verticalAlign) {
        setVerticalAlign(table.properties.cell.verticalAlign);
      } else {
        setVerticalAlign(doc.properties.cell.verticalAlign);
      }

      // TablePara Properties
      if (table.properties.para?.alignment) {
        setParaAlignment(table.properties.para.alignment);
      } else {
        setParaAlignment(doc.properties.para.alignment);
      }

      // TableText Properties
      if (table.properties.text.font) {
        setFont(table.properties.text.font);
      } else {
        setFont(doc.properties.text.font);
      }
      if (table.properties.text.fontSize) {
        setFontSize(table.properties.text.fontSize);
      } else {
        setFontSize(doc.properties.text.fontSize);
      }
    }
  }, [docs, NIP_DOC, NIP_TB]);

  let activeStyle = "bg-cyan-950 text-light-500";

  return (
    <section className="psm bg-gray-100">
      <div className="mb-2">
        <Outlet />
      </div>
      {SIP.length === 3 ? (
        <ul className="flex justify-center border-b border-cyan-950 mb-4">
          <li className="-mb-px mr-1">
            <a
              className={`${
                tableStatus === "properties" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setTableStatus("properties")}
            >
              Properties
            </a>
          </li>
          <li className="mr-1">
            <a
              className={`${
                tableStatus === "xt" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setTableStatus("xt")}
            >
              XT
            </a>
          </li>
          <li className="mr-1">
            <a
              className={`${
                tableStatus === "mxt" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setTableStatus("mxt")}
            >
              MXT
            </a>
          </li>
          <li className="mr-1">
            <a
              className={`${
                tableStatus === "jsfun" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setTableStatus("jsfun")}
            >
              JS Fun
            </a>
          </li>
          <li className="mr-1">
            <a
              className={`${
                tableStatus === "docxfun" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setTableStatus("docxfun")}
            >
              DOCX Fun
            </a>
          </li>
        </ul>
      ) : null}
      {tableStatus === "properties" ? (
        <>
          <div>
            <h1 className="font-mono text-center pb-4">
              Table Properties [{tableName ? tableName : ""}]
            </h1>
            <div className="flex flex-col gap-4 pb-4">
              <div className="flex flex-wrap gap-4">
                <div>
                  <h2 className="pb-1">Row</h2>
                  <button
                    className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
                    onClick={() => {
                      dispatch(
                        addRowToTable({
                          table: RRIP_TB,
                          layoutEle:
                            SIPHF === "0_H" || SIPHF === "0_F"
                              ? SIPHF
                              : undefined,
                        })
                      );
                    }}
                  >
                    Row
                  </button>
                </div>
                <div>
                  <h2 className="pb-1">Column</h2>
                  <button
                    className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
                    onClick={() => {
                      dispatch(
                        addColumnToTable({
                          table: RRIP_TB,
                          layoutEle:
                            SIPHF === "0_H" || SIPHF === "0_F"
                              ? SIPHF
                              : undefined,
                        })
                      );
                    }}
                  >
                    Column
                  </button>
                </div>
              </div>
              <div className="flex flex-wrap gap-4">
                <div>
                  <h2 className="pb-1">Set Width Type</h2>
                  <Dropdown
                    className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                    value={widthType}
                    options={widthTypes}
                    target="option"
                    name="WT"
                    placeholder="width type"
                    width={150}
                    height={40}
                    click={(value) => {
                      dispatch(
                        setTableWidth({
                          loc: "type",
                          table: RRIP_TB,
                          value,
                          layoutEle:
                            SIPHF === "0_H" || SIPHF === "0_F"
                              ? SIPHF
                              : undefined,
                        })
                      );
                    }}
                  />
                </div>
                <div>
                  <h2 className="pb-1">Set Width Size</h2>
                  <Input
                    className="h-[40px] w-[150px]"
                    value={widthSize}
                    type="text"
                    blur={(e) => {
                      dispatch(
                        setTableWidth({
                          loc: "size",
                          table: RRIP_TB,
                          doc: RRIP_DOC,
                          value: e.target.value,
                          layoutEle:
                            SIPHF === "0_H" || SIPHF === "0_F"
                              ? SIPHF
                              : undefined,
                        })
                      );
                    }}
                    change={(e) => setWidthSize(e.target.value)}
                  />
                </div>
                <div>
                  <h2 className="pb-1">Set Border</h2>
                  <Dropdown
                    className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                    value={border}
                    options={isTrueOrFalse}
                    target="option"
                    placeholder="border"
                    width={150}
                    height={40}
                    click={(value) => {
                      dispatch(
                        updateTableBorder({
                          table: RRIP_TB,
                          value,
                          layoutEle:
                            SIPHF === "0_H" || SIPHF === "0_F"
                              ? SIPHF
                              : undefined,
                        })
                      );
                    }}
                    style={
                      border === "true"
                        ? { backgroundColor: "#083344", color: "#f8f9fa" }
                        : {}
                    }
                  />
                </div>
                <div>
                  <h2 className="pb-1">Table Alignment</h2>
                  <Dropdown
                    className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                    value={alignment}
                    name="alignment"
                    options={tableAlignments}
                    target="option"
                    placeholder="alignment"
                    width={150}
                    height={40}
                    click={(value) => {
                      dispatch(
                        setTableAlignment({
                          table: RRIP_TB,
                          value,
                          layoutEle:
                            SIPHF === "0_H" || SIPHF === "0_F"
                              ? SIPHF
                              : undefined,
                        })
                      );
                    }}
                  />
                </div>
              </div>
              <div className={`${minimal ? "hidden" : "block"}`}>
                <div className="flex flex-wrap gap-4">
                  <div>
                    <h2 className="pb-1">Left Margin</h2>
                    <Input
                      className="h-[40px] w-[150px]"
                      value={marginLeft}
                      placeholder="Left Margin"
                      blur={(e) =>
                        dispatch(
                          setTableCellMargin({
                            loc: "left",
                            table: RRIP_TB,
                            value: e.target.value,
                            layoutEle:
                              SIPHF === "0_H" || SIPHF === "0_F"
                                ? SIPHF
                                : undefined,
                          })
                        )
                      }
                      change={(e) => setMarginLeft(e.target.value)}
                      type="text"
                    />
                  </div>
                  <div>
                    <h2 className="pb-1">Right Margin</h2>
                    <Input
                      className="h-[40px] w-[150px]"
                      value={marginRight}
                      placeholder="right margin"
                      blur={(e) =>
                        dispatch(
                          setTableCellMargin({
                            loc: "right",
                            table: RRIP_TB,
                            value: e.target.value,
                            layoutEle:
                              SIPHF === "0_H" || SIPHF === "0_F"
                                ? SIPHF
                                : undefined,
                          })
                        )
                      }
                      change={(e) => setMarginRight(e.target.value)}
                      type="text"
                    />
                  </div>
                  <div>
                    <h2 className="pb-1">Top Margin</h2>
                    <Input
                      className="h-[40px] w-[150px]"
                      value={marginTop}
                      placeholder="top margin"
                      blur={(e) =>
                        dispatch(
                          setTableCellMargin({
                            loc: "top",
                            table: RRIP_TB,
                            value: e.target.value,
                            layoutEle:
                              SIPHF === "0_H" || SIPHF === "0_F"
                                ? SIPHF
                                : undefined,
                          })
                        )
                      }
                      change={(e) => setMarginTop(e.target.value)}
                      type="text"
                    />
                  </div>
                  <div>
                    <h2 className="pb-1">Bottom Margin</h2>
                    <Input
                      className="h-[40px] w-[150px]"
                      value={marginBottom}
                      placeholder="bottom margin"
                      blur={(e) =>
                        dispatch(
                          setTableCellMargin({
                            loc: "bottom",
                            table: RRIP_TB,
                            value: e.target.value,
                            layoutEle:
                              SIPHF === "0_H" || SIPHF === "0_F"
                                ? SIPHF
                                : undefined,
                          })
                        )
                      }
                      change={(e) => setMarginBottom(e.target.value)}
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <h1 className="mb-1 bg-green-500 pl-2">Row Properties</h1>
            <div className="flex flex-wrap gap-4 pb-4"></div>
          </div> */}
          <div className={`${minimal ? "hidden" : "block"}`}>
            <h1 className="mb-1 bg-yellow-500 pl-2">Cell Properties</h1>
            <div className="flex flex-wrap gap-4 pb-4">
              <div>
                <h2 className="pb-1">Vertical Alignment</h2>
                <Dropdown
                  className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                  value={verticalAlign}
                  options={cellAlignments}
                  target="option"
                  placeholder="alignment"
                  width={150}
                  height={40}
                  click={(value) => {
                    dispatch(
                      setTableCellAlignment({
                        table: RRIP_TB,
                        value,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className={`${minimal ? "hidden" : "block"}`}>
            <h1 className="mb-1 bg-teal-500 pl-2">Para Properties</h1>
            <div className="flex flex-wrap gap-4 pb-4">
              <div>
                <h2 className="pb-1">Horizontal Alignment</h2>
                <Dropdown
                  className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                  value={paraAlignment}
                  options={alignments}
                  target="option"
                  placeholder="alignment"
                  name="HL"
                  width={150}
                  height={40}
                  click={(value) => {
                    dispatch(
                      setTableParaAlignment({
                        table: RRIP_TB,
                        value,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className={`${minimal ? "hidden" : "block"}`}>
            <h1 className="mb-1 bg-purple-500 pl-2">Text Properties</h1>
            <div className="flex flex-wrap gap-4 pb-4">
              <div>
                <h2 className="pb-1">Set Text Font</h2>
                <Dropdown
                  className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                  value={font}
                  options={fonts}
                  target="option"
                  width={250}
                  height={40}
                  click={(value) => {
                    dispatch(
                      setTableTextFont({
                        table: RRIP_TB,
                        value,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    );
                  }}
                />
              </div>
              <div>
                <h2 className="pb-1">Set Text Size</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={fontSize}
                  type="text"
                  blur={(e) =>
                    dispatch(
                      setTableTextFontSize({
                        table: RRIP_TB,
                        value: e.target.value,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    )
                  }
                  change={(e) => setFontSize(e.target.value)}
                />
              </div>
            </div>
          </div>
        </>
      ) : tableStatus === "xt" ? (
        <div className="flex flex-col">
          <XtControls path={RRIP_TB} dynEle={dynEle} operator="user" />
          <XtControls path={RRIP_TB} dynEle={dynEle} operator="manager" />
        </div>
      ) : tableStatus === "mxt" ? (
        <div className="flex flex-col">
          <MxtControls path={RRIP_TB} dynEle={dynEle} operator="user" />
          <MxtControls path={RRIP_TB} dynEle={dynEle} operator="manager" />
        </div>
      ) : tableStatus === "jsfun" ? (
        <div className="flex flex-col pb-[80px]">
          <JsFunControls path={RRIP_TB} dynEle={dynEle} />
        </div>
      ) : (
        <></>
      )}
      {/* </RetractableDiv> */}
    </section>
  );
};

export default TableProperties;
