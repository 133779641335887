import React, { useState } from "react";
import Input from "../../../utils/Input";
import Dropdown from "../../../utils/Dropdown";

const CreditRows = ({ index, index2, record, onChange }) => {
  return (
    <div className="flex flex-wrap gap-4">
      <Input
        className="w-full sm:w-[250px] h-[40px] text-lg"
        type="number"
        placeholder="Account Number"
        value={record.creditAccountNumber}
        change={(e) => {
          const inputValue = e.target.value;
          if (inputValue.length <= 15) {
            onChange(index, index2, "creditAccountNumber", inputValue);
          }
        }}
      />
      {/* <Input
        className="w-full sm:w-[150px] h-[40px] text-lg"
        type="text"
        value={record.credit}
        disabled
      /> */}
      <Input
        className="w-full sm:w-[250px] h-[40px] text-lg"
        type="text"
        placeholder="Beneficiary Name"
        value={record.beneficiaryName}
        change={(e) =>
          onChange(
            index,
            index2,
            "beneficiaryName",
            e.target.value.toUpperCase()
          )
        }
        blur={(e) =>
          onChange(index, index2, "beneficiaryName", e.target.value.trim())
        }
      />
      <Input
        className="w-full sm:w-[250px] h-[40px] text-lg"
        type="number"
        placeholder="Amount"
        value={record.amount}
        change={(e) => onChange(index, index2, "amount", e.target.value)}
      />
    </div>
  );
};

export default CreditRows;
