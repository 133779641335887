import React, { useEffect, useState } from "react";
import { useGetUserEntryFormsQuery } from "../../slices/entryFormApiSlice";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import Table from "../../utils/Table/Table";
import TableRow from "../../utils/Table/TableRow";
import TableCell from "../../utils/Table/TableCell";
import Loader from "../../components/Loader";

import { getForms } from "../../slices/formsSlice";
import { getListIDS } from "../../slices/internalDependencySlice";
import { useGetSingleAssignedDocMutation } from "../../slices/documentsApiSlice";
import { setDocument } from "../../slices/documentSlice";
import { useNavigate } from "react-router-dom";

const UserEntryForms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { document } = useSelector((state) => state.document);
  const { forms, userForms } = useSelector((state) => state.forms);
  const {
    data: userEntryForms,
    isLoading: userEntryFormsLoading,
    refetch,
  } = useGetUserEntryFormsQuery();

  const [getSingleAssignedDoc, { isLoading: mainFileLoading }] =
    useGetSingleAssignedDocMutation();

  const getMainFile = async (docName, category) => {
    try {
      const res = await getSingleAssignedDoc({ docName, category }).unwrap();
      dispatch(setDocument(res));
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (document && forms && userForms) {
      navigate(`/Forms/EF/${document.category}_EF`);
    }
  }, [document, forms, userForms]);

  return (
    <div className="psm">
      <h2 className="font-mono pb-2">Records</h2>
      {userEntryFormsLoading ? (
        <Loader />
      ) : (
        <Table>
          <TableRow isHeader={true}>
            <TableCell isHeader={true}>S.No</TableCell>
            <TableCell isHeader={true}>Name</TableCell>
            <TableCell isHeader={true}>Category</TableCell>
            <TableCell isHeader={true}>Status</TableCell>
            <TableCell isHeader={true}>Select</TableCell>
          </TableRow>
          {userEntryForms?.map((form, sno) => {
            return (
              <TableRow
                key={sno}
                className={(sno + 1) % 2 === 0 ? "bg-gray-100" : null}
              >
                <TableCell>{sno + 1}</TableCell>
                <TableCell>{form.docName}</TableCell>
                <TableCell>{form.category}</TableCell>
                <TableCell>{form.status}</TableCell>
                <TableCell
                  style={{
                    padding: "0px",
                    cursor: "pointer",
                    backgroundColor: "#083344",
                    color: "#f8f9fa",
                    textAlign: "center",
                  }}
                  click={() => {
                    dispatch(
                      getForms({
                        userForms: [...form.userForms, { name: "Submit" }],
                        forms: form.forms,
                      })
                    );
                    dispatch(getListIDS(form.listIDS));
                    getMainFile(form.docName, form.category);
                  }}
                >
                  Select
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      )}
    </div>
  );
};

export default UserEntryForms;
