export const getImageStyle = ({
  imageProps,
  paraProps,
  cellProps,
  rowProps,
  tableProps,
  docProps,
}) => {
  const style = {};
  style.width = `${imageProps?.transformation?.width}px`;
  style.height = `${imageProps?.transformation?.height}px`;

  style.display = "inline-block";
  return style;
};
