import React, { Fragment, useState } from "react";
import Input from "../../utils/Input";
import {
  useAddNumberingMutation,
  useAddLevelMutation,
  useUpdateLevelMutation,
  useDeleteLevelMutation,
  useGetNumberingQuery,
} from "../../slices/numberingApiSlice";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import Dropdown from "../../utils/Dropdown";
import { useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";

const AddNumbering = () => {
  const [createReference, setCreateReference] = useState("");
  const [levelReference, setLevelReference] = useState("");
  const [index, setIndex] = useState();

  const [id, setId] = useState();
  const [reference, setReference] = useState();
  const [level, setLevel] = useState();
  const [format, setFormat] = useState();
  const [text, setText] = useState();
  const [alignment, setAlignment] = useState();
  const [suffix, setSuffix] = useState();
  const [leftIndent, setLeftIndent] = useState(); // Example default value
  const [rightIndent, setRightIndent] = useState();
  const [firstLineIndent, setFirstLineIndent] = useState();
  const [hangingIndent, setHangingIndent] = useState();
  // const [styleParagraphIndentLeft]

  const { levelFormat, levelSuffix, tableAlignments } = useSelector(
    (state) => state.options
  );
  const { userInfo } = useSelector((state) => state.auth);

  const [addNumbering, {}] = useAddNumberingMutation();
  const [addLevel, {}] = useAddLevelMutation();
  const [updateLevel, {}] = useUpdateLevelMutation();
  const [deleteLevel, {}] = useDeleteLevelMutation();
  const { data: numberingTypes, refetch, isLoading } = useGetNumberingQuery();

  const addReferenceHandler = async () => {
    try {
      const res = await addNumbering({
        reference: createReference,
      }).unwrap();
      toast.success(res.message);
      setLevelReference();
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const addLevelHandler = async () => {
    try {
      const levelObj = {
        reference,
        level,
        format,
        text,
        alignment,
        suffix,
        style: {
          paragraph: {
            indent: {},
          },
        },
      };
      const indent = {};
      if (leftIndent) {
        indent["left"] = leftIndent;
      }
      if (rightIndent) {
        indent["right"] = rightIndent;
      }
      if (firstLineIndent) {
        indent["firstLine"] = firstLineIndent;
      }
      if (hangingIndent) {
        indent["hanging"] = hangingIndent;
      }
      levelObj.style.paragraph.indent = indent;

      const res = await addLevel({
        id,
        level: levelObj,
      }).unwrap();
      toast.success(res.message);
      setLevelReference();
      setId();
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const updateLevelHandler = async () => {
    if (index !== undefined) {
      try {
        const levelObj = {
          reference,
          level,
          format,
          text,
          alignment,
          suffix,
          style: {
            paragraph: {
              indent: {},
            },
          },
        };
        const indent = {};
        if (leftIndent) {
          indent["left"] = leftIndent;
        }
        if (rightIndent) {
          indent["right"] = rightIndent;
        }
        if (firstLineIndent) {
          indent["firstLine"] = firstLineIndent;
        }
        if (hangingIndent) {
          indent["hanging"] = hangingIndent;
        }
        levelObj.style.paragraph.indent = indent;

        const res = await updateLevel({
          id,
          index,
          level: levelObj,
        }).unwrap();
        toast.success(res.message);
        setLevelReference();
        setId();
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    } else {
      toast.error("You need to select a level before updating");
    }
  };

  const deleteLevelHandler = async (levelIndex) => {
    try {
      const res = await deleteLevel({
        id: levelReference._id,
        index: levelIndex,
      }).unwrap();
      toast.success(res.message);
      setLevelReference();
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="psm">
      <div className="flex gap-10">
        <div className="p-4 morphous bg-color">
          <h2 className="font-mono pb-2">Add Reference</h2>
          <div className="flex gap-4">
            <Input
              id="reference"
              value={createReference}
              placeholder="Enter Reference"
              type="text"
              change={(e) => setCreateReference(e.target.value)}
            />
            <button
              className="px-5 py-3 bg-cyan-950 text-light-500 font-mono"
              onClick={addReferenceHandler}
            >
              Add Reference
            </button>
          </div>
        </div>
        {!isLoading ? (
          <div className="p-4 morphous bg-color">
            <h2 className="font-mono pb-2">Select Reference</h2>
            <div className="cursor-pointer flex gap-4">
              <Dropdown
                options={numberingTypes}
                name="Reference"
                value={levelReference?.reference}
                target="option.reference"
                click={(value) => {
                  setId(value._id);
                  setLevelReference(value);
                  setReference(value.reference);
                  setLevel("");
                  setFormat();
                  setText();
                  setAlignment();
                }}
                style={
                  levelReference !== ""
                    ? { backgroundColor: "#035afc", color: "white" }
                    : null
                }
              />
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      {levelReference ? (
        <div className="psm">
          <div className="psm font-mono flex flex-col gap-8">
            <div className="py-4 flex flex-wrap gap-4">
              <div>
                <h2 className="pb-1">Reference</h2>
                <Input
                  type="text"
                  className="w-[200px] h-[40px]"
                  value={reference}
                  disabled
                />
              </div>
              <div>
                <h2 className="pb-1">Level 0 to 8</h2>
                <Dropdown
                  width={150}
                  height={40}
                  name="level"
                  options={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
                  value={level}
                  target="option"
                  click={(value) => {
                    setLevel(value);
                  }}
                />
              </div>
              <div>
                <h2 className="pb-1">Format</h2>
                <Dropdown
                  width={200}
                  height={40}
                  name="format"
                  options={levelFormat}
                  value={format}
                  target="option"
                  click={(value) => {
                    setFormat(value);
                  }}
                />
              </div>
              <div>
                <h2 className="pb-1">Text</h2>
                <Input
                  type="text"
                  className="w-[200px] h-[40px]"
                  value={text}
                  placeholder="Enter Text"
                  change={(e) => setText(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">Alignment</h2>
                <Dropdown
                  width={200}
                  height={40}
                  name="alignment"
                  options={tableAlignments}
                  value={alignment}
                  target="option"
                  click={(value) => {
                    setAlignment(value);
                  }}
                />
              </div>
              <div>
                <h2 className="pb-1">Suffix</h2>
                <Dropdown
                  width={200}
                  height={40}
                  name="suffix"
                  options={levelSuffix}
                  value={suffix}
                  target="option"
                  click={(value) => {
                    setSuffix(value);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-wrap items-end gap-4">
              <div>
                <h2 className="pb-1">Indent Left</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  type="text"
                  value={leftIndent}
                  change={(e) => setLeftIndent(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">Indent Right</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  type="text"
                  value={rightIndent}
                  change={(e) => setRightIndent(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">Indent FirstLine</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  type="text"
                  value={firstLineIndent}
                  change={(e) => setFirstLineIndent(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">Indent Hanging</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  type="text"
                  value={hangingIndent}
                  change={(e) => setHangingIndent(e.target.value)}
                />
              </div>
              <div>
                <button
                  className="px-2 h-[40px] bg-cyan-950 text-light-500"
                  onClick={addLevelHandler}
                >
                  Add Level
                </button>
              </div>
              <div>
                <button
                  className="px-2 h-[40px] bg-cyan-950 text-light-500"
                  onClick={updateLevelHandler}
                >
                  Update Level
                </button>
              </div>
            </div>
            {levelReference ? (
              <div className="psm flex flex-col gap-4">
                <h1>Levels</h1>
                <div className="flex flex-wrap gap-4">
                  {levelReference.levels.map((level, i) => {
                    return (
                      <div
                        className="bg-gray-100 rounded-lg p-4 shadow-md max-w-md cursor-pointer relative"
                        onClick={() => {
                          setIndex(i);
                          Object.keys(level).map((property) => {
                            switch (property) {
                              case "level":
                                setLevel(level[property]);
                                break;
                              case "format":
                                setFormat(level[property]);
                                break;
                              case "text":
                                setText(level[property]);
                                break;
                              case "alignment":
                                setAlignment(level[property]);
                                break;
                              case "style":
                                Object.keys(level[property]).map((element) => {
                                  switch (element) {
                                    case "paragraph":
                                      Object.keys(
                                        level[property][element].indent
                                      ).map((direction) => {
                                        switch (direction) {
                                          case "left":
                                            setLeftIndent(
                                              level[property][element].indent[
                                                direction
                                              ]
                                            );
                                            break;
                                          case "right":
                                            setRightIndent(
                                              level[property][element].indent[
                                                direction
                                              ]
                                            );
                                            break;
                                          case "firstLine":
                                            setFirstLineIndent(
                                              level[property][element].indent[
                                                direction
                                              ]
                                            );
                                            break;
                                          case "hanging":
                                            setHangingIndent(
                                              level[property][element].indent[
                                                direction
                                              ]
                                            );
                                            break;
                                        }
                                      });
                                      break;
                                  }
                                });
                                break;
                            }
                          });
                        }}
                      >
                        <div
                          className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
                          onClick={() => deleteLevelHandler(i)}
                        >
                          <IoCloseSharp />
                        </div>
                        <pre className="text-sm text-gray-700 whitespace-pre-wrap break-words">
                          {JSON.stringify(level, null, 2)}
                        </pre>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AddNumbering;
