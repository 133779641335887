import { apiSlice } from "./apiSlice";
import { IMAGE_URL } from "../constants";

export const imageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addImage: builder.mutation({
      query: (data) => ({
        url: `${IMAGE_URL}`,
        method: "POST",
        body: data,
      }),
    }),
    getImages: builder.query({
      query: () => ({
        url: IMAGE_URL,
        method: "GET",
      }),
    }),
    deleteImage: builder.mutation({
      query: (imageId) => ({
        url: `${IMAGE_URL}/${imageId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddImageMutation,
  useGetImagesQuery,
  useDeleteImageMutation,
} = imageApiSlice;
