import { getTableStyle } from "./Styles/tableStyle";
import DeleteElement from "../Elements/DeleteElement";
import { useDispatch, useSelector } from "react-redux";
import { setIndexPath } from "../../../../slices/docsSlice";

import { useNavigate } from "react-router-dom";
import CreateRow from "./Row";

const CreateTable = ({ table, index, loc, sec, doc, baseURL, SIPHF }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { RRIP_TB, NIP_TB } = useSelector((state) => state.docs);
  const tableStyle = getTableStyle({
    tableProps: table?.properties,
    sectionProps: sec?.properties,
    docProps: doc?.properties,
  });
  let splitIndex = index.split("_");
  return (
    <>
      <table
        cellPadding="0"
        cellSpacing="0"
        className="border border-black relative scroll-mt-[100px]"
        id={index}
        style={tableStyle}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          // dispatch(setIndexPath(`${loc}`));
          // navigate(`${baseURL}/${loc}/TBP`);
        }}
      >
        {SIPHF !== "0_H" || SIPHF !== "0_F" ? (
          <DeleteElement
            NIP={NIP_TB}
            RRIP={RRIP_TB}
            index={index}
            path={`${baseURL}`}
          />
        ) : null}
        {table?.ele?.map((row, i1) => {
          return (
            <CreateRow
              row={row}
              index={`${index}_${i1}`}
              loc={`${loc}/${i1}_TR`}
              table={table}
              sec={sec}
              doc={doc}
              baseURL={baseURL}
              SIPHF={SIPHF}
            />
          );
        })}
      </table>
      {/* {splitIndex.length === 3 ? (
        <p style={{ marginBottom: "20px" }}></p>
      ) : null} */}
    </>
  );
};

export default CreateTable;
