import React from "react";
import Input from "../../../utils/Input";

const DynamicRows = ({ index, record, onChange, type }) => {
  return (
    <div className="flex flex-wrap gap-4 pb-6">
      <Input
        className="w-full sm:w-[250px] h-[40px] text-lg"
        type="number"
        placeholder="Account Number"
        value={record.accountNumber}
        change={(e) => {
          const inputValue = e.target.value;
          if (inputValue.length <= 15) {
            onChange(index, "accountNumber", inputValue);
          }
        }}
      />
      <Input
        className="w-full sm:w-[250px] h-[40px] text-lg"
        type="number"
        placeholder="Amount"
        value={record.amount}
        change={(e) => onChange(index, "amount", e.target.value)}
        blur={(e) => {
          if (type === "RTGS" && parseFloat(e.target.value) < 200000) {
            window.alert(
              "RTGS transactions must be a minimum of 200,000 or higher."
            );
          }
        }}
      />
      <Input
        className="w-full sm:w-[250px] h-[40px] text-lg"
        type="text"
        placeholder="IFSC Code"
        value={record.ifscCode}
        change={(e) =>
          onChange(index, "ifscCode", e.target.value.toUpperCase())
        }
      />
      <Input
        className="w-full sm:w-[250px] h-[40px] text-lg"
        type="text"
        placeholder="Beneficiary Account Number"
        value={record.beneficiaryAccNumber}
        change={(e) =>
          onChange(index, "beneficiaryAccNumber", e.target.value.toUpperCase())
        }
        blur={(e) =>
          onChange(index, "beneficiaryAccNumber", e.target.value.trim())
        }
      />
      <Input
        className="w-full sm:w-[250px] h-[40px] text-lg"
        type="text"
        placeholder="Beneficiary Name"
        value={record.name}
        change={(e) => onChange(index, "name", e.target.value.toUpperCase())}
        blur={(e) => onChange(index, "name", e.target.value.trim())}
      />
      <Input
        className="w-full sm:w-[250px] h-[40px] text-lg"
        type="text"
        placeholder="Place"
        value={record.place}
        change={(e) => onChange(index, "place", e.target.value.toUpperCase())}
        blur={(e) => onChange(index, "place", e.target.value.trim())}
      />
      <Input
        className="w-full sm:w-[250px] h-[40px] text-lg"
        type="number"
        placeholder="Cell Number"
        value={record.phoneNo}
        change={(e) => {
          const inputValue = e.target.value;
          if (inputValue.length <= 10) {
            onChange(index, "phoneNo", inputValue);
          }
        }}
      />
    </div>
  );
};

export default DynamicRows;
