import React, { useState } from "react";
import Input from "../../../utils/Input";
import CreditRows from "./CreditRows";

const DebitRows = ({ index, record, onChange, onChange1, noOfCredits }) => {
  return (
    <div className="rounded bg-red-200 psm">
      <div className="flex flex-wrap gap-4 pb-2">
        <Input
          className="w-full sm:w-[250px] h-[40px] text-lg"
          type="number"
          placeholder="Account Number"
          value={record.debitAccountNumber}
          change={(e) => {
            const inputValue = e.target.value;
            if (inputValue.length <= 15) {
              onChange(index, "debitAccountNumber", inputValue);
            }
          }}
        />
        <Input
          className="w-full sm:w-[150px] h-[40px] text-lg"
          type="text"
          value={record.debit}
          disabled
        />
        <Input
          className="w-full sm:w-[250px] h-[40px] text-lg"
          type="text"
          placeholder="Sender Name"
          value={record.senderName}
          change={(e) =>
            onChange(index, "senderName", e.target.value.toUpperCase())
          }
          blur={(e) => onChange(index, "senderName", e.target.value.trim())}
        />
        <Input
          className="w-full sm:w-[250px] h-[40px] text-lg"
          type="number"
          placeholder="Amount"
          value={record.amount}
          change={(e) => onChange(index, "amount", e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-4 rounded bg-blue-200 psm">
        <h2>No. of Credit records</h2>
        <div>
          <Input
            className="w-[250px] h-[40px]"
            type="number"
            value={record.noOfCredits}
            change={(e) =>
              noOfCredits(index, "noOfCredits", parseInt(e.target.value))
            }
          />
        </div>
        <div className="flex flex-col gap-4">
          {Array.from({ length: record.noOfCredits }).map((_, index2) => (
            <CreditRows
              key={`${index}_${index2}`}
              index={index}
              index2={index2}
              record={record.credits[index2]}
              onChange={onChange1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DebitRows;
