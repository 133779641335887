import { convertToPx } from "../../UnitConversion";
export const getSectionStyle = ({ sectionProps, docProps }) => {
  const style = {};

  const borders = sectionProps?.page?.borders;
  const borderLeftSpace = borders?.pageBorderLeft?.space || 0;
  const borderRightSpace = borders?.pageBorderRight?.space || 0;
  const borderTopSpace = borders?.pageBorderTop?.space || 0;
  const borderBottomSpace = borders?.pageBorderBottom?.space || 0;

  style.paddingLeft =
    convertToPx(sectionProps?.page?.margin.left) - borderLeftSpace * 1.3333;
  style.paddingRight =
    convertToPx(sectionProps?.page?.margin.right) - borderRightSpace * 1.3333;
  style.paddingTop =
    convertToPx(sectionProps?.page?.margin.top) - borderTopSpace * 1.3333;
  style.paddingBottom =
    convertToPx(sectionProps?.page?.margin.bottom) - borderBottomSpace * 1.3333;
  return style;
};
