import { apiSlice } from "../../../slices/apiSlice";
import { LEVEL_URL } from "../../../constants";

export const agencyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addLevel: builder.mutation({
      query: (data) => ({
        url: `${LEVEL_URL}`,
        method: "POST",
        body: data,
      }),
    }),
    getLevels: builder.query({
      query: () => ({
        url: LEVEL_URL,
        method: "GET",
      }),
    }),
    deleteLevel: builder.mutation({
      query: (levelId) => ({
        url: `${LEVEL_URL}/${levelId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddLevelMutation,
  useGetLevelsQuery,
  useDeleteLevelMutation,
} = agencyApiSlice;
