import React, { useState } from "react";
import { parse } from "parse5";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { setCibil } from "../features/testSlice";

const CIBIL = () => {
  // const CES = useSelector((state) => state.test.CES);
  const dispatch = useDispatch();
  const history = useNavigate();

  let [customerFile, setCustomerFile] = useState();

  let [cibilTables, setCibilTables] = useState([]);
  let [cibilTable, setCibilTable] = useState([]);

  let [accountTables, setAccountTables] = useState([]);
  let [accountTable, setAccountTable] = useState([]);

  let [customerDetails, setCustomerDetails] = useState([]);
  let [customerDetail, setCustomerDetail] = useState([]);

  let [pans, setPans] = useState([]);
  let [pan, setPan] = useState("");

  let dummyCibilData = [
    [
      [
        [
          "BOMMA VIJAYA",
          "006146857060",
          "17-07-2023",
          "5",
          "0",
          "5",
          "6.24",
          "0.00",
          "741",
          [],
        ],
        ["0.00", "812", []],
      ],
      [
        [
          "BOMMA VIJAYA",
          "006146857060",
          "17-07-2023",
          "5",
          "0",
          "5",
          "6.24",
          "0.00",
          "741",
          [],
        ],
        ["0.00", "812", []],
      ],
    ],
    [],
    [
      [
        "BOMMA VIJAYA",
        "17-07-2023",
        "006146857060",
        "05-10-1976",
        "H.NO.34-121/1,SHIVAJI NAGAR, RAMAKRISHNAPUR, MANDAMARRI, MANDAMARRI, TELANGANA, TELANGANA, 504301",
        "9618846901",
        "",
        "0.00",
      ],
      [
        "BOMMA VIJAYA",
        "17-07-2023",
        "006146857060",
        "05-10-1976",
        "H.NO.34-121/1,SHIVAJI NAGAR, RAMAKRISHNAPUR, MANDAMARRI, MANDAMARRI, TELANGANA, TELANGANA, 504301",
        "9618846901",
        "",
        "0.00",
      ],
    ],
    ["CCDPB5197A", "CCDPB5197A"],
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    let newCibilTables = [];
    cibilTables.map((row) => {
      let newRow = [];
      let name = row[0];
      let date = row[1];
      let cNo = row[2];
      // const cNoLength = cNo.length;
      // cNo = cNo.slice(0, cNoLength / 2) + ' ' + cNo.slice(cNoLength / 2);
      let creditScore = row[3];
      let creditRemarks = row[4];
      let personalScore = row[5];
      let personalRemarks = row[6];
      let totalNoOfAcc = row[7];
      let highCR = row[8];
      let currentBal = row[9];
      let overDueAcc = row[10];
      let overDueAmt = row[11];
      let zeroBalAcc = row[12];

      newRow = [
        [
          name,
          cNo,
          date,
          totalNoOfAcc,
          overDueAcc,
          zeroBalAcc,
          highCR,
          currentBal,
          creditScore,
          creditRemarks,
        ],
        [overDueAmt, personalScore, personalRemarks],
      ];

      newCibilTables.push(newRow);
    });

    dispatch(setCibil([newCibilTables, accountTables, customerDetails, pans]));
    history("/Test/CurrItem");
  };

  const addData = (e) => {
    e.preventDefault();
    if (cibilTable.length > 0) {
      let newCibilTables = [...cibilTables];
      newCibilTables.push([...cibilTable]);
      setCibilTables(newCibilTables);
    }

    if (accountTable.length > 0) {
      let newAccountTables = [...accountTables];
      newAccountTables.push([...accountTable]);
      setAccountTables(newAccountTables);
    }

    if (customerDetail.length > 0) {
      let newCustomerDetails = [...customerDetails];
      newCustomerDetails.push([...customerDetail]);
      setCustomerDetails(newCustomerDetails);
    }

    if (pan !== "") {
      let newPans = [...pans];
      newPans.push(pan);
      setPans(newPans);
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    setCustomerFile(e.target.files);
    let CN = document.querySelector(".Cibil-name");
    CN.innerHTML = `Cibil Name: ${e.target.value.split("\\").pop()}`;
  };

  let updatePAN = (panNo) => {
    pan = panNo;
  };

  // second Table

  let updateAccount = (accountData) => {
    accountTable.push(accountData);
  };

  // third Table

  let updateDetails = (value, type) => {
    if (type === "phNo") {
      if (value.length > 10 && value.length === 12) {
        value = value.slice(2, value.length);
      }
      customerDetail[5] = value.trim();
    } else if (type === "address") {
      customerDetail[4] = value.trim();
    } else if (type === "curr bal") {
      customerDetail.push(value);
    } else {
      customerDetail.push(value.trim());
    }
  };

  // first table

  let updateCibil = (value) => {
    if (typeof value === "string") {
      cibilTable.push(value.trim());
    } else {
      cibilTable.push(value);
    }
  };

  if (cibilTable.length > 0) {
    cibilTable.splice(0, cibilTable.length);
  }

  if (accountTable.length > 0) {
    accountTable.splice(0, accountTable.length);
  }

  if (customerDetail.length > 0) {
    customerDetail.splice(0, customerDetail.length);
  }

  if (pan !== "") {
    pan = "";
  }

  if (customerFile !== undefined) {
    let fileReader = new FileReader();

    fileReader.readAsText(customerFile[0]);
    fileReader.onload = (e) => {
      let data = e.target.result;
      let doc = parse(data);
      doc.childNodes[1].childNodes[1].childNodes[0].childNodes[0].childNodes.map(
        (ele, index) => {
          if (index === 1) {
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4, index1) => {
                    if (index1 === 1) {
                      el4.childNodes.map((el5, index2) => {
                        if (index2 === 0) {
                          el5.childNodes.map((el6) => {
                            el6.childNodes.map((el7) => {
                              el7.childNodes.map((el8, index3) => {
                                if (index3 === 0) {
                                  el8.childNodes.map((el9, index4) => {
                                    if (index4 === 1) {
                                      updateCibil(el9.childNodes[0].value);
                                      // updateCibil('');
                                      updateDetails(el9.childNodes[0].value);
                                    }
                                  });
                                }
                              });
                            });
                          });
                        } else if (index2 === 2) {
                          el5.childNodes.map((el6) => {
                            el6.childNodes.map((el7) => {
                              el7.childNodes.map((el8, index5) => {
                                if (index5 === 0) {
                                  el8.childNodes.map((el9, index6) => {
                                    if (index6 === 1) {
                                      updateCibil(el9.childNodes[0].value);
                                      updateDetails(el9.childNodes[0].value);
                                    }
                                  });
                                } else if (index5 === 2) {
                                  el8.childNodes.map((el9, index6) => {
                                    if (index6 === 1) {
                                      updateCibil(el9.childNodes[0].value);
                                      updateDetails(el9.childNodes[0].value);
                                    }
                                  });
                                }
                              });
                            });
                          });
                        }
                      });
                    }
                  });
                });
              });
            });
          } else if (index === 4) {
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4, index1) => {
                    if (index1 === 1) {
                      el4.childNodes.map((el5, index2) => {
                        if (index2 === 0) {
                          el5.childNodes.map((el6) => {
                            el6.childNodes.map((el7) => {
                              el7.childNodes.map((el8, index3) => {
                                if (index3 === 1) {
                                  el8.childNodes.map((el9, index4) => {
                                    if (index4 === 1) {
                                      updateDetails(el9.childNodes[0].value);
                                    }
                                  });
                                }
                              });
                            });
                          });
                        }
                      });
                    }
                  });
                });
              });
            });
          } else if (index === 8) {
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4) => {
                    let CreditScore;
                    let CreditRemarks = [];
                    el4.childNodes.map((el5, index1) => {
                      if (index1 === 1) {
                        CreditScore = el5.childNodes[0].value;
                      }
                      if (index1 === 2) {
                        el5.childNodes[0].childNodes.map((remark) => {
                          CreditRemarks.push(remark.childNodes[0].value);
                        });
                      }
                    });
                    // updateCibil([CreditScore, CreditRemarks]);
                    updateCibil(CreditScore);
                    updateCibil(CreditRemarks);
                  });
                });
              });
            });
          } else if (index === 10) {
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4) => {
                    let PersonalScore;
                    let PersonalRemarks = [];
                    el4.childNodes.map((el5, index1) => {
                      if (index1 === 1) {
                        PersonalScore = el5.childNodes[0].value;
                      }
                      if (index1 === 2) {
                        el5.childNodes[0].childNodes.map((remark) => {
                          PersonalRemarks.push(remark.childNodes[0].value);
                        });
                      }
                    });
                    // updateCibil([PersonalScore, PersonalRemarks]);
                    updateCibil(PersonalScore);
                    updateCibil(PersonalRemarks);
                  });
                });
              });
            });
          } else if (index === 19) {
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4, index1) => {
                    if (index1 === 2) {
                      if (
                        el4.childNodes[0].childNodes[0].value.trim() ===
                        "INCOME TAX ID NUMBER (PAN)"
                      ) {
                        updatePAN(el4.childNodes[1].childNodes[0].value);
                      } else {
                        updatePAN("-");
                      }
                    }
                  });
                });
              });
            });
          } else if (index === 22) {
            // phone no
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  let phNo = "";
                  el3.childNodes.map((el4, index1) => {
                    if (index1 > 1) {
                      let numType = el4.childNodes[0].childNodes[0].value
                        .replace("\n", "")
                        .trim();
                      if (numType === "MOBILE PHONE" && phNo === "") {
                        phNo = el4.childNodes[1].childNodes[0].value;
                      }
                    }
                  });
                  updateDetails(phNo, "phNo");
                });
              });
            });
          } else if (index === 25) {
            // console.log(ele); // email Id
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  let email = "";
                  el3.childNodes.map((el4, index1) => {
                    if (index1 > 1) {
                      if (email === "") {
                        email =
                          el4.childNodes[0].childNodes[0].value.toLowerCase();
                      }
                    }
                  });
                  updateDetails(email);
                });
              });
            });
          } else if (index === 28) {
            // Address
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4, index1) => {
                    if (index1 === 1) {
                      el4.childNodes.map((el5) => {
                        el5.childNodes.map((el6, index2) => {
                          if (index2 === 1) {
                            el6.childNodes.map((el7) => {
                              let address = el7.value
                                .replace(/\s+/g, " ")
                                .trim();
                              if (address === "(e)") {
                                address =
                                  el3.childNodes[1].childNodes[0].childNodes[3].childNodes[0].value
                                    .replace(/\s+/g, " ")
                                    .trim();
                              }
                              address = address.replace(/\s,/g, ", ");
                              if (
                                address.substring(0, 4) === "SAME" &&
                                el3.childNodes[3] !== undefined
                              ) {
                                address =
                                  el3.childNodes[3].childNodes[0].childNodes[1].childNodes[0].value
                                    .replace(/\s+/g, " ")
                                    .trim();
                                if (address === "(e)") {
                                  address =
                                    el3.childNodes[3].childNodes[0].childNodes[3].childNodes[0].value
                                      .replace(/\s+/g, " ")
                                      .trim();
                                }
                                address = address.replace(/\s,/g, ", ");
                                updateDetails(address, "address");
                              } else {
                                updateDetails(address, "address");
                              }
                            });
                          }
                        });
                      });
                    }
                  });
                });
              });
            });
          } else if (index === 34) {
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4, index1) => {
                    if (index1 === 3) {
                      el4.childNodes.map((el5, index2) => {
                        // console.log(el5);
                        if (index2 === 1) {
                          updateCibil(el5.childNodes[1].value);
                        } else if (index2 === 2) {
                          if (el5.childNodes[1] !== undefined) {
                            let HighCR = (
                              parseFloat(
                                el5.childNodes[1].value.replace(/,/g, "")
                              ) / 100000
                            ).toFixed(2);
                            updateCibil(HighCR);
                          } else {
                            updateCibil("0.00");
                          }
                        } else if (index2 === 3) {
                          if (el5.childNodes[1] !== undefined) {
                            let CurrentBal = (
                              parseFloat(
                                el5.childNodes[1].value.replace(/,/g, "")
                              ) / 100000
                            ).toFixed(2);
                            updateCibil(CurrentBal);
                            updateDetails(CurrentBal, "curr bal");
                          } else {
                            updateCibil("0.00");
                            updateDetails("0.00", "curr bal");
                          }
                        }
                      });
                    } else if (index1 === 4) {
                      el4.childNodes.map((el5, index2) => {
                        if (index2 === 1) {
                          updateCibil(el5.childNodes[1].value);
                        } else if (index2 === 3) {
                          if (el5.childNodes[1] !== undefined) {
                            let OverDueAmt = (
                              parseFloat(
                                el5.childNodes[1].value.replace(/,/g, "")
                              ) / 100000
                            ).toFixed(2);
                            updateCibil(OverDueAmt);
                          } else {
                            updateCibil("0.00");
                          }
                        }
                      });
                    } else if (index1 === 5) {
                      el4.childNodes.map((el5, index2) => {
                        if (index2 === 1) {
                          updateCibil(el5.childNodes[1].value);
                        }
                      });
                    }
                  });
                });
              });
            });
          } else if (index === 37) {
            //console.log(ele);
            ele.childNodes.map((el1) => {
              el1.childNodes.map((el2) => {
                el2.childNodes.map((el3) => {
                  el3.childNodes.map((el4, index1) => {
                    // console.log(index1);
                    // if (index1 === 10) {
                    //   console.log(el4);
                    // }
                    if (
                      index1 === 3 ||
                      index1 === 10 ||
                      index1 === 17 ||
                      index1 === 24 ||
                      index1 === 31 ||
                      index1 === 38 ||
                      index1 === 45 ||
                      index1 === 52 ||
                      index1 === 59 ||
                      index1 === 66 ||
                      index1 === 73 ||
                      index1 === 80 ||
                      index1 === 87 ||
                      index1 === 94 ||
                      index1 === 101 ||
                      index1 === 108 ||
                      index1 === 115 ||
                      index1 === 122 ||
                      index1 === 129 ||
                      index1 === 136 ||
                      index1 === 143 ||
                      index1 === 150 ||
                      index1 === 157 ||
                      index1 === 164 ||
                      index1 === 171 ||
                      index1 === 178 ||
                      index1 === 185 ||
                      index1 === 192 ||
                      index1 === 199 ||
                      index1 === 206
                    ) {
                      el4.childNodes.map((el5, index2) => {
                        if (index2 === 2) {
                          el5.childNodes.map((el6) => {
                            if (el6.childNodes !== undefined) {
                              el6.childNodes.map((el7) => {
                                el7.childNodes.map((el8, index3) => {
                                  if (
                                    el8.childNodes[0].childNodes[0] !==
                                    undefined
                                  ) {
                                    if (
                                      el8.childNodes[0].childNodes[0]
                                        .childNodes[0].value ===
                                      "CURRENT BALANCE:"
                                    ) {
                                      if (
                                        el8.childNodes[0].childNodes[1]
                                          .value !== "0"
                                      ) {
                                        let sanctionAmt;
                                        let overdue = "0";
                                        if (
                                          el4.childNodes[0].childNodes[0]
                                            .childNodes[0].childNodes[2]
                                            .childNodes[0].childNodes[1]
                                            .value === "CREDIT CARD"
                                        ) {
                                          if (
                                            el4.childNodes[2].childNodes[0]
                                              .childNodes[0].childNodes[0]
                                              .childNodes[0].childNodes[0]
                                              .childNodes[0].value ===
                                            "HIGH-CREDIT:"
                                          ) {
                                            sanctionAmt =
                                              el4.childNodes[2].childNodes[0]
                                                .childNodes[0].childNodes[0]
                                                .childNodes[0].childNodes[1]
                                                .value;
                                          } else {
                                            sanctionAmt = "Dummy";
                                          }
                                        } else {
                                          sanctionAmt =
                                            el4.childNodes[2].childNodes[0]
                                              .childNodes[0].childNodes[0]
                                              .childNodes[0].childNodes[1]
                                              .value;
                                        }

                                        // console.log(
                                        //   el4.childNodes[2].childNodes[0]
                                        //     .childNodes[0]
                                        // );
                                        // OverDue
                                        if (
                                          el4.childNodes[2].childNodes[0]
                                            .childNodes[0].childNodes[2]
                                        ) {
                                          if (
                                            el4.childNodes[2].childNodes[0]
                                              .childNodes[0].childNodes[2]
                                              .childNodes[0].childNodes[0] !==
                                            undefined
                                          ) {
                                            if (
                                              el4.childNodes[2].childNodes[0]
                                                .childNodes[0].childNodes[2]
                                                .childNodes[0].childNodes[0]
                                                .childNodes[0].value ===
                                              "OVERDUE:"
                                            ) {
                                              overdue =
                                                el4.childNodes[2].childNodes[0]
                                                  .childNodes[0].childNodes[2]
                                                  .childNodes[0].childNodes[1]
                                                  .value;
                                            }
                                          }
                                        }

                                        // console.log(ele);
                                        updateAccount([
                                          [
                                            el4.childNodes[0].childNodes[0]
                                              .childNodes[0].childNodes[2]
                                              .childNodes[0].childNodes[1]
                                              .value,
                                            el4.childNodes[0].childNodes[0]
                                              .childNodes[0].childNodes[1]
                                              .childNodes[0].childNodes[1]
                                              .value,
                                          ],
                                          el4.childNodes[0].childNodes[0]
                                            .childNodes[0].childNodes[3]
                                            .childNodes[0].childNodes[1].value,
                                          el4.childNodes[1].childNodes[0]
                                            .childNodes[0].childNodes[0]
                                            .childNodes[0].childNodes[1].value,
                                          // sanctionAmt,
                                          (
                                            parseFloat(
                                              sanctionAmt.replace(/,/g, "")
                                            ) / 100000
                                          ).toFixed(2),
                                          (
                                            parseFloat(
                                              el8.childNodes[0].childNodes[1].value.replace(
                                                /,/g,
                                                ""
                                              )
                                            ) / 100000
                                          ).toFixed(2),
                                          // el8.childNodes[0].childNodes[1].value,
                                          // overdue,
                                          (
                                            parseFloat(
                                              overdue.replace(/,/g, "")
                                            ) / 100000
                                          ).toFixed(2),
                                        ]);
                                      }
                                    }
                                  }
                                });
                                // console.log('Break');
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                });
              });
            });
          }
          // console.log(ele);
        }
      );
    };
  }

  return (
    <div className="psm">
      {/* {CES["PR"].doc["Partners Table"].T.map((R, i) => {
        if (i > 0) {
          return <h2 className="t2">{R[2].name}</h2>;
        }
      })} */}
      <div className="Cibil-box">
        <div className="Cibil-input-box bg2">
          <h1 className="t2 Cibil-name">Cibil Name: </h1>
          <input
            type="file"
            id="customerFile"
            files={customerFile}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="d-flex justify-content-between">
          <button className="Cibil-Add bg6 txt7 sdw" onClick={addData}>
            Add Data
          </button>
          <button className="Cibil-Dummy bg9 txt1 sdw" onClick={() => {}}>
            Test Data
          </button>
          <input
            type="submit"
            value="Submit"
            className="Cibil-Add bg5 txt6 sdw"
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default CIBIL;
