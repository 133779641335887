import React, { useEffect } from "react";
import Layout from "./Layout";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AdminRoute from "./pages/admin/AdminRoute";
import ManagerRoute from "./pages/manager/ManagerRoute";

import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Profile from "./pages/Profile";
import Forgot from "./pages/Forgot";
import PrivateRoute from "./pages/PrivateRoute";

import AdminDashBoard from "./pages/admin/Dashboard";
import AdminManagers from "./pages/admin/Managers";
import AdminNumbering from "./pages/admin/AddNumbering";
import AdminExternalDependency from "./pages/admin/AddExternalDependency";

import ManagerDashBoard from "./pages/manager/Dashboard";
import ManagerUsers from "./pages/manager/Users";
import ManagerCreateDocs from "./pages/manager/CreateDocuments";
import ManagerAddDependency from "./pages/manager/AddDependency";
import ManagerAssignDocuments from "./pages/manager/AssignDocuments";

import ManagerDocumentLayout from "./pages/manager/Document/DocumentLayout";
import ManagerDocument from "./pages/manager/Document/Document";
import DocumentControls from "./pages/manager/Document/Controls/DocumentControls";
import ImageControls from "./pages/manager/Document/Controls/ImageControls";
import DocumentProperties from "./pages/manager/Document/Controls/DocumentProperties";

import SectionProperties from "./pages/manager/Document/Controls/Section/SectionProperties";

import ParagraphProperties from "./pages/manager/Document/Controls/Paragraph/ParagraphProperties";
import SavedImages from "./pages/manager/Document/Controls/Paragraph/Image/SavedImages";
import ImageProperties from "./pages/manager/Document/Controls/Paragraph/Image/ImageProperties";
import TextProperties from "./pages/manager/Document/Controls/Paragraph/Text/TextProperties";
import SavedColors from "./pages/manager/Document/Controls/Paragraph/Text/SavedColors";

import TableProperties from "./pages/manager/Document/Controls/Table/TableProperties";
import RowProperties from "./pages/manager/Document/Controls/Table/TableRow/RowProperties";
import CellProperties from "./pages/manager/Document/Controls/Table/TableRow/TableCell/CellProperties";

import Forms from "./pages/user/Forms";
import UserDashBoard from "./pages/user/Dashboard";
import UserEntryForms from "./pages/user/UserEntryForms";
import UserSubmit from "./pages/user/Submit";

import EntryForm from "./pages/shared/EntryForm";
import InternalDep from "./pages/shared/InternalDep";

import Cibil from "./externalDependency/LanceTechServices/Cibil";
import PPB from "./externalDependency/LanceTechServices/PPB";
import CMA from "./externalDependency/LanceTechServices/UploadCMA";
import Level from "./externalDependency/LanceTechServices/Level";
import FirmNameDate from "./externalDependency/LanceTechServices/FirmNameDate";

import NEFT from "./externalDependency/NEFTRTGS/NEFT";
import RTGS from "./externalDependency/NEFTRTGS/RTGS";
import UBIFUNDTRANSFER from "./externalDependency/NEFTRTGS/UBI_Fund_Transfer";

// import Test from "./pages/manager/Test";
import { useSelector } from "react-redux";

const Router = () => {
  const { indexPath } = useSelector((state) => state.docs);

  return (
    <BrowserRouter>
      <Routes>
        <Route index={true} path="/" element={<SignIn />} />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/Forgot" element={<Forgot />} />

        <Route path="/" element={<Layout />}>
          {/* Protected Routes */}
          <Route path="" element={<PrivateRoute />}>
            <Route path="/Profile" exact element={<Profile />} />
            <Route path="/Dashboard" exact element={<UserDashBoard />} />
            <Route path="/InternalDep" exact element={<InternalDep />} />
            <Route path="/Records" exact element={<UserEntryForms />} />
            <Route path="/Forms" exact element={<Forms />}>
              <Route path="EF/:entryForm" exact element={<EntryForm />} />
              {/* Lance Tech Services */}
              <Route path="Cibil" exact element={<Cibil />} />
              <Route path="PPB" exact element={<PPB />} />
              <Route path="CMA" exact element={<CMA />} />
              <Route path="FirmND" exact element={<FirmNameDate />} />

              {/* NEFTRTGS */}
              <Route path="NEFT" exact element={<NEFT />} />
              <Route path="RTGS" exact element={<RTGS />} />
              <Route
                path="UBI_Fund_Transfer"
                exact
                element={<UBIFUNDTRANSFER />}
              />

              <Route path="Submit" exact element={<UserSubmit />} />
            </Route>
          </Route>

          {/* Admin Routes */}
          <Route path="/admin" exact element={<AdminRoute />}>
            <Route path="AdminDashBoard" exact element={<AdminDashBoard />} />
            <Route
              path="managers/:pageNumber"
              exact
              element={<AdminManagers />}
            />
            <Route path="Numbering" element={<AdminNumbering />} />
            <Route path="ED" exact element={<AdminExternalDependency />} />
          </Route>

          {/* Manager Routes */}
          <Route path="/manager" exact element={<ManagerRoute />}>
            <Route
              path="ManagerDashBoard"
              exact
              element={<ManagerDashBoard />}
            />
            <Route path="users/:pageNumber" exact element={<ManagerUsers />} />
            <Route path="CD" exact element={<ManagerCreateDocs />} />
            <Route path="AD" exact element={<ManagerAddDependency />} />
            <Route path="Assign" exact element={<ManagerAssignDocuments />} />
            <Route path="InternalDep" exact element={<InternalDep />} />
          </Route>
        </Route>

        <Route path="/" element={<ManagerDocumentLayout />}>
          <Route path="/manager" element={<ManagerRoute />}>
            <Route path=":document" exact element={<ManagerDocument />}>
              <Route path="DC" exact element={<DocumentControls />} />
              <Route path="IC" exact element={<ImageControls />} />
              <Route path={`:${indexPath}`} element={<DocumentControls />} />
              <Route
                path={`:${indexPath}/DP`}
                exact
                element={<DocumentProperties />}
              />
              {/* <Route
                path={`:${indexPath}/SP`}
                exact
                element={<SectionProperties />}
              />
              <Route
                path={`:${indexPath}/PP`}
                exact
                element={<ParagraphProperties />}
              />
              <Route
                path={`:${indexPath}/IP`}
                exact
                element={<ImageProperties />}
              />
              <Route
                path={`:${indexPath}/TP`}
                exact
                element={<TextProperties />}
              />
              <Route
                path={`:${indexPath}/TBP`}
                exact
                element={<TableProperties />}
              />
              <Route
                path={`:${indexPath}/TR`}
                exact
                element={<RowProperties />}
              />
              <Route
                path={`:${indexPath}/TC`}
                exact
                element={<CellProperties />}
              /> */}
              <Route path={`:${indexPath}/SP`} element={<SectionProperties />}>
                <Route path={`PP`} element={<ParagraphProperties />}>
                  <Route path="TP" element={<TextProperties />}>
                    <Route path="SC/:type" element={<SavedColors />} />
                  </Route>
                  <Route path="IP/:id" exact element={<ImageProperties />} />
                  <Route path="SI" exact element={<SavedImages />} />
                  <Route path="TP/:id" element={<TextProperties />} />
                  <Route path="SC/:type" exact element={<SavedColors />} />
                </Route>
              </Route>
              <Route path={`:${indexPath}/SP`} element={<SectionProperties />}>
                <Route path={`TBP`} element={<TableProperties />}>
                  <Route path={`TR`} exact element={<RowProperties />}>
                    <Route path={`TC`} exact element={<CellProperties />}>
                      <Route
                        path={`PP`}
                        exact
                        element={<ParagraphProperties />}
                      >
                        <Route path="TP" exact element={<TextProperties />}>
                          <Route
                            path="SC/:type"
                            exact
                            element={<SavedColors />}
                          />
                        </Route>
                        <Route
                          path="IP/:id"
                          exact
                          element={<ImageProperties />}
                        />
                        <Route path="SI" exact element={<SavedImages />} />
                        <Route path="TP/:id" element={<TextProperties />} />
                        <Route
                          path="SC/:type"
                          exact
                          element={<SavedColors />}
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route
                path={`:${indexPath}/SP/HFP`}
                element={<SectionProperties />}
              >
                <Route path={`PP`} element={<ParagraphProperties />}>
                  <Route path="TP" element={<TextProperties />}>
                    <Route path="SC/:type" element={<SavedColors />} />
                  </Route>
                  <Route path="IP/:id" exact element={<ImageProperties />} />
                  <Route path="SI" exact element={<SavedImages />} />
                  <Route path="TP/:id" element={<TextProperties />} />
                  <Route path="SC/:type" exact element={<SavedColors />} />
                </Route>
              </Route>
              <Route
                path={`:${indexPath}/SP/HFP`}
                element={<SectionProperties />}
              >
                <Route path={`TBP`} element={<TableProperties />}>
                  <Route path={`TR`} exact element={<RowProperties />}>
                    <Route path={`TC`} exact element={<CellProperties />}>
                      <Route
                        path={`PP`}
                        exact
                        element={<ParagraphProperties />}
                      >
                        <Route path="TP" exact element={<TextProperties />}>
                          <Route
                            path="SC/:type"
                            exact
                            element={<SavedColors />}
                          />
                        </Route>
                        <Route
                          path="IP/:id"
                          exact
                          element={<ImageProperties />}
                        />
                        <Route path="SI" exact element={<SavedImages />} />
                        <Route path="TP/:id" element={<TextProperties />} />
                        <Route
                          path="SC/:type"
                          exact
                          element={<SavedColors />}
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="EF/:entryForm" exact element={<EntryForm />} />
            <Route path="Cibil" exact element={<Cibil />} />
            <Route path="PPB" exact element={<PPB />} />
            <Route path="CMA" exact element={<CMA />} />
            <Route path="FirmND" exact element={<FirmNameDate />} />
            <Route path="Level" exact element={<Level />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
