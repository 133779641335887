import { getImageStyle } from "./Styles/imageStyle";
import DeleteElement from "../Elements/DeleteElement";
import { useDispatch, useSelector } from "react-redux";
import { setIndexPath } from "../../../../slices/docsSlice";
import { useNavigate } from "react-router-dom";

const CreateImage = ({
  image,
  index,
  loc,
  para,
  table,
  row,
  cell,
  sec,
  doc,
  baseURL,
  SIPHF,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { RRIP_I, NIP_I } = useSelector((state) => state.docs);

  let splitLoc = loc.split("/");
  let splitIndex = index.split("_");
  let lastIndex = splitIndex[splitIndex.length - 1];

  const imageStyle = getImageStyle({
    imageProps: image?.properties,
    paraProps: para?.properties,
    cellProps: cell?.properties,
    rowProps: row?.properties,
    tableProps: table?.properties,
    docProps: doc?.properties,
  });
  return (
    <div className="relative inline-block" id={`${index}`}>
      {NIP_I && (SIPHF !== "0_H" || SIPHF !== "0_F") ? (
        <DeleteElement
          NIP={NIP_I}
          RRIP={RRIP_I}
          index={`${index}`}
          path={`${baseURL}`}
        />
      ) : null}

      <img
        // id={`${index}`}
        className="cursor-pointer"
        src={image?.image}
        style={imageStyle}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(setIndexPath(`${loc}`));
          if (SIPHF === "0_H" || SIPHF === "0_F") {
            navigate(
              `${baseURL}/${loc}${
                splitLoc.length > 3
                  ? `/SP/HFP/TBP/TR/TC/PP/IP/${lastIndex}`
                  : `/SP/HFP/PP/IP/${lastIndex}`
              }`
              // `${baseURL}/${loc}/${i3}_I/IP/${i3}`
            );
          } else {
            navigate(
              `${baseURL}/${loc}${
                splitLoc.length > 3
                  ? `/SP/TBP/TR/TC/PP/IP/${lastIndex}`
                  : `/SP/PP/IP/${lastIndex}`
              }`
              // `${baseURL}/${loc}/${i3}_I/IP/${i3}`
            );
          }
        }}
      />
    </div>
  );
};

export default CreateImage;
