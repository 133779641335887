import React, { useCallback, useEffect, useState } from "react";
// import "../styles/uploadCma.scss";
import { useDropzone } from "react-dropzone";
import { read } from "xlsx";
import { useDispatch, useSelector } from "react-redux";
// import {
//   updateLT,
//   updatePMT,
//   updateDepTotals,
//   updatePLT,
//   updatePLTTotals,
// } from "../features/testSlice";

const UploadCMA = () => {
  // const LETTER = useSelector((state) => state.test.LETTER);
  const [WS, setWS] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(WS).length > 0) {
      let cmaTable = [];
      let RF = false;
      let Continue = true;
      let row = [];
      let charLength = LETTER.charCodeAt(0);

      Object.keys(WS).map((cell) => {
        if (
          cell.charAt(0).charCodeAt() >= 65 &&
          cell.charAt(0).charCodeAt() <= charLength
        ) {
          if (RF === true && cell.charAt(0).charCodeAt() === 65) {
            RF = false;
            Continue = false;
          }
          if (Continue) {
            if (cell.charAt(0).charCodeAt() === 65) {
              RF = true;
            }
            if (RF) {
              if (
                cell.charAt(0) === "C" ||
                cell.charAt(0) === "D" ||
                cell.charAt(0) === "E" ||
                cell.charAt(0) === "F" ||
                cell.charAt(0) === "G" ||
                cell.charAt(0) === "H" ||
                cell.charAt(0) === "I" ||
                cell.charAt(0) === "J" ||
                cell.charAt(0) === "K" ||
                cell.charAt(0) === "L"
              ) {
                row[cell.charAt(0).charCodeAt() - 67] = WS[cell].v;
              }
            }
          }
          if (RF === false) {
            cmaTable.push(row);
            if (cell.charAt(0).charCodeAt() === 65) {
              row = [];
            }
            if (
              cell.charAt(0) === "C" ||
              cell.charAt(0) === "D" ||
              cell.charAt(0) === "E" ||
              cell.charAt(0) === "F" ||
              cell.charAt(0) === "G" ||
              cell.charAt(0) === "H" ||
              cell.charAt(0) === "I" ||
              cell.charAt(0) === "J" ||
              cell.charAt(0) === "K" ||
              cell.charAt(0) === "L"
            ) {
              row[cell.charAt(0).charCodeAt() - 67] = WS[cell].v;
            }
            RF = true;
            Continue = true;
          }
          if (cell.charAt(0).charCodeAt() === charLength) {
            cmaTable.push(row);
            row = [];
            RF = false;
            Continue = true;
          }
        }
      });
      cmaTable.push(row);
      // console.log(charLength - 66);

      cmaTable.shift();
      cmaTable.splice(4, 2);
      cmaTable.splice(5, 3);
      cmaTable.splice(8, 1);
      cmaTable.splice(14, 1);
      cmaTable.splice(15, 1);
      cmaTable.splice(16, 3);
      cmaTable.splice(18, 1);
      cmaTable.splice(20, 1);
      cmaTable.splice(24, 1);
      cmaTable.splice(44, 1);
      cmaTable.splice(47, 1);
      cmaTable.splice(56, 3);
      cmaTable.splice(58, 4);
      cmaTable.splice(60, 1);
      cmaTable.splice(61, 1);
      cmaTable.splice(64, 1);
      cmaTable.splice(65, 1);
      cmaTable.splice(66, 1);
      cmaTable.splice(67, 1);
      cmaTable.splice(73, 8);
      cmaTable.splice(75, 2);
      cmaTable.splice(76, 1);
      cmaTable.splice(77, 1);
      cmaTable.splice(80, 1);
      cmaTable.splice(81, 1);
      cmaTable.splice(85, 1);
      cmaTable.splice(86, 1);
      cmaTable.splice(87, 1);
      cmaTable.splice(88, 1);
      cmaTable.splice(89, 1);
      cmaTable.splice(92, 1);
      cmaTable.splice(95, 1);
      cmaTable.splice(96, 1);
      cmaTable.splice(97, 1);
      cmaTable.splice(100, 1);
      cmaTable.splice(101, 1);
      cmaTable.splice(120, 1);
      cmaTable.splice(127, 1);
      cmaTable.splice(132, 1);
      cmaTable.splice(139, 1);
      cmaTable.splice(140, 1);
      cmaTable.splice(144, 1);
      cmaTable.splice(150, 1);
      cmaTable.splice(151, 1);
      cmaTable.splice(161, 1);
      cmaTable.splice(164, 1);
      cmaTable.splice(170, 1);
      cmaTable.splice(171, 1);
      cmaTable.splice(172, 1);
      cmaTable.splice(180, 1);
      cmaTable.splice(181, 1);
      cmaTable.splice(182, 1);
      cmaTable.splice(183, 1);
      cmaTable.splice(185, 1);
      cmaTable.splice(189, 1);
      cmaTable.splice(194, 14);
      let PMArr = [cmaTable[167], cmaTable[168]];

      dispatch(
        updateLT({
          L: charLength - 66,
        })
      );
      dispatch(
        updatePMT({
          L: charLength - 66,
          Arr: PMArr,
        })
      );
      dispatch(
        updatePLT({
          L: charLength - 66,
          Arr: cmaTable,
        })
      );
      dispatch(updateDepTotals());
      dispatch(updatePLTTotals());
    }
  }, [WS]);

  const onDrop = useCallback((acceptedFile) => {
    let fileReader = new FileReader();
    fileReader.readAsBinaryString(acceptedFile[0]);
    fileReader.onload = async (e) => {
      let data = e.target.result;
      let workbook = read(data, { type: "binary" });
      const worksheet = workbook.Sheets["Company Financial Input"];
      setWS(worksheet);
    };
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="upload">
      <div className="upload-box">
        <div {...getRootProps({ className: "dropzone bg2 txt1" })}>
          {/* <input {...getInputProps(LETTER)} /> */}
          <h1 className="t2">
            {acceptedFiles.length > 0 ? "Uploaded" : "Upload File here"}
          </h1>
        </div>
        <div className="upload-file-name">
          <ul className="txt1">{files}</ul>
        </div>
      </div>
    </div>
  );
};

export default UploadCMA;
