import { apiSlice } from "./apiSlice";
import { NUMBERING_URL } from "../constants";

export const numberingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNumbering: builder.mutation({
      query: (data) => ({
        url: `${NUMBERING_URL}`,
        method: "POST",
        body: data,
      }),
    }),
    addLevel: builder.mutation({
      query: (data) => ({
        url: `${NUMBERING_URL}/${data.id}`,
        method: "POST",
        body: { level: data.level },
      }),
    }),
    updateLevel: builder.mutation({
      query: (data) => ({
        url: `${NUMBERING_URL}/updateLevel/${data.id}`,
        method: "POST",
        body: { index: data.index, level: data.level },
      }),
    }),
    deleteLevel: builder.mutation({
      query: (data) => ({
        url: `${NUMBERING_URL}/${data.id}`,
        method: "DELETE",
        body: {
          index: data.index,
        },
      }),
    }),
    getNumbering: builder.query({
      query: () => ({
        url: `${NUMBERING_URL}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddNumberingMutation,
  useAddLevelMutation,
  useUpdateLevelMutation,
  useDeleteLevelMutation,
  useGetNumberingQuery,
} = numberingApiSlice;
