export const isValidVariableName = (name) => {
  const keywordList = [
    "break",
    "case",
    "catch",
    "class",
    "const",
    "continue",
    "debugger",
    "default",
    "delete",
    "do",
    "else",
    "export",
    "extends",
    "finally",
    "for",
    "function",
    "if",
    "import",
    "in",
    "instanceof",
    "let",
    "new",
    "return",
    "super",
    "switch",
    "this",
    "throw",
    "try",
    "typeof",
    "var",
    "void",
    "while",
    "with",
    "yield",
  ];

  // Check if it's a reserved keyword
  if (keywordList.includes(name)) {
    return false;
  }

  // Regular expression to validate the variable name
  const validVariableNamePattern = /^[a-zA-Z_$][a-zA-Z_$0-9]*$/;

  return validVariableNamePattern.test(name);
};
