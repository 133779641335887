import React, { useEffect, useState } from "react";
import Input from "../../utils/Input";
import { useDispatch, useSelector } from "react-redux";
import { setSingleForm } from "../../slices/formsSlice";
import { toast } from "react-toastify";

const FirmNameDate = () => {
  const dispatch = useDispatch();
  const [firmName, setFirmName] = useState();
  const [dateOfSanction, setDateOfSanction] = useState();
  const [noOfYears, setNoOfYears] = useState(5);
  const { forms } = useSelector((state) => state.forms);

  useEffect(() => {
    if (forms.FirmND) {
      setFirmName(forms.FirmND.firmName);
      setDateOfSanction(forms.FirmND.dateOfSanction);
      setNoOfYears(forms.FirmND.NOY);
    }
  }, [forms.FirmND]);

  return (
    <div className="psm">
      <div className="flex flex-wrap items-end gap-4">
        <div>
          <h1 className="pb-1">Enter Firm Name</h1>
          <Input
            type="text"
            placeholder="Enter Firm Name"
            className="w-[350px] h-[40px]"
            value={firmName}
            change={(e) => {
              setFirmName(e.target.value);
            }}
          />
        </div>
        <div>
          <h1 className="pb-1">Enter Date of Sanction</h1>
          <Input
            type="date"
            className="w-[200px] h-[40px]"
            value={
              dateOfSanction
                ? dateOfSanction.split("-").reverse().join("-")
                : ""
            }
            change={(e) => {
              const [year, month, day] = e.target.value.split("-");
              let date = `${day}-${month}-${year}`;
              setDateOfSanction(date);
            }}
          />
        </div>
        <div>
          <h1 className="pb-1">Enter No.of Years</h1>
          <Input
            type="number"
            placeholder="Enter Firm Name"
            className="w-[150px] h-[40px]"
            value={noOfYears}
            change={(e) => {
              setNoOfYears(e.target.value);
            }}
          />
        </div>
        <div>
          <button
            className="h-[40px] bg-cyan-950 text-light-500 px-2"
            onClick={() => {
              if (firmName !== undefined && dateOfSanction !== undefined) {
                dispatch(
                  setSingleForm({
                    name: "FirmND",
                    value: {
                      firmName,
                      dateOfSanction,
                      NOY: noOfYears,
                    },
                  })
                );
                toast.success(
                  "Firm Name and Date of Sanction Added Successfully"
                );
              }
            }}
          >
            Add Data
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirmNameDate;
