import React, { useState } from "react";
import DynamicRows from "./Dependencies/DynamicRows";
import Input from "../../utils/Input";
import Button from "../../utils/Button";
import * as XLSX from "xlsx";
import { timeStamp } from "./Dependencies/TimeStamp";

const NEFT_RTGS = () => {
  const [noOfRecords, setNoOfRecords] = useState(1);
  const [records, setRecords] = useState(
    Array.from({ length: noOfRecords }, () => ({
      accountNumber: "",
      amount: "",
      ifscCode: "",
      transfer: "TRANSFER",
      beneficiaryAccNumber: "",
      name: "",
      place: "",
      empty1: "",
      empty2: "",
      nefto: "NEFTO",
      empty3: "",
      accountNumber2: "",
      sms: "SMS",
      phoneNo: "",
    }))
  );

  const handleChange = (index, field, value) => {
    const updatedRecords = [...records];
    updatedRecords[index][field] = value;
    if (field === "accountNumber") {
      updatedRecords[index]["accountNumber2"] = value;
    }
    setRecords(updatedRecords);
  };

  const handleClick = () => {
    const fileContent = records
      .map(
        (record, i) =>
          `${record.accountNumber}|${record.amount}|${record.ifscCode}|TRANSFER|${record.beneficiaryAccNumber}|${record.name}|${record.place}|||NEFTO||${record.accountNumber}|SMS|${record.phoneNo}`
      )
      .join("\n");

    const blob = new Blob([fileContent], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${timeStamp()}.txt`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleExcelDownload = () => {
    let amtTotal = 0;
    records.forEach((rec) => {
      amtTotal += parseFloat(rec.amount);
    });

    // Create worksheet and set column widths
    const worksheet = XLSX.utils.json_to_sheet(records);
    worksheet["!cols"] = [
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 80 },
      { wpx: 70 },
      { wpx: 170 },
      { wpx: 300 },
      { wpx: 100 },
      { wpx: 1 },
      { wpx: 1 },
      { wpx: 50 },
      { wpx: 1 },
      { wpx: 100 },
      { wpx: 50 },
      { wpx: 100 },
    ]; // Adjust widths as needed

    // Add total row at the end
    const lastRowIndex = records.length + 2;
    XLSX.utils.sheet_add_aoa(worksheet, [["Total", amtTotal]], {
      origin: `A${lastRowIndex}`,
    });

    // Create workbook and append the sheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Records");

    // Save the workbook
    XLSX.writeFile(workbook, "neft.xlsx");
  };

  return (
    <div>
      <h2 className="font-mono pb-2">Enter the following details</h2>
      <div className="pb-6">
        <h2 className="pb-2">Enter No. of NEFT records</h2>
        <Input
          className="w-[250px] h-[40px]"
          type="number"
          value={noOfRecords}
          change={(e) => {
            const newNumber = parseFloat(e.target.value);
            setNoOfRecords(newNumber);
            setRecords(
              Array.from(
                { length: newNumber },
                (_, i) =>
                  records[i] || {
                    accountNumber: "",
                    amount: "",
                    ifscCode: "",
                    beneficiaryAccNumber: "",
                    name: "",
                    place: "",
                  }
              )
            );
          }}
        />
      </div>
      <div className="flex flex-col gap-4">
        {Array.from({ length: noOfRecords }).map((_, index) => (
          <DynamicRows
            key={index}
            index={index}
            record={records[index]}
            onChange={handleChange}
            type="NEFT"
          />
        ))}
      </div>
      <div className="flex flex-wrap gap-4">
        <Button name="Download .txt file" click={handleClick} value={true} />
        <Button
          name="Download .xlsx file"
          click={handleExcelDownload}
          value={true}
        />
      </div>
    </div>
  );
};

export default NEFT_RTGS;
