import React, { useEffect, useState } from "react";
import { useGetSingleDepDocMutation } from "../../slices/documentsApiSlice";
import { toast } from "react-toastify";
import {
  setListIDS,
  setStructuredIDS,
  getListIDS,
  setInternalDependency,
} from "../../slices/internalDependencySlice";
import { setFormsForUser, setFormsForManager } from "../../slices/formsSlice";
import { useDispatch, useSelector } from "react-redux";
import produceClone from "../../components/Lodash";
import Loader from "../../components/Loader";

const RecursiveDependency = ({ tempIDS }) => {
  const dispatch = useDispatch();
  const [dependencyDocument, setDependencyDocument] = useState();
  const [getSingleDepDoc, { isLoading }] = useGetSingleDepDocMutation();
  // const [ids, setIds] = useState();
  const { listIDS, structuredIDS, internalDependency } = useSelector(
    (state) => state.internalDependency
  );
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    const findValueInObject = (obj, targetCategory, targetName) => {
      let result = null;
      const traverse = (currentObj) => {
        for (const key in currentObj) {
          if (typeof currentObj[key] === "object" && currentObj[key] !== null) {
            traverse(currentObj[key]);
          } else if (
            currentObj.depCategory === targetCategory &&
            currentObj.depName === targetName
          ) {
            result = currentObj;
            return;
          }
        }
      };
      traverse(obj);
      return result;
    };
    if (dependencyDocument) {
      let ids = produceClone(structuredIDS);
      const foundObject = findValueInObject(
        ids,
        dependencyDocument.category,
        dependencyDocument.name
      );
      foundObject.depName = dependencyDocument.name;
      let internalDep = {};
      if (
        dependencyDocument.internalDependency &&
        Object.keys(dependencyDocument.internalDependency).length > 0
      ) {
        Object.keys(dependencyDocument.internalDependency).map((category) => {
          let depArr = [];
          dependencyDocument.internalDependency[category].map((dep) => {
            depArr.push({
              depCategory: dep.category,
              depName: dep.name,
              internalDep: [],
            });
          });
          internalDep[category] = depArr;
        });
      }
      foundObject.internalDep = internalDep;
      dispatch(setStructuredIDS(ids));
    }
  }, [dependencyDocument]);

  return (
    <div>
      {tempIDS &&
        Object.keys(tempIDS).map((categoryKey, i) => {
          const currentDeps = tempIDS[categoryKey];
          return (
            <div key={`category-${i}`} className="pt-5 pl-5">
              <h2 className="font-mono pb-2">{categoryKey} [Select One]</h2>
              <div className="flex gap-4">
                {currentDeps.map((dep, depIndex) => {
                  return (
                    <button
                      key={`dep-${i}-${depIndex}`}
                      className={`${
                        listIDS &&
                        listIDS[i]?.name === dep.depName &&
                        listIDS[i]?.category === dep.depCategory
                          ? "bg-primary-500"
                          : "bg-cyan-950"
                      } text-light-500 p-2`}
                      onClick={async () => {
                        dispatch(
                          setListIDS({
                            document: `${dep.depCategory}$${dep.depName}`,
                            entryForm: `${dep.depCategory}$${dep.depName}_EF`,
                            category: dep.depCategory,
                            name: dep.depName,
                          })
                        );
                        try {
                          const res = await getSingleDepDoc({
                            category: dep.depCategory,
                            name: dep.depName,
                          }).unwrap();
                          console.log(res);
                          setDependencyDocument(res);
                          if (userInfo.role === "Manager") {
                            dispatch(setInternalDependency(res));
                            dispatch(setFormsForManager(res));
                          } else if (userInfo.role === "User") {
                            dispatch(setFormsForUser(res));
                          }
                        } catch (err) {
                          toast.error(err?.data?.message || err.error);
                        }
                      }}
                    >
                      {dep.depName}
                    </button>
                  );
                })}
              </div>
              {isLoading ? (
                <Loader />
              ) : (
                dependencyDocument &&
                currentDeps.map((dep, depIndex) => {
                  return (
                    <RecursiveDependency
                      key={`recursive-${i}-${depIndex}`}
                      document={dependencyDocument}
                      // listIDS={listIDS}
                      tempIDS={dep.internalDep || {}}
                    />
                  );
                })
              )}
            </div>
          );
        })}
    </div>
  );
};

export default RecursiveDependency;
