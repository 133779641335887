import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import produceClone from "../../../../components/Lodash"; // DO NOT REMOVE
import { setDocs, setRunStatus } from "../../../../slices/docsSlice";
import { setFormsRunStatus } from "../../../../slices/formsSlice";

const RunFunctions = () => {
  const dispatch = useDispatch();
  const { docs, name, category, runStatus, entryForm } = useSelector(
    (state) => state.docs
  );
  const { userForms, forms } = useSelector((state) => state.forms);
  const { document: mainFile } = useSelector((state) => state.document);
  const { internalDependency } = useSelector(
    (state) => state.internalDependency
  );

  const TABLE = {
    ele: [],
    properties: {},
    name: "",
    nor: 0,
    noc: 0,
    type: "table",
  };

  const ROW = {
    ele: [],
    properties: {},
    name: "",
    type: "row",
  };

  const CELL = {
    ele: [],
    properties: {},
    name: "",
    type: "cell",
  };

  const PARA = {
    ele: [],
    properties: {},
    name: 0,
    type: "para",
  };

  const TEXT = {
    t: "",
    properties: {},
    type: "text",
  };

  window["num"] = (val) => {
    const num = parseFloat(val);
    if (isNaN(num) || !isFinite(num)) {
      return 0;
    }
    return num;
  };

  window["dn"] = (val) => {
    return parseFloat(val).toFixed(2);
  };

  window["tc"] = (val) => {
    if (typeof val !== "number") {
      return 0;
    } else if (isNaN(val)) {
      return 0;
    } else if (val === Infinity) {
      return 0;
    }
    return val;
  };

  window["CC"] = ({ V, CP, PP, TP }) => {
    let cell = structuredClone(CELL);
    let para = structuredClone(PARA);
    let text = structuredClone(TEXT);
    if (CP) {
      cell.properties = CP;
    }
    if (PP) {
      para.properties = PP;
    }
    if (TP) {
      text.properties = TP;
    }
    text.t = V;
    para.ele.push(text);
    cell.ele.push(para);
    return cell;
  };

  window["CR"] = (cells) => {
    try {
      let row = {
        ele: [],
        properties: {},
        name: "",
        type: "row",
      };
      cells.map(({ V, CP, PP, TP }, i) => {
        let cell = {
          ele: [],
          properties: CP || {},
          name: "",
          type: "cell",
        };
        let para = {
          ele: [],
          properties: PP || {},
          name: 0,
          type: "para",
        };
        let text = {
          t: V,
          properties: TP || {},
          type: "text",
        };
        para.ele.push(text);
        cell.ele.push(para);
        row.ele.push(cell);
      });
      return row;
    } catch (error) {
      console.error("Error in CR function:", error);
    }
  };

  let newForms = produceClone(forms);
  Object.keys(newForms).forEach((form) => {
    window[form] = newForms[form];
  });

  const generateReport = () => {
    let CES = produceClone(docs);
    let NEF = produceClone(entryForm);
    console.log(entryForm);
    CES.map((doc, i1) => {
      // let XTS = NEF[i1].XTS;
      doc.ele.map((sec, i2) => {
        sec.ele.forEach((E, i3) => {
          console.log(NEF);
          let USER = NEF[i1].ele[i2].ele[i3].user;
          let MANAGER = NEF[i1].ele[i2].ele[i3].manager;
          if (E.jsFun) {
            let func = eval("(" + E.jsFun + ")");
            func(E, E.ele, USER, MANAGER);
          }
        });
      });
    });
    dispatch(setDocs(CES));
  };

  return (
    <div className="flex gap-4">
      <button
        className="bg-cyan-950 text-light-500 px-2 py-1"
        onClick={() => {
          if (category === mainFile.category) {
            dispatch(
              setFormsRunStatus({ name: `${category}$${name}`, value: true })
            );
            dispatch(setRunStatus(true));
          } else {
            internalDependency.map((doc, i) => {
              if (category === doc.category) {
                dispatch(
                  setFormsRunStatus({
                    name: `${category}$${name}`,
                    value: true,
                  })
                );
                dispatch(setRunStatus(true));
              }
            });
          }
          generateReport();
        }}
      >
        Run
      </button>
      {runStatus ? (
        <button
          className="bg-warning-500 text-black px-2 py-1"
          onClick={() => {
            // if (category === mainFile.category) {
            //   dispatch(
            //     setFormsRunStatus({ name: `${category}$${name}`, value: false })
            //   );
            //   dispatch(setRunStatus(false));
            //   dispatch(setDocs(mainFile.document));
            // } else {
            internalDependency.map((doc, i) => {
              if (category === doc.category && name === doc.name) {
                dispatch(
                  setFormsRunStatus({
                    name: `${category}$${name}`,
                    value: false,
                  })
                );
                dispatch(setRunStatus(false));
                dispatch(setDocs(doc.document));
              }
            });
            // }
          }}
        >
          Clear
        </button>
      ) : null}
    </div>
  );
};

export default RunFunctions;
