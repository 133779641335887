import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
    },
    logout: (state, action) => {
      state.userInfo = null;
      // NOTE: here we need to also remove the cart from storage so the next
      // logged in user doesn't inherit the previous users cart and shipping
      localStorage.clear();
    },
    updateCredentials: (state, action) => {
      state.userInfo.permissionRequest = action.payload;
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      userInfo.permissionRequest = action.payload;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    updatePasswordRequests: (state, { payload }) => {
      state.userInfo.passwordRequest = payload;
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      userInfo.passwordRequest = payload;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
  },
});

export const {
  setCredentials,
  updateCredentials,
  updatePasswordRequests,
  logout,
} = authSlice.actions;

export default authSlice.reducer;
