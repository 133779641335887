import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  document: undefined,
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setDocument: (state, { payload }) => {
      state.document = payload;
    },
    resetDocument: (state, { payload }) => {
      state.document = undefined;
    },
  },
});

export const { setDocument, resetDocument } = documentSlice.actions;

export default documentSlice.reducer;
