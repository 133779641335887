import React from "react";

const TableRow = ({ children, isHeader = false, className }) => {
  return (
    <tr
      className={`${className} ${
        isHeader
          ? "bg-gray-200 text-gray-600 text-sm font-medium uppercase tracking-wider"
          : null
      }`}
    >
      {children}
    </tr>
  );
};

export default TableRow;
