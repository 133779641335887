import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sideBar: true,
};

const utilSlice = createSlice({
  name: "util",
  initialState,
  reducers: {
    toggleSidebar: (state, { payload }) => {
      state.sideBar = !state.sideBar;
    },
  },
});

export const { toggleSidebar } = utilSlice.actions;

export default utilSlice.reducer;
