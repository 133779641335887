import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  level: [],
};

const levelSlice = createSlice({
  name: "level",
  initialState,
  reducers: {
    updateLevel: (state, { payload }) => {
      state.level = payload;
    },
    updateName: (state, { payload }) => {
      state.name = payload;
    },
    updateLvlInput: (state, { payload }) => {
      state.level[payload.i][payload.j] = payload.value;
    },
  },
});

export const { updateLevel, updateName, updateLvlInput } = levelSlice.actions;
export default levelSlice.reducer;
