import { getSectionStyle } from "./Styles/sectionStyle";
import { getPageBorderStyle } from "./Styles/pageBorder";
import { twipToPt } from "../UnitConversion";
import DeleteElement from "../Elements/DeleteElement";
import { useDispatch, useSelector } from "react-redux";
import { setIndexPath } from "../../../../slices/docsSlice";

import CreatePara from "./Para";
import CreateTable from "./Table";
import { useNavigate } from "react-router-dom";

// Remove Imports

const CreateSection = ({ sec, index, loc, doc, baseURL, SIPHF }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { RRIP_S, NIP_S } = useSelector((state) => state.docs);
  const sectionStyle = getSectionStyle({
    sectionProps: sec?.properties,
    docProps: doc?.properties,
  });
  const pageBorderStyle = getPageBorderStyle({
    sectionProps: sec?.properties,
  });
  return (
    <section
      key={index}
      id={index}
      className={`${
        sec.properties?.page?.size?.orientation === "portrait"
          ? "w-[22cm] min-h-[29.7cm]"
          : "w-[29.7cm] min-h-[22cm]"
      } bg-light-500/90 flex-grow relative morphous`}
      style={sectionStyle}
      // style={{
      //   paddingLeft: `${twipToPt(sec.properties.page.margin.left - 400)}pt`,
      //   paddingRight: `${twipToPt(sec.properties.page.margin.right - 400)}pt`,
      //   paddingTop: `${twipToPt(sec.properties.page.margin.top)}pt`,
      //   paddingBottom: `${twipToPt(sec.properties.page.margin.bottom)}pt`,
      // }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setIndexPath(`${loc}`));
        navigate(`${baseURL}/${loc}/SP`);
      }}
    >
      <DeleteElement RRIP={RRIP_S} NIP={NIP_S} index={index} path={baseURL} />
      <div
        className="flex-grow flex flex-col"
        style={pageBorderStyle}
        // className={`${
        //   doc.properties.border ? "border" : "border-none"
        // } border-dark-900 flex-grow pt-1 pb-1 flex flex-col`}
        // style={{
        //   paddingLeft: `${twipToPt(400)}pt`,
        //   paddingRight: `${twipToPt(400)}pt`,
        // }}
      >
        {sec.headers?.ele?.map((ele, i1) => {
          if (ele.type === "para") {
            return (
              <CreatePara
                para={ele}
                index={`${index}_0_${i1}`}
                loc={`${loc}/0_H/${i1}_P`}
                sec={sec}
                doc={doc}
                baseURL={baseURL}
                SIPHF={SIPHF}
              />
            );
          } else if (ele.type === "table") {
            return (
              <CreateTable
                table={ele}
                index={`${index}_0_${i1}`}
                loc={`${loc}/0_H/${i1}_TB`}
                sec={sec}
                doc={doc}
                baseURL={baseURL}
                SIPHF={SIPHF}
              />
            );
          }
        })}
        {sec.ele.map((ele, i1) => {
          if (ele.type === "para") {
            return (
              <CreatePara
                para={ele}
                index={`${index}_${i1}`}
                loc={`${loc}/${i1}_P`}
                sec={sec}
                doc={doc}
                baseURL={baseURL}
                SIPHF={SIPHF}
              />
            );
          } else if (ele.type === "table") {
            return (
              <CreateTable
                table={ele}
                index={`${index}_${i1}`}
                loc={`${loc}/${i1}_TB`}
                sec={sec}
                doc={doc}
                baseURL={baseURL}
                SIPHF={SIPHF}
              />
            );
          }
        })}
        {sec.footers?.ele?.map((ele, i1) => {
          if (ele.type === "para") {
            return (
              <CreatePara
                para={ele}
                index={`${index}_0_${i1}`}
                loc={`${loc}/0_F/${i1}_P`}
                sec={sec}
                doc={doc}
                baseURL={baseURL}
                SIPHF={SIPHF}
              />
            );
          } else if (ele.type === "table") {
            return (
              <CreateTable
                table={ele}
                index={`${index}_0_${i1}`}
                loc={`${loc}/0_F/${i1}_TB`}
                sec={sec}
                doc={doc}
                baseURL={baseURL}
                SIPHF={SIPHF}
              />
            );
          }
        })}
      </div>
    </section>
  );
};

export default CreateSection;
