import { Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";

const Layout = () => {
  return (
    <section className="h-full">
      <Header />
      <section className="document-layout-body">
        <Outlet />
      </section>
    </section>
  );
};

export default Layout;
