import { apiSlice } from "./apiSlice";
import { ENTRYFORM_URL } from "../constants";

export const entryFormApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addEntryForm: builder.mutation({
      query: (data) => ({
        url: `${ENTRYFORM_URL}/addEntryForm`,
        method: "POST",
        body: data,
      }),
    }),
    getUserEntryForms: builder.query({
      query: () => ({
        url: `${ENTRYFORM_URL}/getUserEntryForms`,
        method: "GET",
      }),
    }),
    getEntryForms: builder.query({
      query: () => ({
        url: ENTRYFORM_URL,
        method: "GET",
      }),
    }),
    deleteEntryForm: builder.mutation({
      query: (documentId) => ({
        url: `${ENTRYFORM_URL}/${documentId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddEntryFormMutation,
  useGetUserEntryFormsQuery,
  useGetEntryFormsQuery,
  useDeleteEntryFormMutation,
} = entryFormApiSlice;
