import React, { useState } from "react";

const Dropdown = ({
  name,
  value,
  options,
  click,
  target,
  condition,
  style,
  width,
  height,
}) => {
  const [show, setShow] = useState(false);

  return (
    // <div className="cursor-pointer flex gap-4">
    <div
      className={`${height ? `h-[${height}px]` : "h-[48px]"} ${
        width !== undefined ? `w-[${width}px]` : "w-[300px]"
      } p-[0.75rem] line-clamp-[20px] border flex items-center relative overflow-visible cursor-pointer`}
      style={style}
      onClick={() => setShow(!show)}
    >
      {value !== undefined && value !== "" ? value : `Select ${name}`}
      {show ? (
        <div
          className={`absolute right-0 left-0 top-[48px] ${
            width !== undefined ? `w-[${width}px]` : "w-[300px]"
          } bg-white border rounded-md shadow-lg text-dark-900 overflow-clip z-50`}
        >
          <ul className="py-1">
            {name ? (
              <li
                className="px-4 py-2 hover:bg-primary-500 hover:text-white"
                onClick={() => click("")}
              >
                Select {name}
              </li>
            ) : null}
            {options.map((option, i) => {
              if (condition) {
                if (eval(condition)) {
                  return (
                    <li
                      key={i}
                      className="px-4 py-2 hover:bg-primary-500 hover:text-white"
                      onClick={() => click(option)}
                    >
                      {eval(target)}
                    </li>
                  );
                }
              } else {
                return (
                  <li
                    key={i}
                    className="px-4 py-2 hover:bg-primary-500 hover:text-white"
                    onClick={() => click(option)}
                  >
                    {eval(target)}
                  </li>
                );
              }
            })}
          </ul>
        </div>
      ) : null}
    </div>
    // </div>
  );
};

export default Dropdown;
