import React, { useState } from "react";
import DebitRows from "./Dependencies/DebitRows";
import Input from "../../utils/Input";
import Button from "../../utils/Button";
import * as XLSX from "xlsx";
import { timeStamp } from "./Dependencies/TimeStamp";

const Fund_Transfer = () => {
  const [noOfRecords, setNoOfRecords] = useState(1);
  const [records, setRecords] = useState(
    Array.from({ length: noOfRecords }, () => ({
      debitAccountNumber: "",
      debit: "Debit",
      senderName: "",
      amount: "",
      noOfCredits: 1,
      credits: [
        {
          creditAccountNumber: "",
          credit: "Credit",
          beneficiaryName: "",
          amount: "",
        },
      ],
    }))
  );

  const handleNoOfCredits = (index, field, value) => {
    const updatedRecords = [...records];
    updatedRecords[index].noOfCredits = value;

    // Adjust the credits array length
    const credits = updatedRecords[index].credits;
    if (credits.length < value) {
      updatedRecords[index].credits = [
        ...credits,
        ...Array.from({ length: value - credits.length }, () => ({
          creditAccountNumber: "",
          credit: "Credit",
          beneficiaryName: "",
          amount: "",
        })),
      ];
    } else {
      updatedRecords[index].credits = credits.slice(0, value);
    }

    setRecords(updatedRecords);
  };

  const handleChange = (index, field, value) => {
    const updatedRecords = [...records];
    updatedRecords[index][field] = value;
    setRecords(updatedRecords);
  };

  const handleChange1 = (index, index2, field, value) => {
    const updatedRecords = [...records];
    updatedRecords[index].credits[index2][field] = value;
    setRecords(updatedRecords);
  };

  const handleClick = () => {
    let isTally = false;
    let totalAmt = 0;
    let calTotal = 0;
    const fileContent = records
      .map((record, i) => {
        let data = "";
        totalAmt = parseFloat(record.amount);
        record.credits.forEach((record1, j) => {
          calTotal = calTotal + parseFloat(record1.amount);
          data =
            data +
            `${record.debitAccountNumber},D,TRANSFER,${record1.beneficiaryName},${record1.amount}\n`;
          data =
            data +
            `${record1.creditAccountNumber},C,TRANSFER,${record.senderName},${record1.amount}\n`;
        });
        return data;
      })
      .join("");

    if (totalAmt === calTotal) {
      isTally = true;
    }

    if (isTally) {
      const blob = new Blob([fileContent], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${timeStamp()}.txt`;
      link.click();
      URL.revokeObjectURL(url);
    } else {
      window.alert(
        "Debit amount is not equal to Credit amount, please verify."
      );
    }
  };

  const handleExcelDownload = () => {
    let amtTotal = 0;
    records.forEach((rec) => {
      amtTotal += parseFloat(rec.amount);
    });

    const worksheet = XLSX.utils.json_to_sheet(records);
    worksheet["!cols"] = [{ wpx: 100 }, { wpx: 100 }, { wpx: 80 }, { wpx: 70 }];
    const lastRowIndex = records.length + 2;
    XLSX.utils.sheet_add_aoa(worksheet, [["Total", amtTotal]], {
      origin: `A${lastRowIndex}`,
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Records");
    XLSX.writeFile(workbook, "fundTransfer.xlsx");
  };

  return (
    <div>
      <h2 className="font-mono pb-2">Enter the following details</h2>
      <div className="pb-6">
        <h2 className="pb-2">No. of Debit Records</h2>
        <Input
          className="w-[250px] h-[40px]"
          type="number"
          value={noOfRecords}
          change={(e) => {
            const newNumber = parseInt(e.target.value);
            setNoOfRecords(newNumber);
            setRecords(
              Array.from(
                { length: newNumber },
                (_, i) =>
                  records[i] || {
                    debitAccountNumber: "",
                    debit: "Debit",
                    senderName: "",
                    amount: "",
                    noOfCredits: 1,
                    credits: [
                      {
                        creditAccountNumber: "",
                        credit: "Credit",
                        beneficiaryName: "",
                        amount: "",
                      },
                    ],
                  }
              )
            );
          }}
        />
      </div>
      <div className="flex flex-col gap-4">
        {Array.from({ length: noOfRecords }).map((_, index) => (
          <DebitRows
            key={index}
            index={index}
            record={records[index]}
            onChange={handleChange}
            onChange1={handleChange1}
            noOfCredits={handleNoOfCredits}
          />
        ))}
      </div>
      <div className="flex flex-wrap gap-4 pt-2">
        <Button name="Download .txt file" click={handleClick} value={true} />
        {/* <Button
          name="Download .xlsx file"
          click={handleExcelDownload}
          value={true}
        /> */}
      </div>
    </div>
  );
};

export default Fund_Transfer;
