import { apiSlice } from "./apiSlice";
import { PASSWORD_URL } from "../constants";

export const passwordApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPasswordRequests: builder.query({
      query: () => ({
        url: PASSWORD_URL,
      }),
      providesTags: ["Password"],
      // keepUnusedDataFor: 5,
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `${PASSWORD_URL}`,
        method: "POST",
        body: data,
      }),
      providesTags: ["Password"],
    }),
  }),
});

export const { useGetPasswordRequestsQuery, useForgotPasswordMutation } =
  passwordApiSlice;
