import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  agency: "",
};

const agencySlice = createSlice({
  name: "agency",
  initialState,
  reducers: {
    setAgency: (state, { payload }) => {
      state.agency = payload;
    },
    resetAgency: (state, { payload }) => {
      state.agency = "";
    },
  },
});

export const { setAgency, resetAgency } = agencySlice.actions;

export default agencySlice.reducer;
