import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Input from "../../../../../utils/Input";
import Dropdown from "../../../../../utils/Dropdown";
import { IoCloseSharp } from "react-icons/io5";

import {
  addCondition,
  addMXTInput,
  addMXTDropDown,
  updateMXTName,
  updateMXTDv,
  removeMXT,
} from "../../../../../slices/docsSlice";

const MxtControls = ({ path, dynEle, operator }) => {
  const dispatch = useDispatch();
  const { docs, indexPath } = useSelector((state) => state.docs);
  return (
    <div className="pb-4 min-h-[300px]">
      <div className="flex items-start justify-between">
        <h2 className="font-mono">{operator.toUpperCase()}</h2>
        <div className="flex gap-2 pb-2">
          {docs ? (
            <Input
              className="w-[200px] h-[40px]"
              type="text"
              placeholder="Purpose Ex: Partner"
              value={eval(dynEle)[operator].condition}
              change={(e) => {
                dispatch(
                  addCondition({
                    path: path,
                    operator: operator,
                    value: e.target.value,
                  })
                );
              }}
              click={() => {
                let val = `${operator}.MXT.map((R, i) => {\n    \n  });`;
                navigator.clipboard.writeText(val);
              }}
            />
          ) : null}
          <button
            className="px-2 bg-cyan-950 text-light-500 font-mono h-[40px]"
            onClick={() => {
              dispatch(
                addMXTInput({
                  path: path,
                  operator: operator,
                })
              );
            }}
          >
            Add Input
          </button>
          <button
            className="px-2 bg-cyan-950 text-light-500 font-mono h-[40px]"
            onClick={() => {
              let noOfVal = prompt("No. of Inputs");
              let valArr = [];
              for (let i = 0; i < noOfVal; i++) {
                let val = prompt(`Input ${i + 1}`);
                valArr.push(val);
              }
              dispatch(
                addMXTDropDown({
                  path: path,
                  name: valArr,
                  operator: operator,
                })
              );
            }}
          >
            Add Dropdown
          </button>
        </div>
      </div>
      <div className="flex gap-4 flex-wrap">
        {docs && eval(dynEle)[operator].MXT
          ? eval(dynEle)[operator].MXT.map((mxt, i) => {
              if (typeof mxt.name === "string") {
                return (
                  <div className="flex flex-wrap gap-2 relative">
                    <div
                      className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
                      onClick={() =>
                        dispatch(
                          removeMXT({
                            path: path,
                            operator: operator,
                            index: i,
                          })
                        )
                      }
                    >
                      <IoCloseSharp />
                    </div>
                    <div>
                      <Input
                        className="w-[250px] h-[40px]"
                        type="text"
                        value={mxt.name}
                        change={(e) =>
                          dispatch(
                            updateMXTName({
                              path: path,
                              operator: operator,
                              index: i,
                              value: e.target.value,
                            })
                          )
                        }
                      />
                    </div>
                    <div>
                      <Input
                        className="w-[100px] h-[40px]"
                        placeholder="dv"
                        type="text"
                        value={mxt.dv}
                        change={(e) =>
                          dispatch(
                            updateMXTDv({
                              path: path,
                              operator: operator,
                              index: i,
                              value: e.target.value,
                            })
                          )
                        }
                      />
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="flex flex-wrap gap-2 relative">
                    <div
                      className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
                      onClick={() =>
                        dispatch(
                          removeMXT({
                            path: path,
                            operator: operator,
                            index: i,
                          })
                        )
                      }
                    >
                      <IoCloseSharp />
                    </div>
                    <div>
                      <Dropdown
                        options={mxt.name}
                        target="option"
                        value={mxt.dv}
                        click={(value) =>
                          dispatch(
                            updateMXTDv({
                              path: path,
                              operator: operator,
                              index: i,
                              value: value,
                            })
                          )
                        }
                        height={40}
                        width={250}
                      />
                    </div>
                    <div>
                      <Input
                        className="w-[100px] h-[40px]"
                        placeholder="dv"
                        type="text"
                        value={mxt.dv}
                        disabled={true}
                      />
                    </div>
                  </div>
                );
              }
            })
          : null}
      </div>
    </div>
  );
};

export default MxtControls;
