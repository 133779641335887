import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DocumentSVG from "../assets/icons/word.svg";
import { TbMenuDeep } from "react-icons/tb";
import { CgMenuGridR } from "react-icons/cg";
import { BsFillFileEarmarkImageFill } from "react-icons/bs";
import { toggleSidebar } from "../slices/utilSlice";
import Input2 from "../utils/Input2";
import Dropdown from "../utils/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resetDocs,
  setDocId,
  setDocs,
  setIndexPath,
  setEntryForm,
  setName,
  setCategory,
  setRunStatus,
  setDev,
  setMinimal,
} from "../slices/docsSlice";
import { resetDocument } from "../slices/documentSlice";
import { resetForms } from "../slices/formsSlice";
import { resetInternalDependency } from "../slices/internalDependencySlice";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { agency } = useSelector((state) => state.agency);
  const { document } = useSelector((state) => state.document);
  const [ID, setID] = useState(document?.category); // Internal Dependency
  const [ED, setED] = useState("");
  const { docs, category, indexPath, dev, minimal } = useSelector(
    (state) => state.docs
  );
  const { userForms, forms } = useSelector((state) => state.forms);
  const { listIDS, internalDependency } = useSelector(
    (state) => state.internalDependency
  );
  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);

  const [newIDS, setNewIDS] = useState([]);

  useEffect(() => {
    if (document?.internalDependency) {
      const dependencies = [];
      Object.keys(document.internalDependency).map((dependency) => {
        dependencies.push(dependency);
        dependencies.push(`${dependency}_EF`);
      });
      setNewIDS(dependencies);
    }
  }, [document]);

  useEffect(() => {
    if (isReadyToNavigate) {
      navigate("/manager/CD");
    }
  }, [isReadyToNavigate]);

  useEffect(() => {
    if (
      !document &&
      !internalDependency &&
      !userForms &&
      !forms &&
      !listIDS &&
      !docs
    ) {
      setIsReadyToNavigate(true);
    }
  }, [internalDependency, listIDS, userForms, forms, document, docs]);

  // const { indexPath } = useSelector((state) => state.docs);

  const { pathname } = useLocation();
  let splitLoc = pathname.split("/");
  const currentPath = splitLoc[3];
  if (splitLoc.length >= 4) {
    let length = splitLoc.length - 3;
    splitLoc.splice(3, length);
  }
  const baseURL = splitLoc.join("/");

  // const splitIndexPath = indexPath.split("/");

  // console.log(internalDependency);

  return (
    <div className="box psm-x justify-between border-b z-10">
      <div className="box gap-2">
        <div
          className="cursor-pointer hover:text-orange-500"
          onClick={() => dispatch(toggleSidebar())}
        >
          <CgMenuGridR className="h-[30px] w-[30px]" />
        </div>
        <div
          className="cursor-pointer py-1 px-2 border-secondary text-lg flex items-center border font-trebuchet"
          onClick={() => {
            dispatch(resetDocument());
            dispatch(resetInternalDependency());
            dispatch(resetForms());
            dispatch(resetDocs());
            navigate("/manager/CD");
          }}
        >
          {/* <div className="pr-1">
            <TbMenuDeep />
          </div> */}
          <h3>{agency ? agency : "Doc Creator"}</h3>
        </div>
      </div>
      <div>
        {/* {splitIndexPath.map((ip) => {
          const splitIp = ip.split("_");
          return (
            <>
              <Input2 value={splitIp[0]} />
            </>
          );
        })} */}
      </div>

      <div className="flex gap-4">
        <div className="flex">
          <button
            className={`border w-[100px] ${
              minimal ? "bg-cyan-950 text-light-500" : ""
            }`}
            onClick={() => dispatch(setMinimal())}
          >
            MINIMAL
          </button>
        </div>
        <div className="flex">
          <button
            className={`border w-[100px] ${
              dev ? "bg-cyan-950 text-light-500" : ""
            }`}
            onClick={() => dispatch(setDev())}
          >
            DEV
          </button>
        </div>
        {document ? (
          <>
            <Dropdown
              options={[...userForms].sort((a, b) =>
                a.name.localeCompare(b.name)
              )}
              // name="Document"
              value={ID.substring(ID.indexOf("$") + 1).replaceAll("_", " ")}
              target={`option.name.substring(option.name.indexOf("$") + 1).replaceAll("_", " ")`}
              click={(value) => {
                // console.log(value);
                value = value.name;
                // console.log(value);
                setID(value);
                if (
                  internalDependency.some(
                    (ID) => `${ID.category}$${ID.name}` === value
                  )
                ) {
                  // console.log(forms[value]);
                  dispatch(setDocId(forms[value]._id));
                  dispatch(setDocs(forms[value].document));
                  dispatch(setEntryForm(forms[`${value}_EF`]));
                  dispatch(setIndexPath(forms[value].indexPath));
                  dispatch(setName(forms[value].name));
                  dispatch(setCategory(forms[value].category));
                  dispatch(setRunStatus(forms[value].runStatus));
                  if (forms[value].indexPath === "") {
                    navigate(`/manager/${value}/DC`);
                  } else {
                    let RRIP = forms[value].indexPath.split("/");
                    let lastIndex = RRIP[RRIP.length - 1];
                    let SLI = lastIndex.split("_");
                    let path = `/manager/${value}/${forms[value].indexPath}`;
                    let NIP = RRIP.map((index) => index.split("_")[0]).join(
                      "_"
                    );
                    // console.log(SLI[1]);
                    switch (SLI[1]) {
                      case "I":
                        if (RRIP.length === 4) {
                          navigate(`${path}/SP/PP/IP`);
                        } else {
                          navigate(`${path}/SP/TBP/TR/TC/PP/IP`);
                        }
                        break;
                      case "T":
                        if (RRIP.length === 4) {
                          navigate(`${path}/SP/PP/TP/0#${NIP}`);
                        } else {
                          navigate(`${path}/SP/TBP/TR/TC/PP/TP/0#${NIP}`);
                        }
                        break;
                      case "P":
                        if (RRIP.length === 3) {
                          navigate(`${path}/SP/PP`);
                        } else {
                          navigate(`${path}/SP/TBP/TR/TC/PP`);
                        }
                        break;
                      case "TC":
                        navigate(`${path}/SP/TBP/TR/TC`);
                        break;
                      case "TR":
                        navigate(`${path}/SP/TBP/TR`);
                        break;
                      case "TB":
                        navigate(`${path}/SP/TBP`);
                        break;
                      case "S":
                        navigate(`${path}/SP`);
                        break;
                      case "Doc":
                        navigate(`${path}/DP`);
                        break;
                    }
                  }
                  // navigate(`/manager/${value}/${forms[value].indexPath}`);
                }
                // else if (value === `${document.category}$${document.name}`) {
                //   dispatch(setDocId(forms[value]._id));
                //   dispatch(setDocs(forms[value].document));
                //   dispatch(setEntryForm(forms[`${value}_EF`]));
                //   dispatch(setIndexPath(forms[value].indexPath));
                //   dispatch(setName(forms[value].name));
                //   dispatch(setCategory(forms[value].category));
                //   dispatch(setRunStatus(forms[value].runStatus));
                //   if (forms[value].indexPath === "") {
                //     navigate(`/manager/${value}/DC`);
                //   } else {
                //     let RRIP = document.indexPath.split("/");
                //     let lastIndex = RRIP[RRIP.length - 1];
                //     let SLI = lastIndex.split("_");
                //     let path = `/manager/${value}/${forms[value].indexPath}`;
                //     let NIP = RRIP.map((index) => index.split("_")[0]).join(
                //       "_"
                //     );
                //     console.log(SLI[1]);
                //     switch (SLI[1]) {
                //       case "I":
                //         if (RRIP.length === 4) {
                //           navigate(`${path}/SP/PP/IP`);
                //         } else {
                //           navigate(`${path}/SP/TBP/TR/TC/PP/IP`);
                //         }
                //         break;
                //       case "T":
                //         if (RRIP.length === 4) {
                //           navigate(`${path}/SP/PP/TP/0#${NIP}`);
                //         } else {
                //           navigate(`${path}/SP/TBP/TR/TC/PP/TP/0#${NIP}`);
                //         }
                //         break;
                //       case "P":
                //         if (RRIP.length === 3) {
                //           navigate(`${path}/SP/PP`);
                //         } else {
                //           navigate(`${path}/SP/TBP/TR/TC/PP`);
                //         }
                //         break;
                //       case "TC":
                //         navigate(`${path}/SP/TBP/TR/TC`);
                //         break;
                //       case "TR":
                //         navigate(`${path}/SP/TBP/TR`);
                //         break;
                //       case "TB":
                //         navigate(`${path}/SP/TBP`);
                //         break;
                //       case "S":
                //         navigate(`${path}/SP`);
                //         break;
                //       case "Doc":
                //         navigate(`${path}/DP`);
                //         break;
                //     }
                //   }
                //   // navigate(`/manager/${value}/${forms[value].indexPath}`);
                // }
                else {
                  let splitValue = value.split("_");
                  if (splitValue[splitValue.length - 1] !== "EF") {
                    navigate(`/manager/${value}`);
                  } else {
                    navigate(`/manager/EF/${value}`);
                  }
                }
              }}
              width={250}
            />
            {/* {document?.externalDependency.length > 0 ? (
              <Dropdown
                options={document.externalDependency}
                name="ED"
                target="option.name"
                // condition="option.role === 'Manager'"
                value={ED}
                click={(value) => {
                  setED(value === "" ? "" : value.name);
                  if (value !== "") {
                    navigate(`/manager/${value.name}`);
                  } else {
                    navigate(`/manager/${document.category}/DC`);
                  }
                }}
                style={
                  ED !== ""
                    ? { backgroundColor: "#035afc", color: "white" }
                    : null
                }
                width={250}
              />
            ) : null} */}
          </>
        ) : null}
        <div
          className={`flex gap-5 items-center w-5 ${
            currentPath === "DC" ? "border-b-2" : ""
          } border-orange-500 cursor-pointer`}
          onClick={() => navigate(`${baseURL}/DC`)}
        >
          <img src={DocumentSVG} className="w-full" />
        </div>
        <div
          className={`flex gap-5 items-center w-5 ${
            currentPath === "IC" ? "border-b-2" : ""
          } border-orange-500 cursor-pointer text-black`}
          onClick={() => navigate(`${baseURL}/IC`)}
        >
          <BsFillFileEarmarkImageFill className="w-full text-xl" />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
