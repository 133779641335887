import React, { useState } from "react";
import Input from "../../../../utils/Input";

import { useAddImageMutation } from "../../../../slices/imageApiSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ImageControls = () => {
  const [fileName, setFileName] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const [naturalWidth, setNaturalWidth] = useState();
  const [naturalHeight, setNaturalHeight] = useState();
  const [imgWidth, setImgWidth] = useState(500);
  const [imgHeight, setImgHeight] = useState(100);

  const { agency } = useSelector((state) => state.agency);

  const [addImage, {}] = useAddImageMutation();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);

      // Convert the image to Base64
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBase64Image(reader.result); // Set the Base64 string

        const img = new Image();
        img.src = reader.result; // Use the Base64 result for the src
        img.onload = () => {
          // Set the original image dimensions once the image is loaded
          setNaturalWidth(img.naturalWidth);
          setNaturalHeight(img.naturalHeight);
          setImgWidth(img.naturalWidth);
          setImgHeight(img.naturalHeight);
        };
      };
    }
  };

  const addImageHandler = async () => {
    try {
      const res = await addImage({
        name: fileName,
        image: base64Image,
        transformation: {
          width: parseFloat(imgWidth),
          height: parseFloat(imgHeight),
        },
        agency,
      }).unwrap();
      toast.success(res.message);
      setFileName("");
      setBase64Image("");
      setImgHeight(0);
      setImgWidth(0);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="psm">
      <h2 className="font-mono text-center pb-4">Image Controls</h2>
      <h2 className="font-mono pb-4">Only .jpg and .png files are accepted.</h2>
      <div className="border psm mb-4">
        <img
          src={base64Image}
          style={{ width: `${imgWidth}px`, height: `${imgHeight}px` }}
        />
      </div>
      <div className="flex gap-4">
        <div className="flex flex-col items-start justify-center pb-4">
          {/* Hidden input */}
          <input
            id="fileInput"
            type="file"
            accept=".jpg, .jpeg, .png"
            className="hidden" // Hide the default input
            onChange={handleFileChange}
          />

          {/* Custom button */}
          <label
            htmlFor="fileInput"
            className="cursor-pointer morphous bg-cyan-950 text-light-500 px-4 h-[40px] flex items-center"
          >
            {fileName ? `File: ${fileName}` : "Upload JPG/PNG File"}
          </label>
        </div>
      </div>
      {fileName ? (
        <div className="flex gap-4 items-end">
          <div>
            <h2 className="font-mono pb-2">Name</h2>
            <Input
              className="h-[40px] w-[300px]"
              type="text"
              value={fileName}
              change={(e) => setFileName(e.target.value)}
            />
          </div>
          <div>
            <h2 className="font-mono pb-2">Width</h2>
            <Input
              className="h-[40px] w-[100px]"
              type="number"
              value={imgWidth}
              change={(e) => {
                setImgWidth(e.target.value);
                setImgHeight(
                  (naturalHeight * (e.target.value / naturalWidth)).toFixed(0)
                );
              }}
            />
          </div>
          <div>
            <h2 className="font-mono pb-2">Height</h2>
            <Input
              className="h-[40px] w-[100px]"
              type="number"
              value={imgHeight}
              disabled={true}
            />
          </div>
          <button
            className="h-[40px] bg-cyan-950 text-light-500 px-4 morphous cursor-pointer"
            onClick={() => addImageHandler()}
          >
            Upload Image
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default ImageControls;
