import React, { useEffect, useState } from "react";
import Input from "../../../../utils/Input";
import Dropdown from "../../../../utils/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  // setDocs,
  addSection,
  setNumbering,
  // DocTable Properties
  setDocTableWidth,
  setDocTableMargins,
  setDocTableBorders,
  // DocRow Properties
  setDocRowHeight,
  // DocCell Properties
  setDocCellAlignment,
  setDocCellMargins,
  setDocCellBorders,
  // DocPara Properties
  setDocParaAlignment,
  setDocParaSpacing,
  // DocText Properties
  setDocTextFont,
  setDocTextFontSize,
} from "../../../../slices/docsSlice";
import { useGetNumberingQuery } from "../../../../slices/numberingApiSlice";
import { useLocation } from "react-router-dom";

import { switchWidthType } from "./CommFunctions";

const DocumentProperties = () => {
  const dispatch = useDispatch();
  const { docs, RRIP_DOC, NIP_DOC } = useSelector((state) => state.docs);
  const {
    fonts,
    fontSizes,
    alignments,
    cellAlignments,
    widthTypes,
    tableAlignments,
    isTrueOrFalse,
    headerFooterStyles,
    borderStyles,
    rowHeightRules,
  } = useSelector((state) => state.options);
  const [sectionName, setSectionName] = useState();

  const [title, setTitle] = useState();
  const [subject, setSubject] = useState();
  const [creator, setCreator] = useState();
  const [keywords, setKeywords] = useState();
  const [description, setDescription] = useState();

  // Section Properties

  // Table Properties
  const [tableWidthSize, setTableWidthSize] = useState();
  const [tableMarginLeft, setTableMarginLeft] = useState();
  const [tableMarginRight, setTableMarginRight] = useState();
  const [tableMarginTop, setTableMarginTop] = useState();
  const [tableMarginBottom, setTableMarginBottom] = useState();
  const [tableBorderLeftStyle, setTableBorderLeftStyle] = useState();
  const [tableBorderLeftColor, setTableBorderLeftColor] = useState();
  const [tableBorderLeftSize, setTableBorderLeftSize] = useState();
  const [tableBorderRightStyle, setTableBorderRightStyle] = useState();
  const [tableBorderRightColor, setTableBorderRightColor] = useState();
  const [tableBorderRightSize, setTableBorderRightSize] = useState();
  const [tableBorderTopStyle, setTableBorderTopStyle] = useState();
  const [tableBorderTopColor, setTableBorderTopColor] = useState();
  const [tableBorderTopSize, setTableBorderTopSize] = useState();
  const [tableBorderBottomStyle, setTableBorderBottomStyle] = useState();
  const [tableBorderBottomColor, setTableBorderBottomColor] = useState();
  const [tableBorderBottomSize, setTableBorderBottomSize] = useState();
  // Row Properties
  const [heightValue, setHeightValue] = useState();
  const [heightRule, setHeightRule] = useState();
  // Cell Properties
  const [verticalAlign, setVerticalAlign] = useState();
  const [cellMarginLeft, setCellMarginLeft] = useState();
  const [cellMarginRight, setCellMarginRight] = useState();
  const [cellMarginTop, setCellMarginTop] = useState();
  const [cellMarginBottom, setCellMarginBottom] = useState();
  const [cellBorderLeftStyle, setCellBorderLeftStyle] = useState();
  const [cellBorderLeftColor, setCellBorderLeftColor] = useState();
  const [cellBorderLeftSize, setCellBorderLeftSize] = useState();
  const [cellBorderLeftSpace, setCellBorderLeftSpace] = useState();
  const [cellBorderRightStyle, setCellBorderRightStyle] = useState();
  const [cellBorderRightColor, setCellBorderRightColor] = useState();
  const [cellBorderRightSize, setCellBorderRightSize] = useState();
  const [cellBorderRightSpace, setCellBorderRightSpace] = useState();
  const [cellBorderTopStyle, setCellBorderTopStyle] = useState();
  const [cellBorderTopColor, setCellBorderTopColor] = useState();
  const [cellBorderTopSize, setCellBorderTopSize] = useState();
  const [cellBorderTopSpace, setCellBorderTopSpace] = useState();
  const [cellBorderBottomStyle, setCellBorderBottomStyle] = useState();
  const [cellBorderBottomColor, setCellBorderBottomColor] = useState();
  const [cellBorderBottomSize, setCellBorderBottomSize] = useState();
  const [cellBorderBottomSpace, setCellBorderBottomSpace] = useState();
  // Para Properties
  const [alignment, setAlignment] = useState();
  const [spacingBefore, setSpacingBefore] = useState();
  const [spacingAfter, setSpacingAfter] = useState();
  const [spacingLine, setSpacingLine] = useState();
  // Text Properties
  const [font, setFont] = useState();
  const [fontSize, setFontSize] = useState();

  const { data: numberingTypes, refetch, isLoading } = useGetNumberingQuery();

  let dynName = "docs";
  NIP_DOC.split("_").forEach((item, i) => {
    dynName =
      dynName +
      `['${item}'].${i === NIP_DOC.split("_").length - 1 ? "name" : "ele"}`;
  });

  useEffect(() => {
    docs.map((doc) => {
      // Table Properties
      setTableWidthSize(doc.properties.table?.width.size);
      setTableMarginLeft(doc.properties.table?.margins.left);
      setTableMarginRight(doc.properties.table?.margins.right);
      setTableMarginTop(doc.properties.table?.margins.top);
      setTableMarginBottom(doc.properties.table?.margins.bottom);
      setTableBorderLeftColor(doc.properties.table?.borders.left.color);
      setTableBorderLeftSize(doc.properties.table?.borders.left.size);
      setTableBorderLeftStyle(doc.properties.table?.borders.left.style);
      setTableBorderRightColor(doc.properties.table?.borders.right.color);
      setTableBorderRightSize(doc.properties.table?.borders.right.size);
      setTableBorderRightStyle(doc.properties.table?.borders.right.style);
      setTableBorderTopColor(doc.properties.table?.borders.top.color);
      setTableBorderTopSize(doc.properties.table?.borders.top.size);
      setTableBorderTopStyle(doc.properties.table?.borders.top.style);
      setTableBorderBottomColor(doc.properties.table?.borders.bottom.color);
      setTableBorderBottomSize(doc.properties.table?.borders.bottom.size);
      setTableBorderBottomStyle(doc.properties.table?.borders.bottom.style);

      // Row Properties
      setHeightValue(doc.properties.row?.height?.value);
      setHeightRule(doc.properties.row?.height?.rule);

      // Cell Properties
      setVerticalAlign(doc.properties.cell?.verticalAlign);
      setCellMarginLeft(doc.properties.cell?.margins?.left);
      setCellMarginRight(doc.properties.cell?.margins?.right);
      setCellMarginTop(doc.properties.cell?.margins?.top);
      setCellMarginBottom(doc.properties.cell?.margins?.bottom);
      setCellBorderLeftColor(doc.properties.cell?.borders.left.color);
      setCellBorderLeftSize(doc.properties.cell?.borders.left.size);
      setCellBorderLeftSpace(doc.properties.cell?.borders.left.space);
      setCellBorderLeftStyle(doc.properties.cell?.borders.left.style);
      setCellBorderRightColor(doc.properties.cell?.borders.right.color);
      setCellBorderRightSize(doc.properties.cell?.borders.right.size);
      setCellBorderRightSpace(doc.properties.cell?.borders.right.space);
      setCellBorderRightStyle(doc.properties.cell?.borders.right.style);
      setCellBorderTopColor(doc.properties.cell?.borders.top.color);
      setCellBorderTopSize(doc.properties.cell?.borders.top.size);
      setCellBorderTopSpace(doc.properties.cell?.borders.top.space);
      setCellBorderTopStyle(doc.properties.cell?.borders.top.style);
      setCellBorderBottomColor(doc.properties.cell?.borders.bottom.color);
      setCellBorderBottomSize(doc.properties.cell?.borders.bottom.size);
      setCellBorderBottomSpace(doc.properties.cell?.borders.bottom.space);
      setCellBorderBottomStyle(doc.properties.cell?.borders.bottom.style);

      // Para Properties
      setAlignment(doc.properties.para?.alignment);
      setSpacingBefore(doc.properties.para?.spacing.before);
      setSpacingAfter(doc.properties.para?.spacing.after);

      // Text Properties
      setFont(doc.properties.text?.font);
      setFontSize(doc.properties.text?.fontSize);
    });
  }, [docs, NIP_DOC]);

  return (
    <div className="psm">
      <div>
        <h1 className="text-center pb-4">
          Document Properties [{eval(dynName)}]
        </h1>
        <div className="flex gap-4 pb-4">
          <Input
            className="h-[40px] w-[250px]"
            value={sectionName}
            change={(e) => setSectionName(e.target.value)}
            placeholder="Enter Section Name"
          />
          <button
            className="px-3 py-1 bg-cyan-950 text-light-500"
            onClick={() => {
              dispatch(
                addSection({
                  name: sectionName,
                  path: RRIP_DOC,
                })
              );
              setSectionName("");
            }}
          >
            Add Section
          </button>
        </div>
        <div className="flex flex-wrap items-end gap-4 pb-4">
          <div>
            <h2 className="pb-1">Title</h2>
            <Input
              type="text"
              className="h-[40px] w-[200px]"
              value={title}
              change={(e) => setTitle(e.target.value)}
              blur={(e) => {}}
            />
          </div>
          <div>
            <h2 className="pb-1">Subject</h2>
            <Input
              type="text"
              className="h-[40px] w-[200px]"
              value={subject}
              change={(e) => setSubject(e.target.value)}
              blur={(e) => {}}
            />
          </div>
          <div>
            <h2 className="pb-1">Creator</h2>
            <Input
              type="text"
              className="h-[40px] w-[200px]"
              value={creator}
              change={(e) => setCreator(e.target.value)}
              blur={(e) => {}}
            />
          </div>
          <div>
            <h2 className="pb-1">Keywords</h2>
            <Input
              type="text"
              className="h-[40px] w-[200px]"
              value={keywords}
              change={(e) => setKeywords(e.target.value)}
              blur={(e) => {}}
            />
          </div>
          <div>
            <h2 className="pb-1">Description</h2>
            <Input
              type="text"
              className="h-[40px] w-[200px]"
              value={description}
              change={(e) => setDescription(e.target.value)}
              blur={(e) => {}}
            />
          </div>
          <div>
            <button
              className="w-[200px] h-[40px] bg-cyan-950 text-light-500"
              onClick={() => {
                dispatch(
                  setNumbering({
                    doc: RRIP_DOC,
                    value: numberingTypes,
                  })
                );
              }}
            >
              Update Bullets
            </button>
          </div>
        </div>
      </div>
      {/* <div>
        <h1 className="pb-4">Section Properties</h1>
      </div> */}
      <div>
        <h1 className="mb-1 bg-blue-500 pl-2">Table Properties</h1>
        <div className="flex flex-wrap">
          <div>
            <h2 className="pb-1">Set Width Size</h2>
            <Input
              className="h-[40px] w-[150px]"
              value={tableWidthSize}
              type="text"
              blur={(e) => {
                dispatch(
                  setDocTableWidth({
                    loc: "size",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                );
              }}
              change={(e) => setTableWidthSize(e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-4 pb-4">
          <div>
            <h2 className="pb-1">Left Margin</h2>
            <Input
              className="h-[40px] w-[150px]"
              value={tableMarginLeft}
              placeholder="left margin"
              blur={(e) =>
                dispatch(
                  setDocTableMargins({
                    loc: "left",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setTableMarginLeft(e.target.value)}
              type="text"
            />
          </div>
          <div>
            <h2 className="pb-1">Right Margin</h2>
            <Input
              className="h-[40px] w-[150px]"
              value={tableMarginRight}
              placeholder="right margin"
              blur={(e) =>
                dispatch(
                  setDocTableMargins({
                    loc: "right",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setTableMarginRight(e.target.value)}
              type="text"
            />
          </div>
          <div>
            <h2 className="pb-1">Top Margin</h2>
            <Input
              className="h-[40px] w-[150px]"
              value={tableMarginTop}
              placeholder="top margin"
              blur={(e) =>
                dispatch(
                  setDocTableMargins({
                    loc: "top",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setTableMarginTop(e.target.value)}
              type="text"
            />
          </div>
          <div>
            <h2 className="pb-1">Bottom Margin</h2>
            <Input
              className="h-[40px] w-[150px]"
              value={tableMarginBottom}
              placeholder="bottom margin"
              blur={(e) =>
                dispatch(
                  setDocTableMargins({
                    loc: "bottom",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setTableMarginBottom(e.target.value)}
              type="text"
            />
          </div>
        </div>
        <div className="pb-4">
          <div className="flex gap-4 pb-1">
            <div>
              <h2 className="pb-1">TB Left style</h2>
              <Dropdown
                width={250}
                height={40}
                options={borderStyles}
                target="option"
                value={tableBorderLeftStyle}
                click={(value) => {
                  dispatch(
                    setDocTableBorders({
                      direction: "left",
                      loc: "style",
                      doc: RRIP_DOC,
                      value,
                    })
                  );
                }}
              />
            </div>
            <div>
              <h2 className="pb-1">TB Left color</h2>
              <Input
                className="h-[40px] w-[100px] p-0 shadow-none"
                value={tableBorderLeftColor}
                blur={(e) => {
                  dispatch(
                    setDocTableBorders({
                      direction: "left",
                      loc: "color",
                      doc: RRIP_DOC,
                      value: e.target.value,
                    })
                  );
                }}
                change={(e) => setTableBorderLeftColor(e.target.value)}
                type="color"
              />
            </div>
            <div>
              <h2 className="pb-1">TB Left Size</h2>
              <Input
                className="h-[40px] w-[100px]"
                value={tableBorderLeftSize}
                type="number"
                blur={(e) => {
                  dispatch(
                    setDocTableBorders({
                      direction: "left",
                      loc: "size",
                      doc: RRIP_DOC,
                      value: e.target.value,
                    })
                  );
                }}
                change={(e) => setTableBorderLeftSize(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-4 pb-1">
            <div>
              <h2 className="pb-1">TB Right style</h2>
              <Dropdown
                width={250}
                height={40}
                options={borderStyles}
                target="option"
                value={tableBorderRightStyle}
                click={(value) => {
                  dispatch(
                    setDocTableBorders({
                      direction: "right",
                      loc: "style",
                      doc: RRIP_DOC,
                      value,
                    })
                  );
                }}
              />
            </div>
            <div>
              <h2 className="pb-1">TB Right color</h2>
              <Input
                className="h-[40px] w-[100px] p-0 shadow-none"
                value={tableBorderRightColor}
                blur={(e) => {
                  dispatch(
                    setDocTableBorders({
                      direction: "right",
                      loc: "color",
                      doc: RRIP_DOC,
                      value: e.target.value,
                    })
                  );
                }}
                change={(e) => setTableBorderRightColor(e.target.value)}
                type="color"
              />
            </div>
            <div>
              <h2 className="pb-1">TB Right Size</h2>
              <Input
                className="h-[40px] w-[100px]"
                value={tableBorderRightSize}
                type="number"
                blur={(e) => {
                  dispatch(
                    setDocTableBorders({
                      direction: "right",
                      loc: "size",
                      doc: RRIP_DOC,
                      value: e.target.value,
                    })
                  );
                }}
                change={(e) => setTableBorderRightSize(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-4 pb-1">
            <div>
              <h2 className="pb-1">TB Top style</h2>
              <Dropdown
                width={250}
                height={40}
                options={borderStyles}
                target="option"
                value={tableBorderTopStyle}
                click={(value) => {
                  dispatch(
                    setDocTableBorders({
                      direction: "top",
                      loc: "style",
                      doc: RRIP_DOC,
                      value,
                    })
                  );
                }}
              />
            </div>
            <div>
              <h2 className="pb-1">TB Top color</h2>
              <Input
                className="h-[40px] w-[100px] p-0 shadow-none"
                value={tableBorderTopColor}
                blur={(e) => {
                  dispatch(
                    setDocTableBorders({
                      direction: "top",
                      loc: "color",
                      doc: RRIP_DOC,
                      value: e.target.value,
                    })
                  );
                }}
                change={(e) => setTableBorderTopColor(e.target.value)}
                type="color"
              />
            </div>
            <div>
              <h2 className="pb-1">TB Top Size</h2>
              <Input
                className="h-[40px] w-[100px]"
                value={tableBorderTopSize}
                type="number"
                blur={(e) => {
                  dispatch(
                    setDocTableBorders({
                      direction: "top",
                      loc: "size",
                      doc: RRIP_DOC,
                      value: e.target.value,
                    })
                  );
                }}
                change={(e) => setTableBorderTopSize(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-4 pb-1">
            <div>
              <h2 className="pb-1">TB Bottom style</h2>
              <Dropdown
                width={250}
                height={40}
                options={borderStyles}
                target="option"
                value={tableBorderBottomStyle}
                click={(value) => {
                  dispatch(
                    setDocTableBorders({
                      direction: "bottom",
                      loc: "style",
                      doc: RRIP_DOC,
                      value,
                    })
                  );
                }}
              />
            </div>
            <div>
              <h2 className="pb-1">TB Bottom color</h2>
              <Input
                className="h-[40px] w-[100px] p-0 shadow-none"
                value={tableBorderBottomColor}
                blur={(e) => {
                  dispatch(
                    setDocTableBorders({
                      direction: "bottom",
                      loc: "color",
                      doc: RRIP_DOC,
                      value: e.target.value,
                    })
                  );
                }}
                change={(e) => setTableBorderBottomColor(e.target.value)}
                type="color"
              />
            </div>
            <div>
              <h2 className="pb-1">TB Bottom Size</h2>
              <Input
                className="h-[40px] w-[100px]"
                value={tableBorderBottomSize}
                type="number"
                blur={(e) => {
                  dispatch(
                    setDocTableBorders({
                      direction: "bottom",
                      loc: "size",
                      doc: RRIP_DOC,
                      value: e.target.value,
                    })
                  );
                }}
                change={(e) => setTableBorderBottomSize(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1 className="mb-1 bg-green-500 pl-2">Row Properties</h1>
        <div className="pb-4">
          <h1 className="pb-1 font-mono">
            Height Units [mm, cm, in, pt, pc, pi]
          </h1>
          <div className="flex flex-wrap gap-x-5 gap-y-3 mt-1">
            <div className="mb-2">
              <p className="mb-2">Height</p>
              <Input
                className="h-[40px] w-[150px]"
                value={heightValue}
                type="text"
                blur={(e) => {
                  dispatch(
                    setDocRowHeight({
                      loc: "value",
                      doc: RRIP_DOC,
                      value: e.target.value,
                    })
                  );
                }}
                change={(e) => setHeightValue(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <p className="mb-2">Height Rule</p>
              <Dropdown
                width={150}
                height={40}
                value={heightRule}
                options={rowHeightRules}
                target="option"
                name="RHR"
                placeholder="height rule"
                click={(value) => {
                  dispatch(
                    setDocRowHeight({
                      loc: "rule",
                      doc: RRIP_DOC,
                      value,
                    })
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1 className="mb-1 bg-yellow-500 pl-2">Cell Properties</h1>
        <div className="flex flex-col">
          <div className="flex flex-wrap">
            <div>
              <h2 className="pb-1">Vertical Alignment</h2>
              <Dropdown
                className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                value={verticalAlign}
                options={cellAlignments}
                target="option"
                placeholder="alignment"
                width={150}
                height={40}
                click={(value) => {
                  dispatch(
                    setDocCellAlignment({
                      doc: RRIP_DOC,
                      value,
                    })
                  );
                }}
              />
            </div>
          </div>
          <div className="pb-4">
            <div className="flex gap-4 pb-4">
              <div>
                <h2 className="pb-1">Left Margin</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  value={cellMarginLeft}
                  placeholder="left margin"
                  blur={(e) =>
                    dispatch(
                      setDocCellMargins({
                        loc: "left",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    )
                  }
                  change={(e) => setCellMarginLeft(e.target.value)}
                  type="text"
                />
              </div>
              <div>
                <h2 className="pb-1">Right Margin</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  value={cellMarginRight}
                  placeholder="right margin"
                  blur={(e) =>
                    dispatch(
                      setDocCellMargins({
                        loc: "right",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    )
                  }
                  change={(e) => setCellMarginRight(e.target.value)}
                  type="text"
                />
              </div>
              <div>
                <h2 className="pb-1">Top Margin</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  value={cellMarginTop}
                  placeholder="top margin"
                  blur={(e) =>
                    dispatch(
                      setDocCellMargins({
                        loc: "top",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    )
                  }
                  change={(e) => setCellMarginTop(e.target.value)}
                  type="text"
                />
              </div>
              <div>
                <h2 className="pb-1">Bottom Margin</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  value={cellMarginBottom}
                  placeholder="bottom margin"
                  blur={(e) =>
                    dispatch(
                      setDocCellMargins({
                        loc: "bottom",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    )
                  }
                  change={(e) => setCellMarginBottom(e.target.value)}
                  type="text"
                />
              </div>
            </div>
            <div className="flex gap-4 pb-1">
              <div>
                <h2 className="pb-1">CB Left style</h2>
                <Dropdown
                  width={250}
                  height={40}
                  options={borderStyles}
                  target="option"
                  value={cellBorderLeftStyle}
                  click={(value) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "left",
                        loc: "style",
                        doc: RRIP_DOC,
                        value,
                      })
                    );
                  }}
                />
              </div>
              <div>
                <h2 className="pb-1">CB Left color</h2>
                <Input
                  className="h-[40px] w-[100px] p-0 shadow-none"
                  value={cellBorderLeftColor}
                  blur={(e) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "left",
                        loc: "color",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setCellBorderLeftColor(e.target.value)}
                  type="color"
                />
              </div>
              <div>
                <h2 className="pb-1">CB Left Size</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={cellBorderLeftSize}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "left",
                        loc: "size",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setCellBorderLeftSize(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">CB Left Space</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={cellBorderLeftSpace}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "left",
                        loc: "space",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setCellBorderLeftSpace(e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-4 pb-1">
              <div>
                <h2 className="pb-1">CB Right style</h2>
                <Dropdown
                  width={250}
                  height={40}
                  options={borderStyles}
                  target="option"
                  value={cellBorderRightStyle}
                  click={(value) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "right",
                        loc: "style",
                        doc: RRIP_DOC,
                        value,
                      })
                    );
                  }}
                />
              </div>
              <div>
                <h2 className="pb-1">CB Right color</h2>
                <Input
                  className="h-[40px] w-[100px] p-0 shadow-none"
                  value={cellBorderRightColor}
                  blur={(e) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "right",
                        loc: "color",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setCellBorderRightColor(e.target.value)}
                  type="color"
                />
              </div>
              <div>
                <h2 className="pb-1">CB Right Size</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={cellBorderRightSize}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "right",
                        loc: "size",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setCellBorderRightSize(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">CB Right Space</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={cellBorderRightSpace}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "right",
                        loc: "space",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setCellBorderRightSpace(e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-4 pb-1">
              <div>
                <h2 className="pb-1">CB Top style</h2>
                <Dropdown
                  width={250}
                  height={40}
                  options={borderStyles}
                  target="option"
                  value={cellBorderTopStyle}
                  click={(value) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "top",
                        loc: "style",
                        doc: RRIP_DOC,
                        value,
                      })
                    );
                  }}
                />
              </div>
              <div>
                <h2 className="pb-1">CB Top color</h2>
                <Input
                  className="h-[40px] w-[100px] p-0 shadow-none"
                  value={cellBorderTopColor}
                  blur={(e) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "top",
                        loc: "color",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setCellBorderTopColor(e.target.value)}
                  type="color"
                />
              </div>
              <div>
                <h2 className="pb-1">CB Top Size</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={cellBorderTopSize}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "top",
                        loc: "size",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setCellBorderTopSize(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">CB Top Space</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={cellBorderTopSpace}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "top",
                        loc: "space",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setCellBorderTopSpace(e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-4 pb-1">
              <div>
                <h2 className="pb-1">CB Bottom style</h2>
                <Dropdown
                  width={250}
                  height={40}
                  options={borderStyles}
                  target="option"
                  value={cellBorderBottomStyle}
                  click={(value) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "bottom",
                        loc: "style",
                        doc: RRIP_DOC,
                        value,
                      })
                    );
                  }}
                />
              </div>
              <div>
                <h2 className="pb-1">CB Bottom color</h2>
                <Input
                  className="h-[40px] w-[100px] p-0 shadow-none"
                  value={cellBorderBottomColor}
                  blur={(e) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "bottom",
                        loc: "color",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setCellBorderBottomColor(e.target.value)}
                  type="color"
                />
              </div>
              <div>
                <h2 className="pb-1">CB Bottom Size</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={cellBorderBottomSize}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "bottom",
                        loc: "size",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setCellBorderBottomSize(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">CB Bottom Space</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={cellBorderBottomSpace}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setDocCellBorders({
                        direction: "bottom",
                        loc: "space",
                        doc: RRIP_DOC,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setCellBorderBottomSpace(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1 className="mb-1 bg-teal-500 pl-2">Para Properties</h1>
        <div className="flex flex-wrap gap-4 pb-4">
          <div>
            <h2 className="pb-1">Alignment</h2>
            <Dropdown
              value={alignment}
              options={alignments}
              target="option"
              width={100}
              height={40}
              click={(value) => {
                dispatch(
                  setDocParaAlignment({
                    doc: RRIP_DOC,
                    value,
                  })
                );
              }}
            />
          </div>
          <div>
            <h2 className="pb-1">Spacing Before</h2>
            <Input
              className="h-[40px] w-[100px]"
              value={spacingBefore}
              blur={(e) =>
                dispatch(
                  setDocParaSpacing({
                    loc: "before",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setSpacingBefore(e.target.value)}
              type="text"
            />
          </div>
          <div>
            <h2 className="pb-1">Spacing After</h2>
            <Input
              className="h-[40px] w-[100px]"
              value={spacingAfter}
              blur={(e) =>
                dispatch(
                  setDocParaSpacing({
                    loc: "after",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setSpacingAfter(e.target.value)}
              type="text"
            />
          </div>
        </div>
      </div>
      <div>
        <h1 className="mb-1 bg-purple-500 pl-2">Text Properties</h1>
        <div className="flex flex-wrap gap-4 pb-4">
          <div>
            <h2 className="pb-1">Text Font</h2>
            <Dropdown
              className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
              value={font}
              options={fonts}
              target="option"
              width={200}
              height={40}
              click={(value) => {
                dispatch(
                  setDocTextFont({
                    doc: RRIP_DOC,
                    value,
                  })
                );
              }}
            />
          </div>
          <div>
            <h2 className="pb-1">Text Size</h2>
            <Input
              className="h-[40px] w-[100px]"
              value={fontSize}
              type="text"
              blur={(e) =>
                dispatch(
                  setDocTextFontSize({
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setFontSize(e.target.value)}
            />
          </div>
        </div>
      </div>
      {/* 
      

      <div className="flex gap-4 pb-6">
        <div>
          <h2 className="pb-1">Table Border</h2>
          <Dropdown
            value={tableBorder}
            options={isTrueOrFalse}
            target="option"
            click={(value) => {
              dispatch(setDocTabBorder({ doc: RRIP_DOC, value }));
            }}
            width={150}
            height={40}
            style={
              tableBorder === "true"
                ? { backgroundColor: "#083344", color: "#f8f9fa" }
                : {}
            }
          />
        </div>
      </div>
      <div className="mb-2 flex gap-2">
        <div className="min-w-[50px]">
          <p className="mb-2">Table Border</p>
        </div>
      </div>
      <div className="flex gap-2">
        <div className="mb-2">
          <p className="mb-2">Set Width Type</p>
          <Dropdown
            width={200}
            height={40}
            value={widthType}
            options={widthTypes}
            target="option"
            placeholder="width type"
            click={(value) => {
              dispatch(
                setDocTabWidth({
                  loc: "type",
                  doc: RRIP_DOC,
                  value,
                })
              );
            }}
          />
        </div>
        <div className="mb-2">
          <p className="mb-2">Set Width Size</p>
          <Input
            className="h-[40px] w-[150px]"
            value={width}
            type="number"
            blur={(e) => {
              dispatch(
                setDocTabWidth({
                  loc: "size",
                  doc: RRIP_DOC,
                  value: e.target.value,
                })
              );
            }}
            change={(e) => setWidth(e.target.value)}
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-x-5 gap-y-3 mt-1">
        <div className="mb-2">
          <p className="mb-2">Doc Tab Ver Alignment</p>
          <Dropdown
            width={200}
            height={40}
            value={verticalAlignment}
            options={cellAlignments}
            target="option"
            placeholder="alignment"
            click={(value) => {
              dispatch(
                setDocVerAlignment({
                  doc: RRIP_DOC,
                  value,
                })
              );
            }}
          />
        </div>
        <div className="mb-2">
          <p className="mb-2">Doc Hor Alignment</p>
          <Dropdown
            width={200}
            height={40}
            value={horizontalAlignment}
            options={alignments}
            target="option"
            placeholder="alignment"
            click={(value) => {
              dispatch(
                setDocHorAlignment({
                  doc: RRIP_DOC,
                  value,
                })
              );
            }}
          />
        </div>
        <div className="mb-2">
          <p className="mb-2">Doc Font</p>
          <Dropdown
            width={200}
            height={40}
            value={font}
            options={fonts}
            target="option"
            click={(value) => {
              dispatch(
                setDocFont({
                  doc: RRIP_DOC,
                  value,
                })
              );
            }}
          />
        </div>
        <div className="mb-2">
          <p className="mb-2">Doc Font Size</p>
          <Input
            className="h-[40px] w-[150px]"
            value={fontSize}
            type="text"
            blur={(e) =>
              dispatch(
                setDocFontSize({
                  doc: RRIP_DOC,
                  value: e.target.value,
                })
              )
            }
            change={(e) => setFontSize(e.target.value)}
          />
        </div>
      </div>

      <div className="mb-2">
        <p className="mb-2">Set Default Spacing for Paragraphs</p>
        <div className="flex gap-2 mb-2">
          <div className="min-w-[50px]">
            <p className="mb-2">Before</p>
            <Input
              className="h-[40px] w-[150px]"
              value={spacingBefore}
              blur={(e) =>
                dispatch(
                  setSpacing({
                    doc: RRIP_DOC,
                    loc: "before",
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setSpacingBefore(e.target.value)}
              type="text"
            />
          </div>
          <div className="min-w-[50px]">
            <p className="mb-2">After</p>
            <Input
              className="h-[40px] w-[150px]"
              value={spacingAfter}
              blur={(e) =>
                dispatch(
                  setSpacing({
                    doc: RRIP_DOC,
                    loc: "after",
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setSpacingAfter(e.target.value)}
              type="text"
            />
          </div>
          <div className="min-w-[50px]">
            <p className="mb-2">Line</p>
            <Input
              className="h-[40px] w-[150px]"
              value={spacingLine}
              blur={(e) =>
                dispatch(
                  setSpacing({
                    doc: RRIP_DOC,
                    loc: "line",
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setSpacingLine(e.target.value)}
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="mb-2">
        <p className="mb-2">Set Doc Cell Margin</p>
        <div className="flex flex-wrap gap-2 mb-2">
          <div className="min-w-[50px]">
            <p className="mb-2">Left</p>
            <Input
              className="h-[40px] w-[150px]"
              value={marginLeft}
              blur={(e) =>
                dispatch(
                  setDocCellMargin({
                    loc: "left",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setMarginLeft(e.target.value)}
              type="text"
            />
          </div>
          <div className="min-w-[50px]">
            <p className="mb-2">Right</p>
            <Input
              className="h-[40px] w-[150px]"
              value={marginRight}
              blur={(e) =>
                dispatch(
                  setDocCellMargin({
                    loc: "right",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setMarginRight(e.target.value)}
              type="text"
            />
          </div>
          <div className="min-w-[50px]">
            <p className="mb-2">Top</p>
            <Input
              className="h-[40px] w-[150px]"
              value={marginTop}
              blur={(e) =>
                dispatch(
                  setDocCellMargin({
                    loc: "top",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setMarginTop(e.target.value)}
              type="text"
            />
          </div>
          <div className="min-w-[50px]">
            <p className="mb-2">Bottom</p>
            <Input
              className="h-[40px] w-[150px]"
              value={marginBottom}
              blur={(e) =>
                dispatch(
                  setDocCellMargin({
                    loc: "bottom",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setMarginBottom(e.target.value)}
              type="text"
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default DocumentProperties;
