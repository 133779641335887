import React, { useState, useEffect, useRef } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { autocompletion } from "@codemirror/autocomplete";

import { javascript } from "@codemirror/lang-javascript";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";

import { useDispatch, useSelector } from "react-redux";
import { updateJSFunc } from "../slices/docsSlice";

const Editor = ({ value, path }) => {
  const dispatch = useDispatch();
  const { indexPath, name, category, docs } = useSelector(
    (state) => state.docs
  );
  const { document: mainFile } = useSelector((state) => state.document);
  const { forms } = useSelector((state) => state.forms);

  const [editorValue, setEditorValue] = useState(value);

  // console.log(value);

  useEffect(() => {
    // console.log(value);
    if (!localStorage.getItem("func")) {
      setEditorValue(value);
    } else {
      let IP = localStorage.getItem("indexPath");
      let func = localStorage.getItem("func");
      let CATEGORY = localStorage.getItem("category");
      let NAME = localStorage.getItem("name");
      if (indexPath === IP && category === CATEGORY && name === NAME) {
        setEditorValue(func);
      }
    }
  }, [value]);

  useEffect(() => {
    let IP = localStorage.getItem("indexPath");
    let func = localStorage.getItem("func");
    let CATEGORY = localStorage.getItem("category");
    let NAME = localStorage.getItem("name");
    if (indexPath === IP && category === CATEGORY && name === NAME) {
      setEditorValue(func);
    }
  }, [indexPath, category, name, mainFile, forms, docs]);

  return (
    <CodeMirror
      value={editorValue}
      theme={vscodeDark}
      extensions={[javascript({ jsx: true }), autocompletion()]}
      onChange={(value) => {
        localStorage.setItem("func", value);
        localStorage.setItem("indexPath", indexPath);
        localStorage.setItem("category", category);
        localStorage.setItem("name", name);
        setEditorValue(value);
        // dispatch(updateJSFunc({ path, value: editorValue }));
      }}
      onBlur={() => {
        dispatch(updateJSFunc({ path, value: editorValue }));
      }}
    />
  );
};

export default Editor;
