import { getRowStyle } from "./Styles/rowStyle";
import DeleteElement from "../Elements/DeleteElement";
import { useDispatch, useSelector } from "react-redux";
import { setIndexPath } from "../../../../slices/docsSlice";
import CreateCell from "./Cell";

const CreateRow = ({ row, index, loc, table, sec, doc, baseURL, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TR, NIP_TR, dev } = useSelector((state) => state.docs);
  const rowStyles = getRowStyle({
    rowProps: row?.properties,
    docProps: doc?.properties,
  });
  const splitIndex = index.split("_");
  const lastIndex = splitIndex[splitIndex.length - 1];
  return (
    <tr key={index} id={`${index}`} style={rowStyles} className="relative">
      {dev ? (
        <span className="text-cyan-950 text-[10pt] rounded-full absolute w-[15px] h-[15px] right-0 left-[-20px] top-0 bottom-0 flex items-center justify-center">
          {lastIndex}
        </span>
      ) : null}
      {row?.ele?.map((cell, i1) => {
        return (
          <CreateCell
            cell={cell}
            index={`${index}_${i1}`}
            loc={`${loc}/${i1}_TC`}
            row={row}
            table={table}
            sec={sec}
            doc={doc}
            baseURL={baseURL}
            SIPHF={SIPHF}
          />
        );
      })}
    </tr>
  );
};

export default CreateRow;
