import React from "react";

const Table = ({ children, style }) => {
  return (
    <div className="overflow-x-auto" style={style}>
      <table className="min-w-full bg-white border border-gray-200">
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default Table;
