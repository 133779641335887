import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../../components/Navbar";

const DocumentLayout = () => {
  return (
    <section className="h-full">
      <Navbar />
      <section className="document-layout-body">
        <Outlet />
      </section>
    </section>
  );
};

export default DocumentLayout;
