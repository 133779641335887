import React, { useState, useEffect } from "react";
import { PiSignInBold, PiSignOutBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useLogoutMutation } from "../slices/userApiSlice";
import { logout } from "../slices/authSlice";
import { FaBell } from "react-icons/fa";
import { CgMenuGridR } from "react-icons/cg";

import { useGetAgencyMutation } from "../slices/agencyApiSlice";
import { setAgency } from "../slices/agency";
import { resetDocs } from "../slices/docsSlice";
import { resetDocument } from "../slices/documentSlice";
import { resetForms } from "../slices/formsSlice";
import { resetInternalDependency } from "../slices/internalDependencySlice";
import { resetAgencies } from "../slices/agencies";
import { resetAgency } from "../slices/agency";
import { resetCategories } from "../slices/categories";
import { resetElementName } from "../slices/elementSlice";
import { toast } from "react-toastify";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const { agency } = useSelector((state) => state.agency);
  const [show, setShow] = useState(false);
  const { document } = useSelector((state) => state.document);
  const { docs, category, indexPath } = useSelector((state) => state.docs);
  const { userForms, forms } = useSelector((state) => state.forms);
  const { listIDS, internalDependency } = useSelector(
    (state) => state.internalDependency
  );
  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);

  const [logoutApiCall] = useLogoutMutation();

  const { pathname } = useLocation();

  const logoutHandler = async () => {
    setShow(false);
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate("/SignIn");
    } catch (err) {
      toast.error(err);
    }
  };

  const [getAgency, { isLoading: loadingAgency }] = useGetAgencyMutation();

  useEffect(() => {
    if (userInfo?.agency) {
      const fetchAgency = async () => {
        const agency = await getAgency({
          agencyid: userInfo.agency,
        }).unwrap();
        dispatch(setAgency(agency.agency));
      };

      fetchAgency();
    }
  }, [userInfo, getAgency, dispatch]);

  useEffect(() => {
    if (isReadyToNavigate) {
      if (userInfo.role === "User") {
        navigate("/DashBoard");
      } else if (userInfo.role === "Manager") {
        navigate("/manager/ManagerDashBoard");
      }
    }
  }, [isReadyToNavigate]);

  useEffect(() => {
    if (!document && !internalDependency && !userForms && !forms && !listIDS) {
      setIsReadyToNavigate(true);
    } else {
      setIsReadyToNavigate(false);
    }
  }, [internalDependency, listIDS, userForms, forms, document, docs]);

  return (
    <div className="box p-2 psm-x justify-between border-b">
      <div
        className="cursor-pointer py-1 px-2 font-courier border border-secondary font-bold"
        onClick={() => {
          dispatch(resetDocs());
          dispatch(resetDocument());
          dispatch(resetInternalDependency());
          dispatch(resetForms());
          dispatch(resetAgencies());
          dispatch(resetAgency());
          dispatch(resetCategories());
          dispatch(resetElementName());
        }}
      >
        <h3>{agency ? agency : "Doc Creator"}</h3>
      </div>
      <div className="box gap-5">
        {userInfo ? (
          <>
            {userInfo.role === "Admin" || userInfo.role === "Manager" ? (
              <div
                className="relative w-[20px] h-[20px] flex justify-between items-center cursor-pointer"
                onClick={() => {
                  if (userInfo.role === "Admin") {
                    navigate("/admin/managers/1");
                  } else {
                    navigate("/manager/CU");
                  }
                }}
              >
                <FaBell />
                {/* {userInfo.passwordRequest.length > 0 ? (
                  <span className="absolute w-[15px] h-[15px] right-[-6px] top-[10px] bg-accept rounded-full flex justify-center items-center text-center text-xs">
                    {userInfo.passwordRequest.length}
                  </span>
                ) : null} */}
              </div>
            ) : null}
            {userInfo.role !== "User" ? (
              <p
                className="cursor-pointer"
                onClick={() => {
                  navigate("/Profile");
                }}
              >
                {userInfo.name}
              </p>
            ) : null}
            {pathname === "/DashBoard" ||
            pathname === "/manager/ManagerDashBoard" ||
            pathname === "/admin/AdminDashBoard" ? (
              <p
                className="cursor-pointer"
                onClick={() => {
                  logoutHandler();
                }}
              >
                Logout
              </p>
            ) : null}
            <div
              className="cursor-pointer relative"
              onClick={() => setShow(!show)}
            >
              <CgMenuGridR className="h-[30px] w-[30px] hover:text-orange-500" />
              {userInfo.role === "Admin" && show ? (
                <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg">
                  <ul className="py-1">
                    {/* <li className="px-4 py-2 hover:bg-gray-100">
                      Permission Requests
                    </li> */}
                    <li
                      className="px-4 py-2 hover:bg-gray-100"
                      onClick={() => navigate("/admin/AdminDashBoard")}
                    >
                      Dash Board
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100"
                      onClick={() => navigate("/admin/managers/1")}
                    >
                      Managers
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100"
                      onClick={() => navigate("/admin/Numbering")}
                    >
                      Numbering
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100"
                      onClick={() => navigate("/admin/ED")}
                    >
                      External Dependency
                    </li>
                  </ul>
                </div>
              ) : null}
              {userInfo.role === "Manager" && show ? (
                <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg">
                  <ul className="py-1">
                    <li
                      className="px-4 py-2 hover:bg-gray-100"
                      onClick={() => navigate("/manager/ManagerDashBoard")}
                    >
                      Dash Board
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100"
                      onClick={() => navigate("/manager/users/1")}
                    >
                      Create Users
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100"
                      onClick={() => {
                        navigate("/manager/CD");
                      }}
                    >
                      Create Documents
                    </li>
                  </ul>
                </div>
              ) : userInfo.role === "User" && show ? (
                <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg">
                  <ul className="py-1">
                    <li
                      className="px-4 py-2 hover:bg-gray-100"
                      onClick={() => navigate("/DashBoard")}
                    >
                      Dash Board
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100"
                      onClick={() => navigate("/Records")}
                    >
                      Records
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
            {/* {userInfo.role === "Admin" ? (
              <Dropdown
                options={["Admin", "Permission Requests", "Managers"]}
                change={(value) => {

                  if (value === "Permission Requests") {
                    navigate("/admin/permission");
                  } else if (value === "Managers") {
                    navigate("/admin/managers/1");
                  }
                }}
              />
            ) : null}
            {userInfo.role === "Manager" ? (
              <Dropdown
                options={["Manager", "Create Users", "Create Documents"]}
                change={(value) => {
                  if (value === "Create Users") {
                    navigate("/manager/CU");
                  } else if (value === "Create Documents") {
                    navigate("/manager/CD");
                  }
                }}
              />
            ) : null} */}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
