import React, { useEffect, useState } from "react";
import Dropdown from "../../../../../utils/Dropdown";
import Input from "../../../../../utils/Input";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { returnPropEle } from "../CommFunctions";
import {
  setSecOrientation,
  setSecMargin,
  setSecType,
  setSecHeaderType,
  addHeaderPara,
  addHeaderTable,
  setSecFooterType,
  addFooterPara,
  addFooterTable,
  addPara,
  addTable,
  setSecBorderStyle,
  setSecBorderColor,
  setSecBorderSize,
  setSecBorderSpace,
} from "../../../../../slices/docsSlice";
import RetractableDiv from "../../../../../components/RetractableDiv";

const SectionProperties = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { docs, SIP, NIP_S, NIP_P, NIP_T, RRIP_S, RRIP_DOC, dev, minimal } =
    useSelector((state) => state.docs);
  const {
    headerFooterTypes,
    sectionTypes,
    sectionOrientation,
    isTrueOrFalse,
    pageBorderDisplay,
    pageBorderOffsetFrom,
    pageBorderZOrder,
    pageBorders,
    borderStyles,
  } = useSelector((state) => state.options);

  const [type, setType] = useState();
  const [orientation, setOrientation] = useState();
  const [marginTop, setMarginTop] = useState();
  const [marginBottom, setMarginBottom] = useState();
  const [marginLeft, setMarginLeft] = useState();
  const [marginRight, setMarginRight] = useState();
  const [pageBorderLeftStyle, setPageBorderLeftStyle] = useState();
  const [pageBorderLeftColor, setPageBorderLeftColor] = useState();
  const [pageBorderLeftSize, setPageBorderLeftSize] = useState();
  const [pageBorderLeftSpace, setPageBorderLeftSpace] = useState();
  const [pageBorderRightStyle, setPageBorderRightStyle] = useState();
  const [pageBorderRightColor, setPageBorderRightColor] = useState();
  const [pageBorderRightSize, setPageBorderRightSize] = useState();
  const [pageBorderRightSpace, setPageBorderRightSpace] = useState();
  const [pageBorderTopStyle, setPageBorderTopStyle] = useState();
  const [pageBorderTopColor, setPageBorderTopColor] = useState();
  const [pageBorderTopSize, setPageBorderTopSize] = useState();
  const [pageBorderTopSpace, setPageBorderTopSpace] = useState();
  const [pageBorderBottomStyle, setPageBorderBottomStyle] = useState();
  const [pageBorderBottomColor, setPageBorderBottomColor] = useState();
  const [pageBorderBottomSize, setPageBorderBottomSize] = useState();
  const [pageBorderBottomSpace, setPageBorderBottomSpace] = useState();

  const [headerType, setHeaderType] = useState();
  const [headerRows, setHeaderRows] = useState();
  const [headerCols, setHeaderCols] = useState();

  const [footerType, setFooterType] = useState();
  const [footerRows, setFooterRows] = useState();
  const [footerCols, setFooterCols] = useState();

  const [paraName, setParaName] = useState();
  const [tableName, setTableName] = useState();
  const [rows, setRows] = useState();
  const [cols, setCols] = useState();

  let lastIndex = SIP[SIP.length - 1];
  let SLI = lastIndex.split("_");

  useEffect(() => {
    const section = RRIP_S ? returnPropEle(docs, RRIP_S) : undefined;
    const doc = returnPropEle(docs, RRIP_DOC);

    if (section) {
      if (section.properties.page.size) {
        setOrientation(section.properties.page.size.orientation);
      }
      if (section.properties.page.margin) {
        setMarginLeft(section.properties.page.margin.left);
        setMarginRight(section.properties.page.margin.right);
        setMarginTop(section.properties.page.margin.top);
        setMarginBottom(section.properties.page.margin.bottom);
      }
      if (section.properties.page.borders) {
        setPageBorderLeftStyle(
          section.properties.page.borders.pageBorderLeft.style
        );
        setPageBorderLeftColor(
          `#${section.properties.page.borders.pageBorderLeft.color.toLowerCase()}`
        );
        setPageBorderLeftSize(
          section.properties.page.borders.pageBorderLeft.size
        );
        setPageBorderLeftSpace(
          section.properties.page.borders.pageBorderLeft.space
        );
        setPageBorderRightStyle(
          section.properties.page.borders.pageBorderRight.style
        );
        setPageBorderRightColor(
          `#${section.properties.page.borders.pageBorderRight.color}`
        );
        setPageBorderRightSize(
          section.properties.page.borders.pageBorderRight.size
        );
        setPageBorderRightSpace(
          section.properties.page.borders.pageBorderRight.space
        );

        setPageBorderTopStyle(
          section.properties.page.borders.pageBorderTop.style
        );
        setPageBorderTopColor(
          `#${section.properties.page.borders.pageBorderTop.color}`
        );
        setPageBorderTopSize(
          section.properties.page.borders.pageBorderTop.size
        );
        setPageBorderTopSpace(
          section.properties.page.borders.pageBorderTop.space
        );

        setPageBorderBottomStyle(
          section.properties.page.borders.pageBorderBottom.style
        );
        setPageBorderBottomColor(
          `#${section.properties.page.borders.pageBorderBottom.color}`
        );
        setPageBorderBottomSize(
          section.properties.page.borders.pageBorderBottom.size
        );
        setPageBorderBottomSpace(
          section.properties.page.borders.pageBorderBottom.space
        );
      }
      // if (`${section.properties.page.borders}`) {
      //   setBorders(`${section.properties.page.borders}`);
      // }
      if (section.properties.page.borders)
        if (section.properties.type) {
          setType(section.properties.type);
        }
      if (section.headers.type) {
        setHeaderType(section.headers.type);
      }
      if (section.footers.type) {
        setFooterType(section.footers.type);
      }
    }
  }, [docs, NIP_S]);

  return (
    <>
      <RetractableDiv name="Section Properties">
        <div className="psm morphous">
          {/* <h1 className="font-mono text-center pb-4">Section Properties</h1> */}
          <div className="flex gap-4 pb-4">
            <div>
              <h2 className="pb-1">Orientation</h2>
              <Dropdown
                width={150}
                height={40}
                options={sectionOrientation}
                target="option"
                value={orientation}
                click={(value) => {
                  dispatch(
                    setSecOrientation({
                      section: RRIP_S,
                      value,
                    })
                  );
                }}
              />
            </div>

            <div className={`${minimal ? "hidden" : "block"}`}>
              <h2 className="pb-1">Type</h2>
              <Dropdown
                width={150}
                height={40}
                options={sectionTypes}
                target="option"
                value={type}
                click={(value) => {
                  dispatch(
                    setSecType({
                      section: RRIP_S,
                      value,
                    })
                  );
                }}
                style={
                  type ? { backgroundColor: "#083344", color: "#f8f9fa" } : {}
                }
              />
            </div>
          </div>
          <div className={`${minimal ? "hidden" : "block pb-4"}`}>
            <div className="flex gap-4 pb-1">
              <div>
                <h2 className="pb-1">PB Left style</h2>
                <Dropdown
                  width={250}
                  height={40}
                  options={borderStyles}
                  target="option"
                  value={pageBorderLeftStyle}
                  click={(value) => {
                    dispatch(
                      setSecBorderStyle({
                        loc: "pageBorderLeft",
                        section: RRIP_S,
                        value,
                      })
                    );
                  }}
                />
              </div>
              <div>
                <h2 className="pb-1">PB Left color</h2>
                <Input
                  className="h-[40px] w-[100px] p-0 shadow-none"
                  value={pageBorderLeftColor}
                  blur={(e) => {
                    dispatch(
                      setSecBorderColor({
                        loc: "pageBorderLeft",
                        section: RRIP_S,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setPageBorderLeftColor(e.target.value)}
                  type="color"
                />
              </div>
              <div>
                <h2 className="pb-1">PB Left Size</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={pageBorderLeftSize}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setSecBorderSize({
                        loc: "pageBorderLeft",
                        section: RRIP_S,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setPageBorderLeftSize(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">PB Left Space</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={pageBorderLeftSpace}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setSecBorderSpace({
                        loc: "pageBorderLeft",
                        section: RRIP_S,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setPageBorderLeftSpace(e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-4 pb-1">
              <div>
                <h2 className="pb-1">PB Right style</h2>
                <Dropdown
                  width={250}
                  height={40}
                  options={borderStyles}
                  target="option"
                  value={pageBorderRightStyle}
                  click={(value) => {
                    dispatch(
                      setSecBorderStyle({
                        loc: "pageBorderRight",
                        section: RRIP_S,
                        value,
                      })
                    );
                  }}
                />
              </div>
              <div>
                <h2 className="pb-1">PB Right color</h2>
                <Input
                  className="h-[40px] w-[100px] p-0 shadow-none"
                  value={pageBorderRightColor}
                  blur={(e) => {
                    dispatch(
                      setSecBorderColor({
                        loc: "pageBorderRight",
                        section: RRIP_S,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setPageBorderRightColor(e.target.value)}
                  type="color"
                />
              </div>
              <div>
                <h2 className="pb-1">PB Right Size</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={pageBorderRightSize}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setSecBorderSize({
                        loc: "pageBorderRight",
                        section: RRIP_S,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setPageBorderRightSize(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">PB Right Space</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={pageBorderRightSpace}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setSecBorderSpace({
                        loc: "pageBorderRight",
                        section: RRIP_S,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setPageBorderRightSpace(e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-4 pb-1">
              <div>
                <h2 className="pb-1">PB Top style</h2>
                <Dropdown
                  width={250}
                  height={40}
                  options={borderStyles}
                  target="option"
                  value={pageBorderTopStyle}
                  click={(value) => {
                    dispatch(
                      setSecBorderStyle({
                        loc: "pageBorderTop",
                        section: RRIP_S,
                        value,
                      })
                    );
                  }}
                />
              </div>
              <div>
                <h2 className="pb-1">PB Top color</h2>
                <Input
                  className="h-[40px] w-[100px] p-0 shadow-none"
                  value={pageBorderTopColor}
                  blur={(e) => {
                    dispatch(
                      setSecBorderColor({
                        loc: "pageBorderTop",
                        section: RRIP_S,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setPageBorderTopColor(e.target.value)}
                  type="color"
                />
              </div>
              <div>
                <h2 className="pb-1">PB Top Size</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={pageBorderTopSize}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setSecBorderSize({
                        loc: "pageBorderTop",
                        section: RRIP_S,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setPageBorderTopSize(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">PB Top Space</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={pageBorderTopSpace}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setSecBorderSpace({
                        loc: "pageBorderTop",
                        section: RRIP_S,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setPageBorderTopSpace(e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-4 pb-1">
              <div>
                <h2 className="pb-1">PB Bottom style</h2>
                <Dropdown
                  width={250}
                  height={40}
                  options={borderStyles}
                  target="option"
                  value={pageBorderBottomStyle}
                  click={(value) => {
                    dispatch(
                      setSecBorderStyle({
                        loc: "pageBorderBottom",
                        section: RRIP_S,
                        value,
                      })
                    );
                  }}
                />
              </div>
              <div>
                <h2 className="pb-1">PB Bottom color</h2>
                <Input
                  className="h-[40px] w-[100px] p-0 shadow-none"
                  value={pageBorderBottomColor}
                  blur={(e) => {
                    dispatch(
                      setSecBorderColor({
                        loc: "pageBorderBottom",
                        section: RRIP_S,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setPageBorderBottomColor(e.target.value)}
                  type="color"
                />
              </div>
              <div>
                <h2 className="pb-1">PB Bottom Size</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={pageBorderBottomSize}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setSecBorderSize({
                        loc: "pageBorderBottom",
                        section: RRIP_S,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setPageBorderBottomSize(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">PB Bottom Space</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={pageBorderBottomSpace}
                  type="number"
                  blur={(e) => {
                    dispatch(
                      setSecBorderSpace({
                        loc: "pageBorderBottom",
                        section: RRIP_S,
                        value: e.target.value,
                      })
                    );
                  }}
                  change={(e) => setPageBorderBottomSpace(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="pb-4">
            <h1 className="pb-1 font-mono">
              Margin Units [mm, cm, in, pt, pc, pi]
            </h1>
            <div className="flex gap-4">
              <div>
                <h2 className="pb-1">M Left</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  value={marginLeft}
                  blur={(e) =>
                    dispatch(
                      setSecMargin({
                        section: RRIP_S,
                        loc: "left",
                        value: e.target.value,
                      })
                    )
                  }
                  change={(e) => setMarginLeft(e.target.value)}
                  type="text"
                />
              </div>
              <div>
                <h2 className="pb-1">M Right</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  value={marginRight}
                  blur={(e) =>
                    dispatch(
                      setSecMargin({
                        section: RRIP_S,
                        loc: "right",
                        value: e.target.value,
                      })
                    )
                  }
                  change={(e) => setMarginRight(e.target.value)}
                  type="text"
                />
              </div>
              <div>
                <h2 className="pb-1">M Top</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  value={marginTop}
                  blur={(e) =>
                    dispatch(
                      setSecMargin({
                        section: RRIP_S,
                        loc: "top",
                        value: e.target.value,
                      })
                    )
                  }
                  change={(e) => setMarginTop(e.target.value)}
                  type="text"
                />
              </div>
              <div>
                <h2 className="pb-1">M Bottom</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  value={marginBottom}
                  blur={(e) =>
                    dispatch(
                      setSecMargin({
                        section: RRIP_S,
                        loc: "bottom",
                        value: e.target.value,
                      })
                    )
                  }
                  change={(e) => setMarginBottom(e.target.value)}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className={`${minimal ? "hidden" : "block"}`}>
            <div className="flex gap-4 pb-4">
              <div>
                <h1 className="pb-1">Header</h1>
                <Dropdown
                  width={200}
                  height={40}
                  options={headerFooterTypes}
                  target="option"
                  name="Type"
                  value={headerType}
                  click={(value) => {
                    dispatch(
                      setSecHeaderType({
                        section: RRIP_S,
                        type: value,
                      })
                    );
                  }}
                  style={
                    headerType
                      ? { backgroundColor: "#083344", color: "#f8f9fa" }
                      : {}
                  }
                />
              </div>
              {headerType ? (
                <>
                  <div>
                    <h1 className="pb-1">Para</h1>
                    <button
                      className="bg-cyan-950 text-light-500 h-[40px] w-[100px]"
                      onClick={() => {
                        dispatch(
                          addHeaderPara({
                            section: RRIP_S,
                          })
                        );
                      }}
                    >
                      Para
                    </button>
                  </div>
                  <div>
                    <h1 className="pb-1">Rows</h1>
                    <Input
                      className="h-[40px] w-[100px]"
                      value={headerRows}
                      change={(e) => setHeaderRows(e.target.value)}
                      placeholder="Rows"
                      type="number"
                    />
                  </div>
                  <div>
                    <h1 className="pb-1">Cols</h1>
                    <Input
                      className="h-[40px] w-[100px]"
                      value={headerCols}
                      change={(e) => setHeaderCols(e.target.value)}
                      placeholder="Cols"
                      type="number"
                    />
                  </div>
                  <div>
                    <h1 className="pb-1">Table</h1>
                    <button
                      className="bg-cyan-950 text-light-500 h-[40px] w-[100px]"
                      onClick={() => {
                        dispatch(
                          addHeaderTable({
                            section: RRIP_S,
                            rows: parseFloat(headerRows),
                            cols: parseFloat(headerCols),
                          })
                        );
                      }}
                    >
                      Table
                    </button>
                  </div>
                </>
              ) : null}
            </div>
            <div className="flex gap-4 pb-4">
              <div>
                <h1 className="pb-1">Footer</h1>
                <Dropdown
                  width={200}
                  height={40}
                  options={headerFooterTypes}
                  target="option"
                  name="Type"
                  value={footerType}
                  click={(value) => {
                    dispatch(
                      setSecFooterType({
                        section: RRIP_S,
                        type: value,
                      })
                    );
                  }}
                  style={
                    footerType
                      ? { backgroundColor: "#083344", color: "#f8f9fa" }
                      : {}
                  }
                />
              </div>
              {footerType ? (
                <>
                  <div>
                    <h1 className="pb-1">Para</h1>
                    <button
                      className="bg-cyan-950 text-light-500 h-[40px] w-[100px]"
                      onClick={() => {
                        dispatch(
                          addFooterPara({
                            section: RRIP_S,
                          })
                        );
                      }}
                    >
                      Para
                    </button>
                  </div>
                  <div>
                    <h1 className="pb-1">Rows</h1>
                    <Input
                      className="h-[40px] w-[100px]"
                      value={footerRows}
                      change={(e) => setFooterRows(e.target.value)}
                      placeholder="Rows"
                      type="number"
                    />
                  </div>
                  <div>
                    <h1 className="pb-1">Cols</h1>
                    <Input
                      className="h-[40px] w-[100px]"
                      value={footerCols}
                      change={(e) => setFooterCols(e.target.value)}
                      placeholder="Cols"
                      type="number"
                    />
                  </div>
                  <div>
                    <h1 className="pb-1">Table</h1>
                    <button
                      className="bg-cyan-950 text-light-500 h-[40px] w-[100px]"
                      onClick={() => {
                        dispatch(
                          addFooterTable({
                            section: RRIP_S,
                            rows: parseFloat(footerRows),
                            cols: parseFloat(footerCols),
                          })
                        );
                      }}
                    >
                      Table
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </RetractableDiv>
      <div className={`psm ${dev ? "hidden" : "block"}`}>
        <h2 className="font-mono pb-2">Paragraph Name</h2>
        <div className="flex gap-4 pb-6">
          <Input
            className="h-[40px] w-[250px]"
            value={paraName}
            change={(e) => setParaName(e.target.value)}
            placeholder="Enter Paragraph Name"
          />
          <button
            className="px-3 py-1 bg-cyan-950 text-light-500"
            onClick={() => {
              dispatch(
                addPara({
                  path: RRIP_S,
                  name: paraName,
                })
              );
              setParaName("");
            }}
          >
            Add Para
          </button>
          <button
            className="px-3 py-1 bg-cyan-950 text-light-500"
            onClick={() => {
              dispatch(
                addPara({
                  path: RRIP_S,
                  loc: "after",
                  index: parseFloat(SLI[0]),
                  name: paraName,
                })
              );
            }}
          >
            After
          </button>
          <button
            className="px-3 py-1 bg-cyan-950 text-light-500"
            onClick={() => {
              dispatch(
                addPara({
                  path: RRIP_S,
                  loc: "before",
                  index: parseFloat(SLI[0]),
                  name: paraName,
                })
              );
            }}
          >
            Before
          </button>
        </div>
        <h2 className="font-mono pb-2">Table Name, Rows and Cols</h2>
        <div className="flex flex-wrap gap-4">
          <div className="flex flex-wrap gap-4">
            <Input
              className="h-[40px] w-[150px]"
              value={rows}
              change={(e) => setRows(e.target.value)}
              placeholder="Enter no.of Rows"
              type="number"
            />
            <Input
              className="h-[40px] w-[150px]"
              value={cols}
              change={(e) => setCols(e.target.value)}
              placeholder="Enter no.of Cols"
              type="number"
            />
          </div>
          <div className="flex flex-wrap gap-4">
            <Input
              className="h-[40px] w-[250px]"
              value={tableName}
              change={(e) => setTableName(e.target.value)}
              placeholder="Enter Table Name"
            />

            <button
              className="px-3 py-1 bg-cyan-950 text-light-500"
              onClick={() => {
                if (tableName !== undefined && docs !== undefined) {
                  dispatch(
                    addTable({
                      path: RRIP_S,
                      name: tableName,
                      rows: parseFloat(rows),
                      cols: parseFloat(cols),
                    })
                  );
                  setRows("");
                  setCols("");
                  setTableName("");
                } else {
                  toast.error("Table Name Cannot be empty");
                }
              }}
            >
              Add Table
            </button>
            <button
              className="px-3 py-1 bg-cyan-950 text-light-500"
              onClick={() => {
                if (tableName !== undefined && docs !== undefined) {
                  dispatch(
                    addTable({
                      path: RRIP_S,
                      loc: "after",
                      index: parseFloat(SLI[0]),
                      name: tableName,
                      rows: parseFloat(rows),
                      cols: parseFloat(cols),
                    })
                  );
                  setRows("");
                  setCols("");
                  setTableName("");
                } else {
                  toast.error("Table Name Cannot be empty");
                }
              }}
            >
              After
            </button>
            <button
              className="px-3 py-1 bg-cyan-950 text-light-500"
              onClick={() => {
                if (tableName !== undefined && docs !== undefined) {
                  dispatch(
                    addTable({
                      path: RRIP_S,
                      loc: "before",
                      index: parseFloat(SLI[0]),
                      name: tableName,
                      rows: parseFloat(rows),
                      cols: parseFloat(cols),
                    })
                  );
                  setRows("");
                  setCols("");
                  setTableName("");
                } else {
                  toast.error("Table Name Cannot be empty");
                }
              }}
            >
              Before
            </button>
          </div>
        </div>
      </div>
      <div className="mb-2">
        <Outlet />
      </div>
    </>
  );
};

export default SectionProperties;
