import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories: (state, { payload }) => {
      state.categories = payload;
    },
    resetCategories: (state, { payload }) => {
      state.categories = [];
    },
  },
});

export const { setCategories, resetCategories } = categoriesSlice.actions;

export default categoriesSlice.reducer;
